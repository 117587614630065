import { useParams } from "react-router-dom";

import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";

import useQuotationData from "pages/bid/_hooks/useQuotationData";

import ConfirmQuotation from "./ConfirmQuotation";

const ConfirmQuotationDetail = () => {
  const { id }: { id: string } = useParams();

  const {
    bidDetail,
    exchangeRateList,
    applyItems,
    linerList,
    isShipmentDetailFetching,
  } = useQuotationData({
    shipmentId: id,
  });

  if (
    !bidDetail ||
    !exchangeRateList ||
    !applyItems ||
    !linerList ||
    isShipmentDetailFetching
  ) {
    return (
      <Layout title={"견적서 확정"}>
        <Loading active={true} />
      </Layout>
    );
  }

  return (
    <Layout title={"견적서 확정"}>
      <ConfirmQuotation
        bidDetail={bidDetail}
        exchangeRateList={exchangeRateList}
        applyItems={applyItems}
        linerList={linerList}
      />
    </Layout>
  );
};

export default withRequireAuth(ConfirmQuotationDetail);
