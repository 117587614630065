import styled, { css } from "styled-components";

import { showVerticalScrollBarAlways } from "@sellernote/_shared/src/styles/functions";

import { COLOR, TEXT_COLOR } from "../../../styles/colors";
import { setFontStyle } from "../../../styles/typography";

import {
  TableCellHorizontalAlign,
  TableCellVerticalAlign,
  TableFooterCellHorizontalAlign,
} from ".";

function setCellHorizontalAlign(
  horizontalAlign?:
    | TableCellHorizontalAlign
    | Extract<TableFooterCellHorizontalAlign, "center">
) {
  switch (horizontalAlign) {
    case "right": {
      return css`
        justify-content: flex-end;
        text-align: right;
      `;
    }

    // footer 셀에서 사용
    case "center": {
      return css`
        justify-content: center;
        text-align: center;
      `;
    }

    default: {
      // left가 default
      return css`
        text-align: left;
        justify-content: flex-start;
      `;
    }
  }
}

function setCellVerticalAlign(verticalAlign?: TableCellVerticalAlign) {
  switch (verticalAlign) {
    case "center": {
      return css`
        text-align: center;
        align-items: center;
      `;
    }
    case "bottom": {
      return css`
        align-items: flex-end;
      `;
    }
    default: {
      // top이 default
      return css`
        align-items: flex-start;
      `;
    }
  }
}

const container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const table = styled.table<{ hasRows: boolean }>`
  position: relative;
  width: 100%;
  height: ${({ hasRows }) => (hasRows ? "100%" : "auto")};
  display: flex;
  flex-direction: column;
`;

const tableHeader = styled.thead<{
  isStickyHeader?: boolean;
}>`
  position: ${({ isStickyHeader }) => (isStickyHeader ? "sticky" : "static")};
  z-index: 8;
  top: 0;
  left: 0;
  flex: 0 0 auto;
`;

const tableBody = styled.tbody`
  position: relative;
  overflow: auto;
  flex: 1 1 auto;
  ${showVerticalScrollBarAlways()};
`;

const tableRow = styled.tr<{
  cellGridSizeList: { width?: string; ratio?: number; minWidth?: string }[];
  hasClickAction: boolean;
  isTheadTr?: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ cellGridSizeList }) =>
    cellGridSizeList
      ?.map((cellGridSize) => {
        if (cellGridSize.width) return cellGridSize.width;

        if (cellGridSize.minWidth)
          return `minmax(${cellGridSize.minWidth}, ${
            cellGridSize.ratio || 1
          }fr)`;

        if (cellGridSize.ratio) return `${cellGridSize.ratio}fr`;

        return "1fr";
      })
      .join(" ")};
  justify-items: center;

  background-color: ${({ isTheadTr }) =>
    isTheadTr ? COLOR.grayScale_300 : COLOR.wh};

  ${({ isTheadTr }) =>
    isTheadTr &&
    css`
      border-bottom: 1px solid ${COLOR.grayScale_400};
    `}

  // onRowClick에 함수를 전달한 경우에만 Hover 스타일 적용
  ${({ hasClickAction, isTheadTr }) =>
    hasClickAction &&
    !isTheadTr &&
    css`
      &:hover {
        background-color: ${COLOR.grayScale_50};
      }
    `}
`;

const tableHeaderCell = styled.th<{
  horizontalAlign?: TableCellHorizontalAlign;
  isSubPanelTable?: boolean;
  isSelectableTable?: boolean;
  isSmallSize: boolean;
}>`
  width: 100%;
  ${({ isSmallSize }) => setFontStyle(isSmallSize ? "SubHead3" : "SubHead2")};
  padding: ${({
    horizontalAlign,
    isSelectableTable,
    isSubPanelTable,
    isSmallSize,
  }) => {
    if (isSelectableTable) {
      if (isSmallSize) {
        return "13px 8px 12px";
      }

      return "13px 12px 12px";
    }

    if (isSubPanelTable) {
      if (isSmallSize) {
        return "13px 8px 12px";
      }

      return "15px 12px 14px";
    }

    if (isSmallSize) {
      const isRight = horizontalAlign === "right";
      if (isRight) {
        return "12px 8px 11px 4px";
      }

      return "12px 4px 11px 8px";
    }

    return "16px 8px 15px";
  }};

  color: ${TEXT_COLOR.black_2};
  background-color: ${COLOR.grayScale_300};

  display: flex;
  ${({ isSelectableTable }) =>
    isSelectableTable &&
    css`
      align-items: center;
    `};

  ${({ horizontalAlign }) => setCellHorizontalAlign(horizontalAlign)}

  > .table-header-filter, .table-header-sort {
    margin-left: 4px;
  }

  > .table-header-tooltip {
    margin-left: 4px;
  }
`;

const tableDataCell = styled.td<{
  horizontalAlign?: TableCellHorizontalAlign;
  verticalAlign?: TableCellVerticalAlign;
  isSelected?: boolean;
  isDisabled?: boolean;
  isFirstAndSelectionCell: boolean;
  hasClickAction: boolean;
  rowBackgroundColor?: string;
  isSelectableTable?: boolean;
  isSubPanelToggle?: boolean;
  isSubPanelCell?: boolean;
  /** subPanel은 전체 행을 하나로 합쳐야 함 */
  totalColumnCount?: number;
  isSmallSize: boolean;
}>`
  width: 100%;

  padding: ${({
    horizontalAlign,
    isSelectableTable,
    isSubPanelToggle,
    isSubPanelCell,
    isSmallSize,
  }) => {
    if (isSubPanelToggle) {
      if (isSmallSize) {
        return "15px 8px 14px";
      }

      return "15px 12px 14px";
    }

    if (isSubPanelCell) {
      if (isSmallSize) {
        if (isSelectableTable) {
          return "16px 8px 15px 86px";
        }

        return "16px 8px 15px 44px";
      }

      if (isSelectableTable) {
        return "16px 12px 15px 98px";
      }

      return "16px 12px 15px 56px";
    }

    if (isSelectableTable) {
      if (isSmallSize) {
        return "16px 8px 15px";
      }

      return "16px 12px 15px";
    }

    if (isSmallSize) {
      const isRight = horizontalAlign === "right";
      if (isRight) {
        return "12px 8px 11px 4px";
      }

      return "12px 4px 11px 8px";
    }

    return "16px 8px 15px";
  }};

  ${({ isSmallSize }) => setFontStyle(isSmallSize ? "Body4" : "Body3")};
  color: ${TEXT_COLOR.black_1};
  border-bottom: 1px solid ${COLOR.grayScale_300};
  display: flex;

  ${({ hasClickAction, isDisabled }) => {
    if (hasClickAction && !isDisabled) {
      return css`
        cursor: pointer;
      `;
    }
  }}

  ${({ horizontalAlign }) => setCellHorizontalAlign(horizontalAlign)}

  ${({ verticalAlign }) => setCellVerticalAlign(verticalAlign)}

  ${({ rowBackgroundColor }) => {
    if (rowBackgroundColor) {
      return css`
        background-color: ${rowBackgroundColor};
      `;
    }
  }}

  ${({ isSelected, isFirstAndSelectionCell }) => {
    if (isFirstAndSelectionCell) {
      return isSelected
        ? css`
            background-color: ${COLOR.grayScale_100};
            box-shadow: inset 2px 0 0 0 ${COLOR.success_400};
          `
        : css`
            box-shadow: inset 2px 0 0 0 transparent;
          `;
    }

    if (isSelected) {
      return css`
        background-color: ${COLOR.grayScale_100};
      `;
    }
  }}

  ${({ totalColumnCount }) => {
    if (totalColumnCount) {
      return css`
        grid-column: 1 / ${totalColumnCount + 2};
      `;
    }
  }}

  ${({ isSubPanelToggle }) => {
    if (isSubPanelToggle) {
      return css`
        cursor: pointer;
      `;
    }
  }};

  ${({ isSubPanelCell }) => {
    if (isSubPanelCell) {
      return css`
        background-color: ${COLOR.grayScale_100};
      `;
    }
  }};

  ${({ isDisabled, isSubPanelToggle }) => {
    if (isDisabled && !isSubPanelToggle) {
      return css`
        cursor: not-allowed;
        background-color: ${COLOR.wh};
        color: ${TEXT_COLOR.black_disabled};
      `;
    }
  }};
`;

const noData = styled.div`
  text-align: center;
  padding: 80px 0;
  ${setFontStyle("Body2")};
  color: ${TEXT_COLOR.black_3};
`;

const loadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 54px);
`;

const gradientOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
  pointer-events: none; /* 그라데이션이 스크롤을 방해하지 않도록 함 */
`;

const tableFooterCell = styled.td<{
  isSmallSize: boolean;
  horizontalAlign: TableFooterCellHorizontalAlign;
}>`
  width: 100%;
  padding: ${({ horizontalAlign, isSmallSize }) => {
    if (isSmallSize) {
      if (horizontalAlign === "right") {
        return "12px 8px 12px 4px";
      }

      if (horizontalAlign === "center") {
        return "12px 4px";
      }

      return "12px 4px 12px 8px";
    }

    if (horizontalAlign === "right") {
      return "16px 8px 16px 4px";
    }

    if (horizontalAlign === "center") {
      return "16px";
    }

    return "16px 8px";
  }};
  display: flex;
  ${({ horizontalAlign }) => setCellHorizontalAlign(horizontalAlign)}
  ${setFontStyle("SubHead3")};
  color: ${TEXT_COLOR.black_2};
  background-color: ${COLOR.grayScale_50};
`;

export default {
  container,
  table,
  tableHeader,
  tableBody,
  tableRow,
  tableHeaderCell,
  tableDataCell,
  noData,
  loadingContainer,
  gradientOverlay,
  tableFooterCell,
};
