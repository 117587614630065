import {
  ContainerType,
  ContainerTypeForCustoms,
} from "@sellernote/_shared/src/types/forwarding/bid";

const HEAD_CELL_LIST = [
  { id: "containerNo", label: "CNTR No.", disablePadding: false },
  { id: "sealNo", label: "Seal No", disablePadding: false },
  { id: "containerType", label: "Container Type", disablePadding: false },
  {
    id: "containerTypeForCustoms",
    label: "Container Type(세관 Code)",
    disablePadding: false,
  },
  { id: "grossWeight", label: "Gross Weight(KG)", disablePadding: false },
  { id: "tareWeight", label: "Tare Weight(KG)", disablePadding: false },
  { id: "vgmWeight", label: "VGM Weight(KG)", disablePadding: false },
  { id: "delete", label: "삭제", disablePadding: false },
];

/** 세관 코드에 상응하는 컨테이너 타입 */
const CORRESPONDING_CONTAINER_TYPE_RECORD_FOR_CONTAINER_TYPE_FOR_CUSTOMS: Partial<
  Record<ContainerTypeForCustoms, ContainerType>
> = {
  "45GP": "40HQ",
  "40HC": "40HQ",
  "40GP": "40DRY",
  "42GP": "40DRY",
  "22PC": "20FR",
  "45RE": "40RF",
  "22RE": "20RF",
  "20RE": "20RF",
};

export {
  HEAD_CELL_LIST,
  CORRESPONDING_CONTAINER_TYPE_RECORD_FOR_CONTAINER_TYPE_FOR_CUSTOMS,
};
