import { FreightType } from "@sellernote/_shared/src/types/common/common";
import { BidProjectStatus } from "@sellernote/_shared/src/types/forwarding/bid";
import { formatDate } from "@sellernote/_shared/src/utils/common/date";

import { getShipmentProjectStatusCompareOrder } from "../../../../utils";

interface UseInfographicInfoProps {
  shipmentProjectStatus: BidProjectStatus | undefined;
  departureTime: string | undefined;
  arrivalTime: string | undefined;
  transportName: string | undefined;
  transportNumber: string | undefined;
  freightType: FreightType | undefined;
}

export type { UseInfographicInfoProps };

export default function useShipmentInfographicInfo({
  shipmentProjectStatus,
  departureTime,
  arrivalTime,
  transportName,
  transportNumber,
  freightType,
}: UseInfographicInfoProps) {
  const { isAfter: isAfterMovingState } = getShipmentProjectStatusCompareOrder({
    target: "moving",
    projectStatus: shipmentProjectStatus,
  });

  const isVisibleMap = (() => {
    const invalidProjectStatus =
      shipmentProjectStatus === "canceled" ||
      shipmentProjectStatus === "failed" ||
      shipmentProjectStatus === "expired";

    return isAfterMovingState && !invalidProjectStatus;
  })();

  const { isBefore: isBeforePortEntry, isAfter: isAfterPortEntry } =
    getShipmentProjectStatusCompareOrder({
      target: "portEntryAndPrepareCustoms",
      projectStatus: shipmentProjectStatus,
    });

  const standardDeparture = (() => {
    if ((isAfterMovingState && isBeforePortEntry) || isAfterPortEntry) {
      return "ATD";
    }

    return "ETD";
  })();

  const standardArrive = (() => {
    if (isAfterPortEntry) return "ATA";

    return "ETA";
  })();

  const standardDepartureTime = departureTime
    ? formatDate({ date: departureTime, type: "YY_MM_DD" })
    : "-";

  const standardArriveTime = arrivalTime
    ? formatDate({ date: arrivalTime, type: "YY_MM_DD" })
    : "-";

  const currentTransportName = transportName || "-";

  const currentTransportNumber = transportNumber || "-";

  return {
    /**
     * Map 정보를 노출시킬지 여부
     *
     * @example
     * 운송중 이후(포함) 상태이고 취소, 거절, 만료상태가 아닌 경우
     */
    isVisibleMap,
    /** 현재 운송상태가 운송중 상태인지 여부 */
    isAfterMovingState,
    /** 현재 운송상태가 통관준비 이전 상태인지 여부 */
    isBeforePortEntry,
    /** 현재 운송상태가 통관준비 이후 상태인지 여부 */
    isAfterPortEntry,

    /** 'ETD' 혹은 'ATD' 문자열 */
    departure: standardDeparture,
    /** 'ETD' 혹은 'ATD' 날짜 */
    departureTime: standardDepartureTime,
    /** 'ETA' 혹은 'ATA' 문자열 */
    arrive: standardArrive,
    /** 'ETA' 혹은 'ATA' 날짜 */
    arriveTime: standardArriveTime,
    /** 운송 타입 */
    freightType,
    /**
     * 현재 모선명 또는 항공사
     *
     * @example
     * 해상운송 : 모선명
     * 항공운송 : 항공사
     */
    currentTransportName,
    /**
     * 현재 항차 또는 편명
     *
     * @example
     * 해상운송 : 항차
     * 항공운송 : 편명
     */
    currentTransportNumber,
  };
}
