import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Input,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import { ContainerTypeForCustoms } from "@sellernote/_shared/src/types/forwarding/bid";
import { removeUnnecessaryDecimalPlaces } from "@sellernote/_shared/src/utils/common/number";

import { ContainerInfo } from "../types";

import Table from "../../../../../../components/Table";

import useGetAndValidateContainerType from "../../../../_hooks/container/useGetAndValidateContainerType";
import {
  CORRESPONDING_CONTAINER_TYPE_RECORD_FOR_CONTAINER_TYPE_FOR_CUSTOMS,
  HEAD_CELL_LIST,
} from "./constants";

export default function ContainerTable({
  containerList,
  setDeletedIdList,
  removeContainer,
  updateContainer,
}: {
  containerList: ContainerInfo[];
  /** 함수형 업데이트를 위해 Dispatch 타입 사용 */
  setDeletedIdList: Dispatch<SetStateAction<number[]>>;
  removeContainer: (index: number) => void;
  updateContainer: (index: number, value: ContainerInfo) => void;
}) {
  const { containerTypeList, containerTypeForCustomsList } =
    useGetAndValidateContainerType();

  const addDeletedId = useCallback(
    (id: number) => {
      setDeletedIdList((prev) => [...prev, id]);
    },
    [setDeletedIdList]
  );

  /**
   * 세관 코드에 상응하는 컨테이너 타입을 반환
   * @returns Container Type 필드에 들어가는 값
   */
  const getContainerTypeForCustoms = (
    containerTypeForCustoms: ContainerTypeForCustoms
  ) => {
    return (
      CORRESPONDING_CONTAINER_TYPE_RECORD_FOR_CONTAINER_TYPE_FOR_CUSTOMS[
        containerTypeForCustoms
      ] || "NONE"
    );
  };

  /** Gross Weight, Tare Weight은 소수점 세자리 이상 입력을 못하게 처리 */
  const checkHasThreeDecimalPlaces = (value: string) => {
    return /^\d*[.]\d{4}$/.test(value);
  };

  const rowList = useMemo(
    () =>
      containerList.map((container, index) => ({
        containerNo: (
          <Input
            className="sads"
            value={container.containerNo}
            onChange={({ target: { value } }) => {
              updateContainer(index, { ...container, containerNo: value });
            }}
          />
        ),

        sealNo: (
          <Input
            className="sads"
            /** MUI Input의 value는 null이 될 수 없기 떄문에, undefined로 처리 */
            value={container.sealNo || undefined}
            onChange={({ target: { value } }) => {
              /** sealNo 값이 없으면 저장시 null로 요청이 필요해, 공란인 경우 빈 문자열이 아닌 null을 받도록 수정 */
              updateContainer(index, { ...container, sealNo: value || null });
            }}
          />
        ),

        containerType: (
          <Autocomplete
            className="sads"
            size="small"
            value={container.containerType}
            options={containerTypeList}
            renderInput={(params) => (
              <TextField {...params} className="sads" label="Container Type" />
            )}
            disabled={(() => {
              /** 세관 Code의 값이 없는 경우는 활성화 */
              if (!container.containerTypeForCustoms) return false;

              /** 특정 세관 Code 선택시, Container Type을 강제하고, 값을 변경할 수 없게 비활성화 처리 */
              return Boolean(
                CORRESPONDING_CONTAINER_TYPE_RECORD_FOR_CONTAINER_TYPE_FOR_CUSTOMS[
                  container.containerTypeForCustoms
                ]
              );
            })()}
            onChange={(_, value) => {
              if (!value) return;

              updateContainer(index, { ...container, containerType: value });
            }}
          />
        ),

        containerTypeForCustoms: (
          <Autocomplete<ContainerTypeForCustoms>
            className="sads"
            size="small"
            value={container.containerTypeForCustoms}
            options={containerTypeForCustomsList}
            renderInput={(params) => (
              <TextField {...params} className="sads" label="세관 코드" />
            )}
            onChange={(_, value) => {
              if (!value) return;

              updateContainer(index, {
                ...container,
                containerTypeForCustoms: value,
                containerType: getContainerTypeForCustoms(value),
              });
            }}
          />
        ),

        grossWeight: (
          <Input
            className="sads"
            type="number"
            value={container.grossWeight || undefined}
            endAdornment={<InputAdornment position="end">kg</InputAdornment>}
            placeholder="0.000"
            onChange={({ target: { value } }) => {
              if (checkHasThreeDecimalPlaces(value)) return;

              updateContainer(index, {
                ...container,
                grossWeight: Number(value),
              });
            }}
          />
        ),

        tareWeight: (
          <Input
            className="sads"
            type="number"
            value={container.tareWeight || undefined}
            endAdornment={<InputAdornment position="end">kg</InputAdornment>}
            placeholder="0.000"
            onChange={({ target: { value } }) => {
              if (checkHasThreeDecimalPlaces(value)) return;

              updateContainer(index, {
                ...container,
                tareWeight: Number(value),
              });
            }}
          />
        ),

        vgmWeight: (
          <Typography variant="body1" className="sads">
            {`${removeUnnecessaryDecimalPlaces(
              Number(container.grossWeight) + Number(container.tareWeight)
            )} kg`}
          </Typography>
        ),

        delete: (
          <Button
            className="sads"
            size="small"
            color="error"
            variant="contained"
            onClick={() => {
              /** 데이터로 받아온 컨테이너는, 삭제를 위해 'id'를 저장, 저장 버튼 클릭시 삭제 API 호출 */
              if (!container.isClientAdded && container.id) {
                addDeletedId(container.id);
              }

              removeContainer(index);
            }}
          >
            삭제
          </Button>
        ),
      })),
    [
      containerList,
      containerTypeList,
      containerTypeForCustomsList,
      updateContainer,
      removeContainer,
      addDeletedId,
    ]
  );

  return (
    <Box mt={1}>
      <Table
        showsTableVerticalLine
        headCells={HEAD_CELL_LIST}
        rows={rowList}
        groupedHeadRow={undefined}
        scrollbarSize={"default"}
      />
    </Box>
  );
}
