import { useCallback, useMemo, useState } from "react";
import { Box, Button } from "@mui/material";

import { GET_VESSEL_SCHEDULE_LIST_RES_LIST_ITEM } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminVesselSchedule";
import ADMIN_VESSEL_SCHEDULE_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_VESSEL_SCHEDULE_QUERY";
import { FreightType } from "@sellernote/_shared/src/types/common/common";
import { VESSEL_DESTINATION } from "@sellernote/_shared/src/types/forwarding/vesselSchedule";
import { formatDate } from "@sellernote/_shared/src/utils/common/date";
import { changeBooleanValueToKr } from "@sellernote/_shared/src/utils/common/etc";
import MultiLevelSelect from "@sellernote/_shared-for-admin/src/components/MultiLevelSelect";

import Table, { TableBodyRow, TableHeadCell } from "../../components/Table";

import useMultiLevelSelectData from "./useMultiLevelSelectData";

type CellId = keyof GET_VESSEL_SCHEDULE_LIST_RES_LIST_ITEM;

export default function useVesselScheduleTable({
  freightType,
  includesWarehouse,
}: {
  freightType: FreightType;
  includesWarehouse: boolean;
}) {
  const [currentPage, setCurrentPage] = useState(0);

  const [perPage, setPerPage] = useState(25);

  const {
    MULTI_LEVEL_SELECT_INFO,
    setOrigin,
    setDestination,
    carrier,
    destination,
    setCarrier,
    origin,
    warehouseName,
    setWarehouseName,
  } = useMultiLevelSelectData({
    freightType,
    includesWarehouse,
  });

  const setOriginAndDestinationsByCarrier = useCallback(
    (value: string | number) => {
      const rootSelectIndex = MULTI_LEVEL_SELECT_INFO?.optionList.findIndex(
        (item) => item.value === value
      );

      if (!MULTI_LEVEL_SELECT_INFO || rootSelectIndex === undefined) {
        return;
      }

      setOrigin(
        MULTI_LEVEL_SELECT_INFO.optionList[rootSelectIndex]?.dependentSelectInfo
          ?.optionList[0].value
      );

      if (includesWarehouse) {
        setWarehouseName(
          MULTI_LEVEL_SELECT_INFO.optionList[rootSelectIndex]
            ?.dependentSelectInfo?.optionList[0]?.dependentSelectInfo
            ?.optionList[0]?.value
        );

        setDestination(
          MULTI_LEVEL_SELECT_INFO.optionList[rootSelectIndex]
            ?.dependentSelectInfo?.optionList[0]?.dependentSelectInfo
            ?.optionList[0]?.dependentSelectInfo?.optionList[0]?.value
        );

        return;
      }

      setDestination(
        MULTI_LEVEL_SELECT_INFO.optionList[rootSelectIndex]?.dependentSelectInfo
          ?.optionList[0]?.dependentSelectInfo?.optionList[0]?.value
      );
    },

    [
      MULTI_LEVEL_SELECT_INFO,
      includesWarehouse,
      setDestination,
      setOrigin,
      setWarehouseName,
    ]
  );

  const { data, ResponseHandler: ResponseHandlerOfGetVesselScheduleList } =
    ADMIN_VESSEL_SCHEDULE_QUERY.useGetVesselScheduleList(
      {
        page: currentPage,
        perPage,
        // TODO: as 떼기 - enabled 옵션 사용하든가 해서 없이도 쓸 수 있게 개선하기
        carrier: carrier as string,
        origin: origin as string,
        destination: destination as VESSEL_DESTINATION,
        warehouseName: includesWarehouse ? warehouseName : undefined,
        includeWarehouse: includesWarehouse,
      },
      freightType
    );

  const headCells = useMemo(() => {
    const baseHeadCells: TableHeadCell<CellId>[] = [
      {
        id: "carrier",
        disablePadding: false,
        label: freightType === "AIR" ? "항공사명" : "선사명",
        width: 100,
      },
      {
        id: "shipName",
        disablePadding: false,
        label: freightType === "AIR" ? "편명" : "선박명",
        width: 200,
      },
      {
        id: "voyagerNo",
        disablePadding: false,
        label: "항차",
        width: 100,
      },
      {
        id: "tsShipName1",
        disablePadding: false,
        label: freightType === "AIR" ? "T/S 편명" : "T/S 선박명",
        width: 160,
      },
      {
        id: "tsVoyagerNo1",
        disablePadding: false,
        label: "T/S 항차",
        width: 100,
      },
      {
        id: "etd",
        disablePadding: false,
        label: "ETD",
        width: 170,
      },
      {
        id: "eta",
        disablePadding: false,
        label: "ETA",
        width: 170,
      },
      {
        id: "transitTimeInDays",
        disablePadding: false,
        label: "T/T",
        width: 54,
        numeric: true,
      },
      {
        id: "ts",
        disablePadding: false,
        label: "T/S",
        width: 80,
      },
      {
        id: "pol",
        disablePadding: false,
        label: "POL",
        width: 90,
      },
      {
        id: "tsPort1",
        disablePadding: false,
        label: "T/S PORT",
        width: 90,
      },
      {
        id: "pod",
        disablePadding: false,
        label: "POD",
        width: 80,
      },
      {
        id: "canBooking",
        disablePadding: false,
        label: "부킹 가능",
        width: 90,
      },

      {
        id: "docCloseTime",
        disablePadding: false,
        label: "서류마감일시",
        width: 170,
      },
      {
        id: "vgmCloseTime",
        disablePadding: false,
        label: "VGM마감일시",
        width: 170,
      },
      {
        id: "cargoCloseTime",
        disablePadding: false,
        label: "카고마감일시",
        width: 170,
      },
      {
        id: "originTerminalCode",
        disablePadding: false,
        label: "출발 터미널 코드",
        width: 100,
      },
      {
        id: "originTerminalName",
        disablePadding: false,
        label: "출발 터미널 이름",
        width: 220,
      },
      {
        id: "destTerminalCode",
        disablePadding: false,
        label: "도착 터미널 코드",
        width: 100,
      },
      {
        id: "destTerminalName",
        disablePadding: false,
        label: "도착 터미널 이름",
        width: 220,
      },
      {
        id: "tsTerminal1",
        disablePadding: false,
        label: "T/S 터미널 1",
        width: 220,
      },
      {
        id: "tsTerminal2",
        disablePadding: false,
        label: "T/S 터미널 2",
        width: 220,
      },
      {
        id: "transitTime",
        disablePadding: false,
        label: "T/T 상세",
        width: 140,
      },
    ];

    if (freightType === "AIR") {
      // AIR 운송인 경우 일부 항목을 제외
      return baseHeadCells.filter(
        (headCell) =>
          headCell.id !== "voyagerNo" && headCell.id !== "tsVoyagerNo1"
      );
    }

    return baseHeadCells;
  }, [freightType]);

  const getUnifiedPod = (
    destinationCountry: string,
    pod: string,
    destinationTerminalCode?: string
  ) => {
    if (pod !== "INC" && pod !== "PUS") return `${destinationCountry}${pod}`;

    switch (destinationTerminalCode) {
      case "HJIT":
      case "SNCT":
        return `${destinationCountry}ICN`;
      case "ICT":
      case "E1CT":
        return `${destinationCountry}INC`;
      case "PNIT":
      case "PNC":
      case "HJNC":
      case "HPNT":
      case "BNCT":
      case "BCT":
      case "BNMT":
        return `${destinationCountry}BNP`;
      case "BPTG":
      case "BPTS":
      case "DPCT":
      case "HBCT":
        return `${destinationCountry}${pod}`;
      default:
        return `${destinationCountry}${pod}`;
    }
  };

  const rows: TableBodyRow<CellId>[] = useMemo(() => {
    if (!data?.list) return [];

    return data.list.map((item) => {
      const row: TableBodyRow<CellId> = {
        carrier: item.carrier,
        shipName: freightType === "AIR" ? item.voyagerNo : item.shipName,
        // voyagerNo와 tsVoyagerNo1 항목 제외 (freightType === "AIR"인 경우)
        ...(freightType === "AIR"
          ? {}
          : {
              voyagerNo: item.voyagerNo,
              tsVoyagerNo1: item.tsVoyagerNo1 && item.tsVoyagerNo1,
            }),
        tsShipName1: item.tsShipName1 && item.tsShipName1,
        etd: formatDate({
          date: item.etd,
          type: "YY_MM_DD_ddd_HH_mm",
        }),
        eta: formatDate({
          date: item.eta,
          type: "YY_MM_DD_ddd_HH_mm",
        }),
        transitTimeInDays: item.transitTimeInDays,
        ts: changeBooleanValueToKr(item.ts),
        pol:
          freightType === "AIR" ? item.pol : `${item.originCountry}${item.pol}`,
        tsPort1: item.tsPort1 && item.tsPort1,
        pod:
          freightType === "AIR"
            ? item.pod
            : getUnifiedPod(item.destCountry, item.pod, item.destTerminalCode),
        canBooking: changeBooleanValueToKr(item.canBooking),
        docCloseTime: formatDate({
          date: item.docCloseTime,
          type: "YY_MM_DD_ddd_HH_mm",
        }),
        vgmCloseTime: formatDate({
          date: item.vgmCloseTime,
          type: "YY_MM_DD_ddd_HH_mm",
        }),
        cargoCloseTime: formatDate({
          date: item.cargoCloseTime,
          type: "YY_MM_DD_ddd_HH_mm",
        }),
        originTerminalCode: item.originTerminalCode,
        originTerminalName: item.originTerminalName,
        destTerminalCode: item.destTerminalCode,
        destTerminalName: item.destTerminalName,
        tsTerminal1: item.tsTerminal1,
        tsTerminal2: item.tsTerminal2,
        transitTime: item.transitTime,
      };

      return row;
    });
  }, [data?.list, freightType]);

  const handlePageResetRef = useCallback(() => {
    if (carrier || origin || destination) {
      setCurrentPage(0);
    }
  }, [carrier, destination, origin]);

  const VesselScheduleTable = useMemo(() => {
    return (
      <Table<CellId>
        title="운항 스케줄"
        headCells={headCells}
        rows={rows}
        pagination={{
          rowsPerPageOptions: [10, 25, 50, 100, 250, 500, 1000],
          totalCount: data?.total || 0,
          perPage,
          setPerPage,
          currentPage,
          setCurrentPage,
        }}
        toolbarItems={{
          left: [
            <Box key="multiSelect" ref={handlePageResetRef}>
              {MULTI_LEVEL_SELECT_INFO && (
                <MultiLevelSelect
                  selectInfo={MULTI_LEVEL_SELECT_INFO}
                  onChangeCallback={setOriginAndDestinationsByCarrier}
                  shouldSubSelectHasDefaultValue={true}
                />
              )}
            </Box>,
          ],
          right: [
            <Button
              key="reset"
              variant="outlined"
              sx={{ mr: 1, mt: 0.2 }}
              onClick={() => {
                setCarrier(undefined);
                setOrigin(undefined);
                setDestination(undefined);
              }}
            >
              초기화
            </Button>,
          ],
        }}
      />
    );
  }, [
    MULTI_LEVEL_SELECT_INFO,
    currentPage,
    data?.total,
    handlePageResetRef,
    headCells,
    perPage,
    rows,
    setCarrier,
    setDestination,
    setOrigin,
    setOriginAndDestinationsByCarrier,
  ]);

  return { VesselScheduleTable, ResponseHandlerOfGetVesselScheduleList };
}
