import { useFormContext } from "react-hook-form";
import { Grid, Typography } from "@mui/material";

import { INCOTERMS_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/import";
import { BidCreateFormData } from "@sellernote/_shared/src/types/forwarding/adminBidCreate";
import AutoCompleteWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/AutoCompleteWithReactHookForm";

function IncotermsForm() {
  const { control } = useFormContext<BidCreateFormData>();

  return (
    <Grid item container xs={12}>
      <Grid item xs={1}>
        <Typography variant="subtitle1" component="span">
          인코텀즈
        </Typography>
      </Grid>

      <Grid item xs={2}>
        <AutoCompleteWithReactHookForm
          name={"incoterms"}
          control={control}
          options={INCOTERMS_OPTION_LIST}
        />
      </Grid>
    </Grid>
  );
}

export default IncotermsForm;
