const getContainerColumnList = (isImport: boolean) => {
  return [
    {
      id: "containerNo",
      disablePadding: false,
      label: "CNTR No",
    },
    {
      id: "sealNo",
      disablePadding: false,
      label: "Seal No",
    },
    {
      id: "containerTypeForCustoms",
      disablePadding: false,
      label: "Container Type(세관 Code)",
    },
    ...(isImport
      ? [
          {
            id: "packageCount",
            disablePadding: false,
            label: "포장 개수",
          },
        ]
      : []),
    {
      id: "delete",
      disablePadding: false,
      label: "삭제",
    },
  ];
};

export default getContainerColumnList;
