import createReactComponent from '../createReactComponent';
export default createReactComponent('external-link', 'IconExternalLink', [
  [
    'path',
    {
      d: 'M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M11 13l9 -9', key: 'svg-1' }],
  ['path', { d: 'M15 4h5v5', key: 'svg-2' }],
]);
