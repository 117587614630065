import { Chip, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import { ExporterInfo as ExporterInfoType } from "@sellernote/_shared/src/types/common/common";

function ExporterInfo({
  exporterInfo,
  index,
}: {
  exporterInfo: ExporterInfoType;
  index: number;
}) {
  return (
    <>
      {/* TODO: 콘솔 EXW일 때 수출자 정보에 출발지가 추가되어야 함 */}
      <Grid item container alignItems="center" xs={1}>
        <Grid item>
          <Chip
            label={`수출자 ${index + 1} 정보`}
            variant="outlined"
            size="small"
            sx={{
              color: grey[800],
            }}
          />
        </Grid>
      </Grid>

      <Grid item container alignItems="center" xs={2} gap={1}>
        <Grid item>
          <Typography variant="overline" color={grey[700]}>
            COMPANY NAME :
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="overline" color={grey[700]}>
            {exporterInfo.companyName}
          </Typography>
        </Grid>
      </Grid>

      <Grid item container alignItems="center" xs={2} gap={1}>
        <Grid item>
          <Typography variant="overline" color={grey[700]}>
            NAME :
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="overline" color={grey[700]}>
            {exporterInfo.personName}
          </Typography>
        </Grid>
      </Grid>

      <Grid item container alignItems="center" xs={2} gap={1}>
        <Grid item>
          <Typography variant="overline" color={grey[700]}>
            EMAIL :
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="overline" color={grey[700]}>
            {exporterInfo.personEmail}
          </Typography>
        </Grid>
      </Grid>

      <Grid item container alignItems="center" xs={2} gap={1}>
        <Grid item>
          <Typography variant="overline" color={grey[700]}>
            TEL :
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="overline" color={grey[700]}>
            {exporterInfo.personPhone}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default ExporterInfo;
