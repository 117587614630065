import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { Button, Grid, Typography } from "@mui/material";

import { GET_FINANCIAL_DEPOSIT_HISTORY_REQ_SEARCH_KIND } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/trello";
import useSet from "@sellernote/_shared/src/hooks/common/useSet";
import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import { FinancialDepositHistory } from "@sellernote/_shared/src/types/forwarding/trello";
import {
  checkEqualArray,
  isEmptyObjectOrArray,
} from "@sellernote/_shared/src/utils/common/etc";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";

import useObjectSet from "../../../../../../hooks/useObjectSet";
import useSearchWithTerm from "../../../../../../hooks/useSearchWithTerm";
import { TermSearchType } from "../../../../../../hooks/useSearchWithTermWithHistorySave";
import useSnackbar from "../../../../../../hooks/useSnackbar";

import AmountTable from "./AmountTable";
import DepositHistoryTable from "./DepositHistoryTable";
import MatchedInvoiceDepositHistoryTable from "./MatchedInvoiceDepositHistoryTable";

const termSearchTypeOptions: TermSearchType<GET_FINANCIAL_DEPOSIT_HISTORY_REQ_SEARCH_KIND>[] =
  [
    {
      label: "입금자명",
      value: "depositName",
    },
    {
      label: "입금액",
      value: "depositAmount",
    },
  ];

export type DepositHistoryTableCellId =
  | keyof FinancialDepositHistory
  | "checkbox";

function DepositHistoryModal({
  showsDepositHistoryModal,
  setShowsDepositHistoryModal,
  invoiceId,
  billingAmount,
  bidId,
}: {
  showsDepositHistoryModal: boolean;
  setShowsDepositHistoryModal: (value: boolean) => void;
  invoiceId: number;
  billingAmount: number;
  bidId: number;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const [perPageForDepositHistoryTable, setPerPageForDepositHistoryTable] =
    useState(10);
  const [
    currentPageForDepositHistoryTable,
    setCurrentPageForDepositHistoryTable,
  ] = useState(0);
  const [
    perPageForMatchedInvoiceDepositHistoryTable,
    setPerPageForMatchedInvoiceDepositHistoryTable,
  ] = useState(5);
  const [
    currentPageForMatchedInvoiceDepositHistoryTable,
    setCurrentPageForMatchedInvoiceDepositHistoryTable,
  ] = useState(0);
  const [allowsDepositHistoryDataFetch, setAllowsDepositHistoryDataFetch] =
    useState(false);

  const {
    array: checkBoxArr,
    set: checkBoxSet,
    toggle: toggleCheckBox,
    init: initCheckBoxSet,
  } = useSet<number>();

  const {
    array: totalDepositAmountArr,
    set: totalDepositAmountSet,
    toggle: toggleTotalDepositAmount,
    init: initTotalDepositAmountSet,
  } = useObjectSet<number, number>();

  const {
    array: totalMatchedInvoiceDepositAmountArr,
    set: totalMatchedInvoiceDepositAmountSet,
    toggle: toggleTotalMatchedInvoiceDepositAmount,
    init: initTotalMatchedInvoiceDepositAmountSet,
  } = useObjectSet<number, number>();

  const {
    TermSearchPanel,
    debouncedSearchTermWithObject,
    debouncedSearchTerm,
  } = useSearchWithTerm({
    termSearchTypeOptions,
  });

  const {
    mutate: matchFinancialDeposit,
    isLoading: isMatchFinancialDepositLoading,
  } = TRELLO_BID_QUERY.useMatchFinancialDeposit();

  const {
    data: matchedInvoiceDepositHistoryData,
    refetch: refetchMatchedInvoiceDepositHistoryData,
  } = TRELLO_BID_QUERY.useGetFinancialDepositHistory({
    invoiceId,
    params: {
      page: 0,
      isMapped: true,
    },
    onSuccess: (data) => {
      if (data?.list && !isEmptyObjectOrArray(data.list)) {
        const matchedInvoiceDepositSetData = data.list.reduce(
          (acc, cur) => {
            const id = cur.id;
            const idAndDepositAmount = { [cur.id]: cur.depositAmount };

            acc.matchedInvoiceDepositIdList = [
              ...acc.matchedInvoiceDepositIdList,
              id,
            ];

            acc.matchedInvoiceDepositAmountList = [
              ...acc.matchedInvoiceDepositAmountList,
              idAndDepositAmount,
            ];

            return acc;
          },
          {
            matchedInvoiceDepositIdList: [] as number[],
            matchedInvoiceDepositAmountList: [] as Record<number, number>[],
          }
        );

        initCheckBoxSet(
          matchedInvoiceDepositSetData.matchedInvoiceDepositIdList
        );
        initTotalMatchedInvoiceDepositAmountSet(
          matchedInvoiceDepositSetData.matchedInvoiceDepositAmountList
        );

        setPerPageForDepositHistoryTable(5);
      }
      setAllowsDepositHistoryDataFetch(true);
      return;
    },
  });

  const { data: depositHistoryData, refetch: refetchDepositHistoryData } =
    TRELLO_BID_QUERY.useGetFinancialDepositHistory({
      invoiceId,
      params: {
        page: currentPageForDepositHistoryTable,
        perPage: perPageForDepositHistoryTable,
        isMapped: false,
        ...debouncedSearchTermWithObject,
      },
      enabled: allowsDepositHistoryDataFetch,
    });

  const totalDepositAmount = useMemo(() => {
    const matchedInvoiceDepositAmount =
      totalMatchedInvoiceDepositAmountArr
        .flatMap((v) => {
          return Object.values(v);
        })
        .reduce((acc, cur) => {
          acc += cur;

          return acc;
        }, 0) || 0;

    const depositAmount =
      totalDepositAmountArr
        .flatMap((v) => {
          return Object.values(v);
        })
        .reduce((acc, cur) => {
          acc += cur;

          return acc;
        }, 0) || 0;

    return matchedInvoiceDepositAmount + depositAmount;
  }, [totalDepositAmountArr, totalMatchedInvoiceDepositAmountArr]);

  const handleFinancialDepositMatch = () => {
    matchFinancialDeposit(
      {
        paymentInvoiceIds: checkBoxArr,
        invoiceId,
      },
      {
        onSuccess: () => {
          handleSnackbarOpen("매칭이 완료되었습니다.");
          initTotalDepositAmountSet();
          refetchMatchedInvoiceDepositHistoryData();
          refetchDepositHistoryData();
        },

        onError: () => {
          handleSnackbarOpen("매칭에 실패했습니다.", "error");
        },
      }
    );
  };

  const getMatchButtonDisabled = useCallback(() => {
    const matchedInvoiceDepositIdList =
      matchedInvoiceDepositHistoryData?.list.map((v) => {
        return v.id;
      });

    if (matchedInvoiceDepositIdList) {
      return checkEqualArray(matchedInvoiceDepositIdList, checkBoxArr);
    }

    if (isEmptyObjectOrArray(checkBoxArr)) {
      return true;
    }
  }, [checkBoxArr, matchedInvoiceDepositHistoryData?.list]);

  return (
    <Modal
      handleClose={() => {
        // 현재 입금매칭이 API 문제로 운영 관리 상세 데이터의 조금 늦게 반영이 됨 성공요청 이후 호출에서 모달 종료로 변경
        setShowsDepositHistoryModal(false);
        queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.trelloDetail());
      }}
      isOpened={showsDepositHistoryModal}
      modalBody={
        <Grid container direction="column" spacing={2} alignItems="center">
          <Grid item>
            <Typography variant="subtitle1" component="div">
              입금 내역
            </Typography>
          </Grid>

          {matchedInvoiceDepositHistoryData?.list &&
            !isEmptyObjectOrArray(matchedInvoiceDepositHistoryData.list) && (
              <Grid item>
                <MatchedInvoiceDepositHistoryTable
                  checkBoxSet={checkBoxSet}
                  toggleCheckBox={toggleCheckBox}
                  toggleTotalMatchedInvoiceDepositAmount={
                    toggleTotalMatchedInvoiceDepositAmount
                  }
                  matchedInvoiceDepositHistoryData={
                    matchedInvoiceDepositHistoryData
                  }
                  perPage={perPageForMatchedInvoiceDepositHistoryTable}
                  setPerPage={setPerPageForMatchedInvoiceDepositHistoryTable}
                  currentPage={currentPageForMatchedInvoiceDepositHistoryTable}
                  setCurrentPage={
                    setCurrentPageForMatchedInvoiceDepositHistoryTable
                  }
                />
              </Grid>
            )}

          <Grid item>
            <DepositHistoryTable
              checkBoxSet={checkBoxSet}
              toggleCheckBox={toggleCheckBox}
              toggleTotalDepositAmount={toggleTotalDepositAmount}
              depositHistoryData={depositHistoryData}
              TermSearchPanel={TermSearchPanel}
              perPage={perPageForDepositHistoryTable}
              setPerPage={setPerPageForDepositHistoryTable}
              currentPage={currentPageForDepositHistoryTable}
              setCurrentPage={setCurrentPageForDepositHistoryTable}
              debouncedSearchTerm={debouncedSearchTerm}
            />
          </Grid>

          <Grid item>
            <AmountTable
              billingAmount={billingAmount}
              totalDepositAmount={totalDepositAmount}
            />
          </Grid>

          <Grid item>
            <Button
              disabled={
                isMatchFinancialDepositLoading || getMatchButtonDisabled()
              }
              variant="contained"
              onClick={handleFinancialDepositMatch}
            >
              등록
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
}

export default DepositHistoryModal;
