import styled, { css } from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

import {
  ButtonIconInfoPosition,
  ButtonPropsV1,
} from "../../../headlessComponents/button/useButton";

type ContainerProps = Pick<
  ButtonPropsV1,
  "theme" | "size" | "disabled" | "width"
> & {
  iconPosition?: ButtonIconInfoPosition;
};

const container = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;

  ${({ size }: ContainerProps) => {
    switch (size) {
      case "small": {
        return css`
          width: auto;
          min-width: 4rem;
          height: 1.5rem;
          padding: 0 0.125rem;
          justify-content: space-between;

          ${({ iconPosition }: ContainerProps) => {
            if (iconPosition === "left") {
              return css`
                padding-right: 0.25rem;
                padding-left: 0;
              `;
            }

            if (iconPosition === "right") {
              return css`
                padding-right: 0;
                padding-left: 0.25rem;
              `;
            }

            if (!iconPosition) {
              return css`
                justify-content: center;
              `;
            }
          }}

          > .label {
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 1.5rem;
            letter-spacing: -0.0375rem;
          }
        `;
      }
      case "smallMultiRow": {
        return css`
          padding: 2px 4px;
          width: auto;
          min-width: 80px;
          border-radius: 2px;
          height: auto;
          min-height: 36px;
          justify-content: space-between;

          ${({ iconPosition }: ContainerProps) => {
            if (iconPosition === "left") {
              return css`
                padding-right: 0.25rem;
                padding-left: 0;
              `;
            }

            if (iconPosition === "right") {
              return css`
                padding-right: 0;
                padding-left: 0.25rem;
                > .icon {
                  margin-left: 9px;
                }
              `;
            }

            if (!iconPosition) {
              return css`
                justify-content: center;
              `;
            }
          }}

          > .label {
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.6px;
          }
        `;
      }

      case "normal": {
        return css`
          justify-content: space-between;
          width: auto;
          min-width: 120px;
          height: 2.5rem;
          border-radius: 2px;
          padding: 0 0.125rem;

          ${({ iconPosition }: ContainerProps) => {
            if (iconPosition === "left") {
              return css`
                padding-right: 0.5rem;
                padding-left: 0;
              `;
            }

            if (iconPosition === "right") {
              return css`
                padding-right: 0;
                padding-left: 0.5rem;
              `;
            }

            if (!iconPosition) {
              return css`
                justify-content: center;
              `;
            }
          }}

          > .label {
            font-weight: 700;
            font-size: 1rem;
            line-height: 2.5rem;
            letter-spacing: -0.0375rem;
          }
        `;
      }

      case "xLarge": {
        return css`
          width: auto;
          min-width: 18rem;
          height: 3.75rem;
          border-radius: 2px;
          padding: 0 0.125rem;

          > .label {
            font-weight: 700;
            font-size: 1.25rem;
            line-height: 3.75rem;
            letter-spacing: -0.0375rem;
          }
        `;
      }

      case "block": {
        return css`
          width: 100%;

          > .label {
            text-align: center;
            font-weight: 700;
            font-size: 14px;
            line-height: 46px;
            letter-spacing: -0.0375rem;
          }
        `;
      }

      case "input": {
        return css`
          width: auto;
          min-width: 4.125rem;
          height: 2.5rem;
          border-radius: 2px;
          padding: 0 0.125rem;

          > .label {
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 2.5rem;
            letter-spacing: -0.0375rem;
          }
        `;
      }
    }
  }}

  /** flexible size 인 경우, 세로 사이즈에 맞춰 동일한 size 를 선택하고 width 를 입력할 것 */
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}

  ${({ theme, size }: ContainerProps) => {
    switch (theme) {
      case "primary": {
        return css`
          color: ${COLOR.white};
          background-color: ${COLOR.primaryBlue};

          > .icon {
            svg {
              path {
                fill: ${COLOR.white};
              }
            }
          }

          &:hover {
            background-color: ${COLOR.primaryBlue_60};
          }

          &:focus,
          &:active {
            background-color: ${COLOR.secondaryDeepBlue};
          }
        `;
      }
      case "secondary": {
        return css`
          background-color: ${COLOR.white};
          color: ${COLOR.primaryBlue};
          border: 1px solid ${COLOR.primaryBlue};

          > .icon {
            svg {
              path {
                fill: ${COLOR.primaryBlue};
              }
            }
          }

          &:hover {
            color: ${COLOR.primaryBlue_60};
            border: 1px solid ${COLOR.primaryBlue_60};

            > .icon {
              svg {
                path {
                  fill: ${COLOR.primaryBlue_60};
                }
              }
            }
          }

          &:focus,
          &:active {
            color: ${COLOR.secondaryDeepBlue};
            border: 1px solid ${COLOR.secondaryDeepBlue};

            > .icon {
              svg {
                path {
                  fill: ${COLOR.secondaryDeepBlue};
                }
              }
            }
          }
        `;
      }
      case "tertiary": {
        return css`
          background-color: ${COLOR.primaryBlue_10};
          color: ${COLOR.primaryBlue};
          border: 1px solid ${COLOR.primaryBlue};

          > .icon {
            svg {
              path {
                fill: ${COLOR.primaryBlue};
              }
            }
          }

          &:hover {
            background-color: ${COLOR.primaryBlue_40};
            color: ${COLOR.primaryBlue_80};
            border: 1px solid ${COLOR.primaryBlue_80};

            > .icon {
              svg {
                path {
                  fill: ${COLOR.primaryBlue_80};
                }
              }
            }
          }

          &:focus,
          &:active {
            background-color: ${COLOR.primaryBlue_60};
            color: ${COLOR.grayScale_100};
            border: 1px solid ${COLOR.primaryBlue};

            > .icon {
              svg {
                path {
                  fill: ${COLOR.grayScale_100};
                }
              }
            }
          }
        `;
      }
      case "danger": {
        return css`
          background-color: ${COLOR.pointWarning};
          color: ${COLOR.white};

          > .icon {
            svg {
              path {
                fill: ${COLOR.white};
              }
            }
          }

          &:hover,
          &:focus,
          &:active {
            background-color: ${COLOR.pointWarning_60};
          }
        `;
      }
      case "dangerStroke": {
        return css`
          background-color: transparent;
          color: ${COLOR.pointWarning};
          border: 1px solid ${COLOR.pointWarning};

          > .icon {
            svg {
              path {
                fill: ${COLOR.pointWarning};
              }
            }
          }

          &:focus,
          &:active,
          &:hover {
            color: ${COLOR.pointWarning_60};
            border: 1px solid ${COLOR.pointWarning_60};

            > .icon {
              svg {
                path {
                  fill: ${COLOR.pointWarning_60};
                }
              }
            }
          }
        `;
      }
      case "scan": {
        return css`
          color: ${COLOR.white};
          background-color: ${COLOR.primaryBlue};
        `;
      }
    }
  }}

  ${({ theme, disabled }: ContainerProps) => {
    if (disabled) {
      switch (theme) {
        case "danger":
        case "primary": {
          return css`
            color: ${COLOR.white};
            background-color: ${COLOR.grayScale_300};
            cursor: not-allowed;

            > .icon {
              svg {
                path {
                  fill: ${COLOR.white};
                }
              }
            }

            &:hover,
            &:focus,
            &:active {
              color: ${COLOR.white};
              background-color: ${COLOR.grayScale_300};

              > .icon {
                svg {
                  path {
                    fill: ${COLOR.white};
                  }
                }
              }
            }
          `;
        }

        case "dangerStroke":
        case "secondary": {
          return css`
            color: ${COLOR.grayScale_400};
            background-color: transparent;
            cursor: not-allowed;
            border: 1px solid ${COLOR.grayScale_400};

            > .icon {
              svg {
                path {
                  fill: ${COLOR.grayScale_400};
                }
              }
            }

            &:hover,
            &:focus,
            &:active {
              color: ${COLOR.grayScale_400};
              background-color: transparent;
              border: 1px solid ${COLOR.grayScale_400};

              > .icon {
                svg {
                  path {
                    fill: ${COLOR.grayScale_400};
                  }
                }
              }
            }
          `;
        }

        case "tertiary": {
          return css`
            color: ${COLOR.grayScale_400};
            background-color: ${COLOR.grayScale_50};
            cursor: not-allowed;
            border: 1px solid ${COLOR.grayScale_400};

            > .icon {
              svg {
                path {
                  fill: ${COLOR.grayScale_400};
                }
              }
            }

            &:hover,
            &:focus,
            &:active {
              color: ${COLOR.grayScale_400};
              background-color: ${COLOR.grayScale_50};
              border: 1px solid ${COLOR.grayScale_400};

              > .icon {
                svg {
                  path {
                    fill: ${COLOR.grayScale_400};
                  }
                }
              }
            }
          `;
        }
      }
    }
  }}
`;

export default {
  container,
};
