import { useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import RestoreIcon from "@mui/icons-material/Restore";
import { Box, Button, Checkbox, Typography } from "@mui/material";

import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import { TradingStatementElem as InvoiceFee } from "@sellernote/_shared/src/types/forwarding/tradingStatement";
import { InvoiceType } from "@sellernote/_shared/src/types/forwarding/trello";
import { formatDate } from "@sellernote/_shared/src/utils/common/date";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { changeInvoiceTypeToKr } from "@sellernote/_shared/src/utils/forwarding/admin/trello";

import useSnackbar from "../../../../../../hooks/useSnackbar";

import Modal from "../../../../../../components/Modal";
import Table from "../../../../../../components/Table";

import HEAD_CELL_LIST from "./constants";

interface Invoice {
  type: InvoiceType;
  id: number;
}

export default function InvoiceRestoreModal({
  shipmentId,
  setShowsInvoiceRestoreModal,
}: {
  shipmentId: number;
  setShowsInvoiceRestoreModal: (value: boolean) => void;
}) {
  const { handleSnackbarOpen: showSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const trelloDetailQueryKey = TRELLO_BID_QUERY_KEY_GEN.trelloDetail();
  const restoreInvoiceQuery = TRELLO_BID_QUERY.useRestoreInvoice();
  const getInvoiceQuery = TRELLO_BID_QUERY.useGetDeletedInvoice({ shipmentId });

  const [invoiceList, setInvoiceList] = useState<Invoice[]>([]);

  const { mutate: restoreInvoice } = restoreInvoiceQuery;
  const { data: deletedInvoiceList = [] } = getInvoiceQuery;

  const handleSuccess = () => {
    showSnackbar("성공적으로 복구했습니다.");
    setInvoiceList([]);
    setShowsInvoiceRestoreModal(false);
    return queryClient.invalidateQueries(trelloDetailQueryKey);
  };

  const handleInvoiceRestore = () => {
    if (!invoiceList.length) return;

    /** 거래명세서를, 부킹커미션을 각 2개 이상 복구 시도시 에러 처리 */
    const bidInvoiceList = invoiceList.filter((v) => v.type === "invoice");
    const refundInvoiceList = invoiceList.filter((v) => v.type === "refund");

    if (bidInvoiceList.length > 1) {
      showSnackbar("거래명세서는 1개만 복구 가능합니다.", "error");
      return;
    }

    if (refundInvoiceList.length > 1) {
      showSnackbar("부킹 커미션은 1개만 복구 가능합니다.", "error");
      return;
    }

    const idList = invoiceList.map(({ id }) => id);

    restoreInvoice(
      { shipmentId, invoiceIds: idList },
      { onSuccess: handleSuccess }
    );
  };

  /** 대납세금 계산 함수 */
  const getTotalTaxFee = (taxFeeList: InvoiceFee[]) => {
    if (taxFeeList.length === 0) return 0;

    return taxFeeList.reduce((acc, { itemPrice }) => acc + itemPrice, 0);
  };

  const rowList = useMemo(
    () =>
      deletedInvoiceList.map((invoice) => {
        const { createdAt, deletedAt, invoiceType: type } = invoice;

        return {
          select: (
            <Checkbox
              className="sads"
              checked={invoiceList.some(({ id }) => id === invoice.id)}
              onChange={({ target: { checked } }) => {
                if (checked) {
                  setInvoiceList([...invoiceList, { id: invoice.id, type }]);
                  return;
                }

                setInvoiceList(
                  invoiceList.filter(({ id }) => id !== invoice.id)
                );
              }}
            />
          ),
          type: changeInvoiceTypeToKr(invoice.invoiceType) ?? "-",
          totalPrice: `${toThousandUnitFormat(invoice.totalPrice)}원`,
          vatPrice: `${toThousandUnitFormat(invoice.vatPrice)}원`,
          taxFee: `${toThousandUnitFormat(getTotalTaxFee(invoice.taxFee))}원`,
          finalPrice: `${toThousandUnitFormat(invoice.finalPrice)}원`,
          createdAt: formatDate({ date: createdAt, type: "YY_MM_DD_HH_mm_ss" }),
          deletedAt: formatDate({ date: deletedAt, type: "YY_MM_DD_HH_mm_ss" }),
        };
      }),
    [deletedInvoiceList, invoiceList]
  );

  return (
    <Modal
      isOpened={true}
      handleClose={() => setShowsInvoiceRestoreModal(false)}
      modalBody={
        <Box display={"flex"} flexDirection={"column"} gap={2}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography variant="h6" className="sads" fontWeight={"bold"}>
              매출관리 복구 리스트
            </Typography>

            <Button
              className="sads"
              variant="contained"
              disabled={invoiceList.length === 0}
              endIcon={<RestoreIcon />}
              onClick={handleInvoiceRestore}
            >
              복구
            </Button>
          </Box>

          <Table
            showsTableVerticalLine
            headCells={HEAD_CELL_LIST}
            rows={rowList}
          />
        </Box>
      }
    />
  );
}
