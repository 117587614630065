import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useAtom } from "jotai";

import {
  GET_ADMIN_TEAM_LIST_REQ,
  GET_ADMIN_TEAM_LIST_REQ_SEARCH_KIND,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminTeam";
import ADMIN_TEAM_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_TEAM_QUERY";
import { TeamListItem } from "@sellernote/_shared/src/types/forwarding/adminTeam";
import { formatDate } from "@sellernote/_shared/src/utils/common/date";
import Table, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/_shared-for-forwarding-admin/src/components/Table";
import useGetForwardingManagerName from "@sellernote/_shared-for-forwarding-admin/src/hooks/useGetForwardingManagerName";
import useGetObjectWithTermSearchTypeKey from "@sellernote/_shared-for-forwarding-admin/src/hooks/useGetObjectWithTermSearchTypeKey";
import useSearchWithDate, {
  SearchWithDateTypeOption,
} from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSearchWithDate";
import useSearchWithTerm, {
  TermSearchType,
} from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSearchWithTerm";
import { FORWARDING_ADMIN_TEAM_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/team";

type CellId = keyof TeamListItem | "masterName" | "masterPhone" | "masterEmail";

const termSearchTypeOptions: TermSearchType<GET_ADMIN_TEAM_LIST_REQ_SEARCH_KIND>[] =
  [
    {
      label: "팀명",
      value: "teamName",
    },
    {
      label: "회사명",
      value: "company",
    },
    {
      label: "담당자 이름",
      value: "masterName",
    },
    {
      label: "연락처",
      value: "masterPhone",
    },
    {
      label: "이메일",
      value: "masterEmail",
    },
  ];

const dateSearchTypeOptions: SearchWithDateTypeOption<"createDate">[] = [
  {
    label: "생성일",
    value: "createDate",
  },
];

function TeamTable() {
  const [teamTableFilterData, setTeamTableFilterData] = useAtom(
    FORWARDING_ADMIN_TEAM_ATOMS.TEAM_TABLE_FILTER_DATA
  );

  const history = useHistory();

  const resetCurrentPage = useCallback(() => {
    // 페이지가 0일 아닐때만 초기화 해준다.
    if (teamTableFilterData.page !== 0) {
      setTeamTableFilterData({
        ...teamTableFilterData,
        page: 0,
      });
    }
  }, [setTeamTableFilterData, teamTableFilterData]);

  const historyTermSearchData = useMemo(() => {
    // 요청 payload가 searchTyped이 아니라 각자 존재해서 검색타입으로 사용중인 하나의 key를 찾는다.
    const targetKey = Object.keys(teamTableFilterData).find(
      (key) =>
        teamTableFilterData[key as keyof GET_ADMIN_TEAM_LIST_REQ] !==
          undefined &&
        key !== "page" &&
        key !== "perPage" &&
        key !== "startDate" &&
        key !== "endDate"
    );

    if (targetKey && targetKey.length > 0) {
      const targetTermSearchTypeOption = termSearchTypeOptions.find(
        (option) => {
          return option.value === targetKey;
        }
      );

      // 전역 데이터에서 해당 key가 가지고 있는 값을 추출한다.
      const targetTermSearchTerm =
        teamTableFilterData[targetKey as keyof GET_ADMIN_TEAM_LIST_REQ];

      return {
        historyTermSearchType: targetTermSearchTypeOption,
        historyTermSearchTerm: targetTermSearchTerm,
      };
    }
  }, [teamTableFilterData]);

  const { debouncedSearchTerm, termSearchType, TermSearchPanel, searchTerm } =
    useSearchWithTerm({
      termSearchTypeOptions,
      historyTermSearchType: historyTermSearchData?.historyTermSearchType,
      historySearchTerm:
        historyTermSearchData?.historyTermSearchTerm?.toString(),
      resetCurrentPage,
    });

  const { DateSearchPanel, dateSearchType, startDate, endDate } =
    useSearchWithDate({
      dateSearchTypeOptions,
      historyStartDate: teamTableFilterData.startDate,
      historyEndDate: teamTableFilterData.endDate,
      resetCurrentPage,
    });

  const { objectWithTermSearchTypeKey } = useGetObjectWithTermSearchTypeKey({
    termSearchType,
    debouncedSearchTerm,
  });

  const { data: teamList } = ADMIN_TEAM_QUERY.useGetAdminTeamList({
    params: {
      page: teamTableFilterData.page,
      perPage: teamTableFilterData.perPage,
      ...objectWithTermSearchTypeKey,
      ...(dateSearchType && startDate && endDate
        ? {
            startDate,
            endDate,
          }
        : {}),
    },
    // 검색어 입력으로 페이지가 초기화되면서 중복요청이 되는걸 방지하기 위해 추가
    enabled: debouncedSearchTerm === searchTerm,
    onSuccess: () => {
      setTeamTableFilterData({
        ...teamTableFilterData,
        ...objectWithTermSearchTypeKey,
        ...(dateSearchType && startDate && endDate
          ? {
              startDate,
              endDate,
            }
          : {}),
      });
    },
  });

  const { getForwardingManagerName } = useGetForwardingManagerName();

  const headCells: TableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "id",
        disablePadding: false,
        label: "NO",
      },
      {
        id: "teamName",
        disablePadding: false,
        label: "팀명",
        width: 120,
      },
      {
        id: "teamCompany",
        disablePadding: false,
        label: "회사명",
        width: 100,
      },
      {
        id: "numberMembers",
        disablePadding: false,
        label: "멤버 수",
        width: 100,
      },
      {
        id: "masterName",
        disablePadding: false,
        label: "마스터 이름",
      },
      {
        id: "masterPhone",
        disablePadding: false,
        label: "연락처",
      },
      {
        id: "masterEmail",
        disablePadding: false,
        label: "이메일",
      },
      {
        id: "forwardingManagerId",
        disablePadding: false,
        label: "포워딩 담당자",
      },
      {
        id: "searchCount",
        disablePadding: false,
        label: "검색건수",
      },
      {
        id: "bidCount",
        disablePadding: false,
        label: "의뢰건수",
      },
      {
        id: "inProgressBidCount",
        disablePadding: false,
        label: "진행건수",
      },
      {
        id: "createdAt",
        disablePadding: false,
        label: "생성일",
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!teamList?.list) return [];

    return teamList?.list.map((v) => {
      const row: TableBodyRow<CellId> = {
        id: v.id,
        teamName: v.teamName,
        teamCompany: v.teamCompany,
        numberMembers: v.numberMembers,
        masterName: v.masterInfo.masterName,
        masterPhone: v.masterInfo.masterPhone,
        masterEmail: v.masterInfo.masterEmail,
        forwardingManagerId: getForwardingManagerName(v.forwardingManagerId),
        searchCount: v.searchCount,
        bidCount: v.bidCount,
        inProgressBidCount: v.inProgressBidCount,
        createdAt: formatDate({
          date: v.createdAt,
          type: "YY_MM_DD_HH_mm_ss",
        }),
        handleRowClick: () => {
          history.push(`/team/${v.id}`);
        },
      };

      return row;
    });
  }, [getForwardingManagerName, history, teamList?.list]);

  return (
    <Box>
      <Box sx={{ display: "flex", gap: 1, pt: 1, px: 1, background: "#fff" }}>
        {TermSearchPanel}
        {DateSearchPanel}
      </Box>

      <Box sx={{ p: 1, background: "#fff" }}>
        <Table
          headCells={headCells}
          rows={rows}
          toolbarItems={{
            left: [
              <Typography key="total">총 {teamList?.total || 0}건</Typography>,
            ],
          }}
          pagination={{
            rowsPerPageOptions: [10, 25, 50, 100, 500, 1000, 10000],
            totalCount: teamList?.total || 0,
            perPage: teamTableFilterData.perPage,
            setPerPage: (perPage: number) => {
              setTeamTableFilterData({
                ...teamTableFilterData,
                perPage: perPage,
              });
            },
            currentPage: teamTableFilterData.page,
            setCurrentPage: (currentPage: number) => {
              setTeamTableFilterData({
                ...teamTableFilterData,
                page: currentPage,
              });
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default TeamTable;
