import { VESSEL_DESTINATION } from "../../types/forwarding/vesselSchedule";

const VESSEL_DESTINATION_SELECT_OPTION_LIST: {
  value: VESSEL_DESTINATION;
  label: VESSEL_DESTINATION;
}[] = [
  {
    value: "KRINC",
    label: "KRINC",
  },
  {
    value: "KRPUS",
    label: "KRPUS",
  },
  {
    value: "KRICN",
    label: "KRICN",
  },
  {
    value: "KRBNP",
    label: "KRBNP",
  },
];

const VESSEL_DESTINATION_SELECT_OPTION_LIST_FOR_AIR: {
  value: VESSEL_DESTINATION;
  label: VESSEL_DESTINATION;
}[] = [
  {
    value: "ICN",
    label: "ICN",
  },
];

const CHANGE_SCHEDULE_REASON_OPTION_LIST = [
  "수출자 화물준비일정 지연",
  "이전 항차로부터 누적된 딜레이로 인한 지연",
  "선적지 포트 혼잡으로 인한 출항 지연",
  "도착지 포트 혼잡으로 인한 입항 지연",
  "환적 스케줄 변동",
  "모선명(혹은 항차) 변경",
];

export {
  VESSEL_DESTINATION_SELECT_OPTION_LIST,
  CHANGE_SCHEDULE_REASON_OPTION_LIST,
  VESSEL_DESTINATION_SELECT_OPTION_LIST_FOR_AIR,
};
