import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { Autocomplete, Box, Button, Input, TextField } from "@mui/material";

import { ContainerInfoForAN as Container } from "@sellernote/_shared/src/types/forwarding/trello";

import { ContainerFormChangeProps } from "../../../../types";
import getContainerColumnList from "./utils";

import Table from "../../../../../../../../../../../../components/Table";

import useGetAndValidateContainerType from "../../../../../../../../../../_hooks/container/useGetAndValidateContainerType";

export default function ContainerTable({
  containerList,
  onContainerFormChange,
  onContainerDelete,
  isEditMode,
  isImport,
  setDeletedIdList,
}: {
  containerList: Container[];
  onContainerFormChange: ({
    index,
    key,
    value,
  }: ContainerFormChangeProps) => void;
  onContainerDelete: ({ index }: { index: number }) => void;
  isEditMode: boolean;
  isImport: boolean;
  /** 함수형 업데이트를 위해 Dispatch 타입 사용 */
  setDeletedIdList: Dispatch<SetStateAction<number[]>>;
}) {
  const { containerTypeForCustomsList } = useGetAndValidateContainerType();

  const addDeletedId = useCallback(
    (id: number) => {
      setDeletedIdList((prev) => [...prev, id]);
    },
    [setDeletedIdList]
  );

  const rowList = useMemo(
    () =>
      containerList.map((container, index) => ({
        containerNo: (
          <Input
            className="sads"
            value={container.containerNo}
            disabled={!isEditMode}
            onChange={(e) =>
              onContainerFormChange({
                index,
                key: "containerNo",
                value: e.target.value,
              })
            }
          />
        ),

        sealNo: (
          <Input
            className="sads"
            value={container.sealNo}
            disabled={!isEditMode}
            onChange={(e) =>
              onContainerFormChange({
                index,
                key: "sealNo",
                value: e.target.value,
              })
            }
          />
        ),

        containerTypeForCustoms: (
          <Autocomplete
            className="sads"
            size="small"
            value={container.containerTypeForCustoms}
            disabled={!isEditMode}
            options={containerTypeForCustomsList}
            onChange={(_, value) => {
              onContainerFormChange({
                index,
                key: "containerTypeForCustoms",
                value: value ?? "",
              });
            }}
            renderInput={(params) => (
              <TextField {...params} className="sads" label="세관 코드" />
            )}
          />
        ),

        ...(isImport && {
          packageCount: (
            <Input
              className="sads"
              /** packageCount 초기값이 null이라, Input Value가 허용하는 타입으로 변환 */
              value={container.packageCount || undefined}
              disabled={!isEditMode}
              type={"number"}
              onChange={(e) =>
                onContainerFormChange({
                  index,
                  key: "packageCount",
                  value: Number(e.target.value),
                })
              }
            />
          ),
        }),

        delete: (
          <Button
            className="sads"
            size="small"
            variant="contained"
            color="error"
            disabled={!isEditMode}
            onClick={() => {
              /**
               * id가 있으면 데이터로 받은 컨테이너
               *
               * TODO: id를 optional 하게 사용 X
               *         - RHF 사용하여 개선
               */
              if ("id" in container && container.id) {
                addDeletedId(container.id);
              }

              onContainerDelete({ index });
            }}
          >
            삭제
          </Button>
        ),
      })),
    [
      addDeletedId,
      containerList,
      containerTypeForCustomsList,
      isEditMode,
      isImport,
      onContainerDelete,
      onContainerFormChange,
    ]
  );

  return (
    <Box>
      <Table
        isFixedHeader
        headCells={getContainerColumnList(isImport)}
        showsTableVerticalLine
        rows={rowList}
      />
    </Box>
  );
}
