import { formatDate, getRemainedDays, toFormattedDate } from "../common/date";
/**
 * 시간을 제외하고 초과기간을 계산하기 위한 포맷을 반환하는 함수.
 *
 * @param date - 포맷할 날짜 객체 (선택적)
 * @returns 포맷된 날짜 문자열 (YYYY-MM-DD 형식)
 */
const toFormattedDifferentDate = (date?: Date | null) =>
  // TODO: toFormattedDate > 날짜 연산에 사용되는 포맷은 수정 보류
  toFormattedDate(date, "YYYY-MM-DD");

/**
 * 반입/반출/반납에 대한 각 항목별(DEM, DET, Storage) 프리타임 관련 날짜를 계산하는 함수.
 *
 * @param occurrenceDate - 프리타임이 시작되는 발생일
 * @param completeDate - 완료일
 * @param LFD - 프리타임 기한일
 * @returns 프리타임 기간, 발생일, 프리타임 기한일, 초과일.
 */
const calculateFreeTimeDate = (
  occurrenceDate: Date | null,
  completeDate: Date | null,
  LFD: Date | null
) => {
  const today = new Date();

  const formattedFreeTimeDate = formatDate({
    date: LFD,
    type: "YY_MM_DD",
  });

  // 프리타임 기한을 초과한 기간. (완료일이 아직 없는 경우, 현시간 기준)
  const exceededDuration = getRemainedDays(
    toFormattedDifferentDate(LFD) ?? 0,
    toFormattedDifferentDate(completeDate) || toFormattedDifferentDate(today)
  );

  return {
    occurrenceDate,
    freeTimeDate: formattedFreeTimeDate,
    exceededDuration,
  };
};

/**
 * 프리타임 데드라인일자를 계산.
 *
 * @param occurrenceDate - 프리타임이 시작되는 발생일
 * @param completeDate - 완료일
 * @param LFD - 프리타임 기한일
 * @returns 현재일 기준으로 프리타임 기한일까지의 데드라인.
 *
 * @remarks 발생일이 없거나 이미 완료된 경우에는 데드라인을 계산하지 않습니다.
 */
const calculateLFDDeadline = (
  occurrenceDate: Date | null,
  completeDate: Date | null,
  LFD: Date | null
) => {
  // 발생일이 없어서 아직 시작하지 않았거나, 이미 완료된 것인지 확인.
  if (completeDate || !occurrenceDate) return;

  if (!LFD) return;

  const today = new Date();

  const deadline = getRemainedDays(
    toFormattedDifferentDate(today),
    toFormattedDifferentDate(LFD)
  );

  return deadline;
};

export {
  toFormattedDifferentDate,
  calculateFreeTimeDate,
  calculateLFDDeadline,
};
