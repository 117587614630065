import {
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
} from "@mui/material";
import { grey } from "@mui/material/colors";

type TableDataCell = {
  value: React.ReactNode;
};

type TableBodyTotalCell = TableDataCell & {
  colSpan?: number;
  align?: TableCellProps["align"];
  isLabel?: boolean;
};

type TableBodyTotalRow<TotalCellKey extends string> = {
  [K in TotalCellKey]: TableBodyTotalCell;
};

type TableHeadCell<HeadCellKey extends string> = {
  cellKey: HeadCellKey;
  label: React.ReactNode;
  align?: TableCellProps["align"];
  width?: TableCellProps["width"];
  /**
   * true이면 이 column은 표시하지 않는다.
   */
  hidden?: boolean;
};

/**
 * 테이블 셀의 세로 가운데 정렬을 위한 패딩 값
 */
const TABLE_CELL_PADDING = "8px 16px 4px 16px";

/**
 * 총계를 표기하기 위한 테이블, 헤더는 숨김이 가능하고, body cell 의 cell 사이즈와 정렬을 위해 사용한다.
 */
export default function TotalTable<
  CellKey extends string,
  TotalKey extends string
>({
  headCellList,
  totalRow,
}: {
  /**
   * headCell 순서가 column순서로 사용된다.
   */
  headCellList: TableHeadCell<CellKey>[];
  totalRow: TableBodyTotalRow<TotalKey>;
}) {
  const visibleHeadCellList = headCellList.filter((v) => !v.hidden);

  return (
    <Table size="small">
      {/* body의 colspan 사이즈를 위해 header 는 시각적으로만 숨김 */}
      <TableHead sx={{ height: 0, visibility: "collapse" }}>
        <TableRow>
          {visibleHeadCellList.map(({ cellKey, width, align, label }) => (
            <TableCell
              key={cellKey}
              width={width}
              align={align}
              sx={{
                padding: TABLE_CELL_PADDING,
                borderBottom: 0,
              }}
            >
              {label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        <TableRow key="total-row">
          {(Object.values(totalRow) as TableBodyTotalCell[]).map((cell) => (
            <TableCell
              key="total-cell"
              colSpan={cell.colSpan}
              align={cell.align}
              sx={{
                fontWeight: "bold",
                padding: TABLE_CELL_PADDING,
                borderBottom: 0,
                backgroundColor: grey[500],
                color: "#fff",
              }}
            >
              {cell.value}
            </TableCell>
          ))}
        </TableRow>
      </TableBody>
    </Table>
  );
}

export type { TableDataCell, TableHeadCell, TableBodyTotalRow };
