import {
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
} from "@mui/material";
import { grey } from "@mui/material/colors";

type TableDataCell = {
  value: React.ReactNode;
};

type TableBodyRow<HeadCellKey extends string> = {
  [K in HeadCellKey]: TableDataCell;
} & {
  rowKey: string | number;
  verticalAlign?: "top" | "middle" | "bottom";
};

type TableBodyTotalCell = TableDataCell & {
  colSpan?: number;
  align?: TableCellProps["align"];
  isLabel?: boolean;
};

type TableBodyTotalRow<TotalCellKey extends string> = {
  [K in TotalCellKey]: TableBodyTotalCell;
};

type TableHeadCell<HeadCellKey extends string> = {
  cellKey: HeadCellKey;
  label: React.ReactNode;
  align?: TableCellProps["align"];
  width?: TableCellProps["width"];
  /**
   * true이면 이 column은 표시하지 않는다.
   */
  hidden?: boolean;
};

/**
 * 테이블 셀의 세로 가운데 정렬을 위한 패딩 값
 */
const TABLE_CELL_PADDING = "8px 16px 4px 16px";

/**
 * TODO: 사용성이 괜찮으면 admin용 디자인시스템 프로젝트를 만들어 분리하기.
 * - 아직은 사용성 검증이 안 되어서 로컬 컴포넌트위치에만 정의하고 쓰는중. (참고: https://sellernotehq.slack.com/archives/C03PCAJTE92/p1719900655311539)
 */
export default function SADSTable<
  CellKey extends string,
  TotalKey extends string
>({
  headCellList,
  bodyRowList,
  totalRow,
}: {
  /**
   * headCell 순서가 column순서로 사용된다.
   */
  headCellList: TableHeadCell<CellKey>[];
  bodyRowList: TableBodyRow<CellKey>[];
  totalRow?: TableBodyTotalRow<TotalKey>;
}) {
  const visibleHeadCellList = headCellList.filter((v) => !v.hidden);

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          {visibleHeadCellList.map(({ cellKey, width, align, label }) => (
            <TableCell
              key={cellKey}
              width={width}
              align={align}
              sx={{
                padding: TABLE_CELL_PADDING,
                borderBottom: `1px solid ${grey[300]}`,
                color: grey[600],
                backgroundColor: grey[50],
              }}
            >
              {label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {bodyRowList && bodyRowList.length ? (
          <>
            {bodyRowList.map((row, i) => (
              <TableRow
                key={row.rowKey}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  verticalAlign: row.verticalAlign || "top",
                }}
              >
                {visibleHeadCellList.map(({ cellKey, align }) => (
                  <TableCell
                    align={align}
                    key={cellKey}
                    sx={{ padding: TABLE_CELL_PADDING, borderBottom: 0 }}
                  >
                    {row[cellKey].value}
                  </TableCell>
                ))}
              </TableRow>
            ))}

            {totalRow && (
              <TableRow key="total-row">
                {(Object.values(totalRow) as TableBodyTotalCell[]).map(
                  (cell) => (
                    <TableCell
                      key="total-cell"
                      colSpan={cell.colSpan}
                      align={cell.align}
                      sx={{
                        fontWeight: "bold",
                        padding: TABLE_CELL_PADDING,
                        borderBottom: 0,
                        backgroundColor: grey[100],
                      }}
                    >
                      {cell.value}
                    </TableCell>
                  )
                )}
              </TableRow>
            )}
          </>
        ) : (
          <TableRow>
            <TableCell
              colSpan={headCellList.length}
              sx={{ textAlign: "center" }}
            >
              데이터가 없습니다
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

export type { TableDataCell, TableHeadCell, TableBodyRow, TableBodyTotalRow };
