import { useFormContext } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  IconButton,
  InputAdornment,
  SelectChangeEvent,
  Typography,
} from "@mui/material";

import { ADMIN_BID_CONTAINER_TYPE_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/adminBid";
import { HBLInfo } from "@sellernote/_shared/src/types/forwarding/trello";

import NumericFormatWithReactHookForm from "../../../../../../../../../../../components/NumericFormatWithReactHookForm";
import SelectWithReactHookForm from "../../../../../../../../../../../components/SelectWithReactHookForm";
import TextFieldWithReactHookForm from "../../../../../../../../../../../components/TextFieldWithReactHookForm";

import useItemAdd from "../../../../_hooks/useItemAdd";
import ItemAddSelect from "../ItemAddSelect";
import Styled from "./index.styles";

function CargoInfo({
  showsAttachedRider,
  addDeletedContainerId,
}: {
  showsAttachedRider: boolean;
  addDeletedContainerId: (id: number) => void;
}) {
  const { control, watch } = useFormContext<HBLInfo>();

  const {
    handleContainerAdd,
    handleContainerRemove,
    handleItemNameAdd,
    setToBeAddedContainerTotal,
    handleItemNameRemove,
    setToBeAddedItemNameTotal,
    toBeAddedContainerTotal,
    toBeAddedItemNameTotal,
  } = useItemAdd();

  const {
    containers: containerList,
    itemName: itemNameList,
    freightType,
    freightPaymentType,
  } = watch();

  return (
    <Styled.cargoInfoContainer>
      <Styled.cargoInfoSection>
        <Styled.cargoInfoHeader>
          <div className="border-top"></div>
          <div className="title">
            UNDERMENTIONED PARTICULARS AS DECLARED BY SHIPPER, BUT NOT
            ACKNOWLEDGED BY THE CARRIER
          </div>
        </Styled.cargoInfoHeader>

        <Styled.cargoInfoDetail>
          <Styled.marksOrUnitsColumn>
            <Styled.marksOrUnitsRow>
              <Styled.marksOrUnitsItem>
                <div className="border-top"></div>

                <div className="title">MARKS AND NUMBERS</div>

                <div className="subtitle">(CONTAINER NO. &amp; SEAL NO.)</div>

                <div className="content">
                  {freightType === "FCL" ? "CY/CY" : "CFS/CFS"}
                </div>
              </Styled.marksOrUnitsItem>

              <Styled.marksOrUnitsItem>
                <div className="border-top"></div>
                <div className="title">NO. OF PKGS OR UNITS</div>

                {!showsAttachedRider && (
                  <TextFieldWithReactHookForm
                    name="packingType"
                    control={control}
                    fullWidth
                    required={true}
                  />
                )}
              </Styled.marksOrUnitsItem>
            </Styled.marksOrUnitsRow>

            {showsAttachedRider
              ? "AS ATTACHED"
              : containerList?.map((container, index) => {
                  return (
                    <Styled.containerInfo key={index}>
                      <TextFieldWithReactHookForm
                        name={`containers.${index}.containerNo`}
                        control={control}
                        required={true}
                        pattern={/^[A-Za-z0-9]{11}$/}
                        errorMessage="영문+숫자 11자리입니다."
                        InputProps={{
                          style: {
                            fontSize: "8px",
                          },
                        }}
                        placeholder="containerNo"
                        sx={{
                          fontSize: "8px",
                          width: 65,
                        }}
                      />

                      <TextFieldWithReactHookForm
                        name={`containers.${index}.sealNo`}
                        control={control}
                        required={true}
                        placeholder="sealNo"
                        InputProps={{
                          style: {
                            fontSize: "8px",
                          },
                        }}
                        sx={{
                          width: 45,
                        }}
                      />

                      <SelectWithReactHookForm
                        name={`containers.${index}.containerType`}
                        control={control}
                        options={ADMIN_BID_CONTAINER_TYPE_OPTION_LIST.map(
                          (option) => {
                            return option.value;
                          }
                        )}
                        required={true}
                        size="small"
                        sx={{
                          fontSize: "8px",
                          padding: "3px 2px 0px",
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              maxHeight: 200, // 드롭다운의 최대 높이 제한
                            },
                          },
                        }}
                      />

                      <IconButton
                        aria-label="delete"
                        size="small"
                        color="error"
                        sx={{ fontSize: 11, padding: "5px 0px" }}
                        onClick={() => {
                          const isContainerFromServer = "id" in container;

                          if (isContainerFromServer && container.id) {
                            addDeletedContainerId(container.id);
                          }

                          handleContainerRemove(index);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    </Styled.containerInfo>
                  );
                })}

            {!showsAttachedRider && (
              <ItemAddSelect
                itemType="container"
                value={toBeAddedContainerTotal}
                onSelectChange={(e: SelectChangeEvent) =>
                  setToBeAddedContainerTotal(e.target.value)
                }
                onContainerItemAdd={handleContainerAdd}
              />
            )}
          </Styled.marksOrUnitsColumn>

          <Styled.goodsInfo>
            <div className="border-top"></div>

            <div className="title">Number and Kind of</div>

            <div className="title">Packages Description of ItemName</div>

            <div className="unknownClause-wrapper">
              <TextFieldWithReactHookForm
                name="unknownClause"
                control={control}
                fullWidth
                multiline={true}
                required={true}
                rows={2}
                InputProps={{
                  style: {
                    fontSize: "9px",
                  },
                }}
              />
            </div>

            {!showsAttachedRider &&
              itemNameList?.map((_, index) => {
                return (
                  <Box
                    key={index}
                    display="flex"
                    alignItems={"center"}
                    width={"100%"}
                  >
                    <TextFieldWithReactHookForm
                      name={`itemName.${index}`}
                      control={control}
                      multiline={true}
                      required={true}
                      fullWidth
                    />

                    <IconButton
                      aria-label="delete"
                      size="small"
                      color="error"
                      onClick={() => handleItemNameRemove(index)}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  </Box>
                );
              })}

            {!showsAttachedRider && (
              <ItemAddSelect
                itemType="goods"
                value={toBeAddedItemNameTotal}
                onSelectChange={(e: SelectChangeEvent) =>
                  setToBeAddedItemNameTotal(e.target.value)
                }
                onContainerItemAdd={handleItemNameAdd}
              />
            )}

            {!showsAttachedRider && freightPaymentType && (
              <Styled.freightPaymentType>
                {freightPaymentType === "cc"
                  ? '"FREIGHT COLLECT"'
                  : '"FREIGHT PREPAID"'}
              </Styled.freightPaymentType>
            )}
          </Styled.goodsInfo>

          <Styled.grossWeightColumn>
            <div className="border-top"></div>
            <div className="title">Gross Weight</div>

            <NumericFormatWithReactHookForm
              name="weight"
              control={control}
              fullWidth
              required={true}
              thousandSeparator={true}
              decimalScale={3}
              InputProps={{
                style: { fontSize: "9px" },
                endAdornment: (
                  <InputAdornment style={{ fontSize: "9px" }} position="end">
                    <Typography variant="body2" style={{ fontSize: "10px" }}>
                      KGS
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />
          </Styled.grossWeightColumn>

          <Styled.measurementColumn>
            <div className="border-top"></div>
            <div className="title">Measurement</div>

            <NumericFormatWithReactHookForm
              name="cbm"
              control={control}
              fullWidth
              required={true}
              thousandSeparator={true}
              decimalScale={3}
              InputProps={{
                style: { fontSize: "9px" },
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="body2" style={{ fontSize: "10px" }}>
                      CBM
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />
          </Styled.measurementColumn>
        </Styled.cargoInfoDetail>
      </Styled.cargoInfoSection>

      <Styled.signatureSection>
        <TextFieldWithReactHookForm
          name="unitSupply"
          control={control}
          sx={{ width: 300 }}
          required={true}
        />

        <SelectWithReactHookForm
          name={"blType"}
          control={control}
          options={["ORIGINAL", "SURRENDERED", "CHECK"]}
          required={true}
        />
      </Styled.signatureSection>
    </Styled.cargoInfoContainer>
  );
}

export default CargoInfo;
