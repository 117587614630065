import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

import { Comment } from "@sellernote/_shared/src/types/forwarding/trello";
import { formatDate } from "@sellernote/_shared/src/utils/common/date";

function CommonCommentList({
  transformedCommentList,
}: {
  transformedCommentList: Comment[];
}) {
  const history = useHistory();

  return (
    <List>
      {transformedCommentList.map((comment, index) => {
        /** 의뢰의 자동 댓글인 경우 서버에서 이미 변환된 날짜 정보를 주고 있어 Date인지 문자열인지 확인을 한다 */
        const commentDate = (() => {
          if (isNaN(Date.parse(comment.date))) return comment.date;

          return formatDate({
            date: comment.date,
            type: "YY_MM_DD_HH_mm_ss",
          });
        })();

        return (
          <ListItem
            key={index}
            sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}
            disablePadding
          >
            <ListItemText sx={{ flex: "0 0 auto" }} secondary={commentDate}>
              {comment.id}
            </ListItemText>

            <ListItemText
              sx={{ flex: 1 }}
              primary={
                comment.originalShipmentId ? (
                  <Box display={"flex"} alignItems={"center"}>
                    <Typography>원의뢰</Typography>

                    <Button
                      /** 재주문의뢰인 경우 원의뢰번호를 클릭해서 선적계획의뢰 > 상세로 갈 수 있어야 한다.(AB15570) */
                      onClick={() =>
                        history.push(
                          `/bid/detail/${comment.originalShipmentId}`
                        )
                      }
                    >
                      {comment.originalShipmentId}
                    </Button>

                    <Typography>재주문</Typography>
                  </Box>
                ) : (
                  comment.comment
                )
              }
            />
          </ListItem>
        );
      })}
    </List>
  );
}

export default CommonCommentList;
