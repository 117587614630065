import { HTMLAttributes, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import { ShipdaCurrentLanguage } from "@sellernote/_shared/src/i18n/i18nForShipda";
import { FreightType } from "@sellernote/_shared/src/types/common/common";
import {
  RealTimeTransportInfo,
  ShipmentTransportationStatusPort,
} from "@sellernote/_shared/src/types/forwarding/bid";
import {
  checkIsValidDate,
  getRemainedDays,
  hasDayPassed,
  toFormattedDate,
} from "@sellernote/_shared/src/utils/common/date";
import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";
import { replaceEmptyToDash } from "@sellernote/_shared/src/utils/common/string";
import BargeIcon from "@sellernote/_sds-v2/src/components/svgIcons/BargeIcon";
import DotIcon from "@sellernote/_sds-v2/src/components/svgIcons/DotIcon";
import DotOutlinedIcon from "@sellernote/_sds-v2/src/components/svgIcons/DotOutlinedIcon";
import InfoFilledIcon from "@sellernote/_sds-v2/src/components/svgIcons/InfoFilledIcon";
import PlaneIcon from "@sellernote/_sds-v2/src/components/svgIcons/PlaneIcon";
import Ship3Icon from "@sellernote/_sds-v2/src/components/svgIcons/Ship3Icon";
import TruckAndTrainIcon from "@sellernote/_sds-v2/src/components/svgIcons/TruckAndTrainIcon";
import Tooltip from "@sellernote/_sds-v2/src/components/Tooltip";
import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";

import { InfographicTransferInfo, InfographicTransferShipment } from "./types";
import { createTimeTaken, getTransferShipmentDate } from "./utils";

import Styled from "./index.styles";

interface InfographicProps extends HTMLAttributes<HTMLDivElement> {
  backgroundColor?: keyof typeof COLOR;
  transferShipmentList: InfographicTransferShipment[] | undefined;
  realtimeTransportInfo: RealTimeTransportInfo;
  startPort?: ShipmentTransportationStatusPort;
  endPort?: ShipmentTransportationStatusPort;
  freightType: FreightType | undefined;
  /**
   * 실시간 위치를 숨길지 여부
   */
  hidesRealtimePosition?: boolean;
  /**
   * remainedDays를 숨질지 여부
   */
  hidesRemainedDays?: boolean;
  /**
   * 모바일 스타일을 적용하지 않을지 여부
   */
  needsMobileStyle?: boolean;
  /**
   * 무조건 점선만 보여줄지 여부
   */
  isOnlyDottedLine?: boolean;
}

export type { InfographicProps };

export default function ShipmentInfographic({
  backgroundColor,
  className = "",
  realtimeTransportInfo,
  startPort,
  endPort,
  transferShipmentList = [],
  freightType,
  hidesRealtimePosition = false,
  hidesRemainedDays = false,
  needsMobileStyle = true,
  isOnlyDottedLine = false,
}: InfographicProps) {
  const { t } = useTranslation();

  const { isMobile } = useCheckIsMobile();

  // TODO: toFormattedDate > 날짜 연산에 사용되는 포맷은 수정 보류
  const currentDate = toFormattedDate(new Date(), "YYYY-MM-DD");

  /**
   * 운송이 끝났는지 확인 (현재 날짜가 도착지 날짜보다 큰 경우)
   * arriveTime의 값이 없거나, 날짜 형식이 아닌 string으로 포매팅하는 경우 유효하지 않은 날짜로 판단, 도착하지 않은 걸로 간주
   */
  const isTransportComplete = (() => {
    if (!checkIsValidDate(realtimeTransportInfo.arriveTime)) return false;

    return hasDayPassed(new Date(realtimeTransportInfo.arriveTime));
  })();

  const isAirFreightType = freightType === "AIR";

  /** 현재 날짜 기준 가장 가까운 운송지 정보 */
  const nearestTransport: InfographicTransferInfo = useMemo(() => {
    const transferShipmentDateList: InfographicTransferInfo[] =
      transferShipmentList.map(
        ({ shipName, ETA, ETD, ATA, ATD, transportType }) => ({
          shipName,
          ETA,
          ETD,
          ATA,
          ATD,
          transportType,
        })
      );

    /** 출발지, 환적지, 도착지 날짜들을 가공해서 모은 리스트 */
    const allTransportList: InfographicTransferInfo[] = [
      {
        ETA: null,
        ETD: null,
        ATA: null,
        ATD: realtimeTransportInfo.departureTime,
        transportType:
          realtimeTransportInfo.freightType === "AIR" ? "air" : "sea",
      },
      ...transferShipmentDateList,
    ];

    const descendingByTransportDate = allTransportList.sort(
      (prevTransport, nextTransport) => {
        const prevTransportRemainDays = getRemainedDays(
          prevTransport.ATA || prevTransport.ATD || "",
          currentDate
        );

        const nextTransportRemainDays = getRemainedDays(
          nextTransport.ATA || nextTransport.ATD || "",
          currentDate
        );

        return prevTransportRemainDays - nextTransportRemainDays;
      }
    );

    return descendingByTransportDate[0];
  }, [transferShipmentList, realtimeTransportInfo, currentDate]);

  /** 출발지-도착지 총 이동기간 값 (총 운송기간) */
  const totalTransportDays = getRemainedDays(
    realtimeTransportInfo.departureTime,
    realtimeTransportInfo.arriveTime
  );

  /** 출발지 날짜에서 오늘까지 얼마나 지났는지 값 */
  const remainedRealtimeTransportDays = getRemainedDays(
    realtimeTransportInfo.departureTime,
    currentDate
  );

  /**
   * 프론트에서 가공하는 환적 데이터와 날짜 데이터 배열
   *
   * [가공데이터, 환적데이터, 가공데이터, ...] 구조를 만들어서 노출
   */
  const combineTransferShipmentTimeTaken = useMemo(() => {
    // 환적지가 없는 경우 출발지-도착지간 걸리는 시간 리턴
    if (!transferShipmentList.length) {
      return [createTimeTaken(totalTransportDays)];
    }

    return transferShipmentList.reduce(
      (initialTransferShipment, current, index) => {
        const hasNextTransferShipment = Boolean(
          transferShipmentList[index + 1]
        );

        /** 현재 환적지 날짜 */
        const currentTransferShipmentDate = getTransferShipmentDate({
          ATA: current.ATA,
          ETA: current.ETA,
          ATD: current.ATD,
          ETD: current.ETD,
        });

        /** 이전 환적지 날짜 */
        const prevTransferShipmentDate = getTransferShipmentDate({
          ATA: transferShipmentList[index - 1]?.ATA,
          ETA: transferShipmentList[index - 1]?.ETA,
          ATD: transferShipmentList[index - 1]?.ATD,
          ETD: transferShipmentList[index - 1]?.ETD,
        });

        /** 출발지-환적지, 환적지-환적지, 환적지-도착지간 걸리는 시간을 계산한 값 */
        const calcRemainedTransportDays = (() => {
          const isFirstTransferShipment = index === 0;

          // 첫번째 환적지의 경우 -> 출발지의 출발일자와 환적지의 도착일자와 비교
          if (isFirstTransferShipment) {
            return getRemainedDays(
              realtimeTransportInfo.departureTime,
              currentTransferShipmentDate
            );
          }

          // 다음 환적지가 있는 경우 -> 현재 환적지 도착일자와 이전 환적지의 도착일자와 비교
          return getRemainedDays(
            prevTransferShipmentDate,
            currentTransferShipmentDate
          );
        })();

        // 환적지 데이터 앞에 시간 데이터를 삽입
        initialTransferShipment.push(
          createTimeTaken(calcRemainedTransportDays),
          current
        );

        // 다음 환적지가 없는 마지막 환적지의 경우, 환적지가 한개인 경우 -> 현재 환적지 날짜와 도착지의 날짜를 비교
        if (!hasNextTransferShipment) {
          const lastRemainedDays = getRemainedDays(
            currentTransferShipmentDate,
            realtimeTransportInfo.arriveTime
          );

          // 환적지 데이터 뒤에 시간 데이터를 삽입
          initialTransferShipment.push(createTimeTaken(lastRemainedDays));
        }

        return initialTransferShipment;
      },
      [] as InfographicTransferShipment[]
    );
  }, [realtimeTransportInfo, transferShipmentList, totalTransportDays]);

  /**
   * 현재 진행중인 위치에 따른 progress 퍼센트
   *
   * 출발지에서 오늘이 얼마나 지났는지 값 / 총 운송기간 * 100
   */
  const processingWidth = (() => {
    if (isOnlyDottedLine) return 0;

    /** 출발 이전인 경우 0으로 고정 */
    if (remainedRealtimeTransportDays <= 0) return 0;

    if (isTransportComplete) return 99;

    return (remainedRealtimeTransportDays / totalTransportDays) * 100;
  })();

  const realtimeTransportIcon = (() => {
    switch (true) {
      case nearestTransport.transportType === "sea":
        return (
          <Ship3Icon
            width={isMobile ? 16 : 24}
            height={24}
            color={COLOR.grayScale_800}
          />
        );

      case nearestTransport.transportType === "barge":
        return (
          <BargeIcon
            width={isMobile ? 16 : 24}
            height={24}
            color={COLOR.grayScale_800}
          />
        );

      case nearestTransport.transportType === "LTS":
        return (
          <TruckAndTrainIcon
            width={isMobile ? 16 : 24}
            height={24}
            color={COLOR.grayScale_800}
          />
        );

      default:
        return (
          <PlaneIcon
            width={isMobile ? 16 : 24}
            height={24}
            color={COLOR.grayScale_800}
          />
        );
    }
  })();

  return (
    <Styled.container
      backgroundColor={backgroundColor}
      className={`infographic-container ${className}`}
    >
      <Styled.schedule
        className="infographic-schedule"
        processingWidth={processingWidth}
        isTransportComplete={isTransportComplete}
        isShipdaAdmin={APP_NAME === "shipda-admin"}
        needsMobileStyle={needsMobileStyle}
      >
        <Styled.portList
          className="infographic-port-list"
          needsMobileStyle={needsMobileStyle}
        >
          {/* 출발지 정보 */}
          <Styled.portGroup
            isVisibleDate
            className="infographic-port-group"
            textPosition="left"
            needsMobileStyle={needsMobileStyle}
          >
            <div className="dot">
              <DotIcon width={10} height={10} color={COLOR.grayScale_500} />
            </div>

            <div className="port-info">
              <div className="port">
                <span>{startPort?.code}</span>

                <Tooltip
                  position="topLeft"
                  desc={
                    <>
                      {ShipdaCurrentLanguage.currentLanguage === "en"
                        ? `${startPort?.nameEN}`
                        : `${replaceEmptyToDash(startPort?.name)} / ${
                            startPort?.nameEN
                          }`}
                    </>
                  }
                  /** 어드민 > 운영 관리 상세 모달보다 더 높게 위치하기 위해 조정 */
                  tooltipBodyStyle={css`
                    z-index: 1400;
                  `}
                >
                  <InfoFilledIcon
                    width={10}
                    height={10}
                    color={COLOR.grayScale_500}
                  />
                </Tooltip>
              </div>

              <p className="date">
                ({realtimeTransportInfo.departure}:&nbsp;
                {realtimeTransportInfo.departureTime})
              </p>
            </div>
          </Styled.portGroup>

          {/* 환적 정보 */}
          {combineTransferShipmentTimeTaken.map(
            (
              { ATD = "", ATA = "", port, remainedDays },
              index,
              combineTransferShipmentList
            ) => {
              /** 환적지 도착날짜 */
              const arrivalDate =
                // TODO: toFormattedDate > 날짜 연산에 사용되는 포맷은 수정 보류
                toFormattedDate(ATD, "YYYY-MM-DD") ||
                // TODO: toFormattedDate > 날짜 연산에 사용되는 포맷은 수정 보류
                toFormattedDate(ATA, "YYYY-MM-DD") ||
                "";

              /** 현재 선박, 항공의 운항중인 날짜와 환적지 도착 날짜까지 남은 일자 */
              const realtimeRemainedDays = getRemainedDays(
                currentDate,
                arrivalDate
              );

              const { name, nameEN, code } = port;

              /** 현재 선박, 항공이 환적지를 가고 있는지, 지나갔는지 여부 */
              const isDone = realtimeRemainedDays <= 0;

              /** 환적지까지 걸리는 시간을 나타내는 가공데이터인지 여부 */
              const isTimeTakenData = Boolean(remainedDays);

              const isValidTransferData = Boolean(code || name || nameEN);

              if (isTimeTakenData) {
                /** 다음 환적지 혹은 도착지에 도착했는지 여부 */
                const hasArrivedNextTransport = (() => {
                  const ATA = combineTransferShipmentList[index + 1]?.ATA;

                  if (!checkIsValidDate(ATA)) return false;

                  return hasDayPassed(new Date(ATA));
                })();

                /**
                 * 환적지-도착지를 도착했는지 여부
                 *
                 * 운항이 종료됐거나, 다음 환적지의 ATA 데이터가 있는 경우
                 */
                const isNextTransportArrivals =
                  isDone || hasArrivedNextTransport;

                if (hidesRemainedDays) {
                  return null;
                }

                return (
                  <Styled.timeLeft
                    key={index}
                    isDone={false}
                    className="infographic-time-left"
                    needsMobileStyle={needsMobileStyle}
                  >
                    {remainedDays && (
                      <>
                        {remainedDays}days&nbsp;
                        {!isNextTransportArrivals && (
                          <em className="desc">
                            ({t("page-mypage-bid:운송관리_상세_운송현황_예상")})
                          </em>
                        )}
                      </>
                    )}
                  </Styled.timeLeft>
                );
              }

              return (
                <Styled.portGroup
                  className="infographic-port-group"
                  key={index}
                  textPosition="center"
                  isVisibleDate={false}
                  needsMobileStyle={needsMobileStyle}
                >
                  {isValidTransferData && (
                    <div className="dot">
                      {isDone ? (
                        <DotIcon
                          width={10}
                          height={10}
                          color={COLOR.grayScale_500}
                        />
                      ) : (
                        <DotOutlinedIcon width={10} height={10} />
                      )}
                    </div>
                  )}

                  <div className="port-info">
                    <div className="port">
                      {isValidTransferData && (
                        <>
                          <span>{code}</span>

                          <Tooltip
                            position="topCenter"
                            desc={
                              <>
                                {ShipdaCurrentLanguage.currentLanguage === "en"
                                  ? `${nameEN}`
                                  : `${replaceEmptyToDash(name)} / ${nameEN}`}
                                &nbsp;
                                {!isDone &&
                                  `(${t(
                                    "page-mypage-bid:운송관리_상세_운송현황_환적예정"
                                  )})`}
                              </>
                            }
                            /** 어드민 > 운영 관리 상세 모달보다 더 높게 위치하기 위해 조정 */
                            tooltipBodyStyle={css`
                              z-index: 1400;
                            `}
                          >
                            <InfoFilledIcon
                              width={10}
                              height={10}
                              color={COLOR.grayScale_500}
                            />
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </div>
                </Styled.portGroup>
              );
            }
          )}

          {/* 도착지 정보 */}
          <Styled.portGroup
            isVisibleDate
            className="infographic-port-group"
            textPosition="right"
            needsMobileStyle={needsMobileStyle}
          >
            <div className="dot">
              <DotIcon width={10} height={10} color={COLOR.grayScale_500} />
            </div>

            <div className="port-info">
              <div className="port">
                <span>{endPort?.code}</span>

                <Tooltip
                  position="topRight"
                  desc={
                    <>
                      {ShipdaCurrentLanguage.currentLanguage === "en"
                        ? `${endPort?.nameEN}`
                        : `${replaceEmptyToDash(endPort?.name)} / ${
                            endPort?.nameEN
                          }`}
                    </>
                  }
                  /** 어드민 > 운영 관리 상세 모달보다 더 높게 위치하기 위해 조정 */
                  tooltipBodyStyle={css`
                    z-index: 1400;
                  `}
                >
                  <InfoFilledIcon
                    width={10}
                    height={10}
                    color={COLOR.grayScale_500}
                  />
                </Tooltip>
              </div>

              <p className="date">
                ({realtimeTransportInfo.arrive}:&nbsp;
                {realtimeTransportInfo.arriveTime})
              </p>
            </div>
          </Styled.portGroup>

          {!hidesRealtimePosition && (
            <Styled.realtimePosition
              className="infographic-realtime-position"
              position={processingWidth}
              isTransportComplete={isTransportComplete}
              isAirFreightType={isAirFreightType}
              isMobile={isMobile}
              needsMobileStyle={needsMobileStyle}
            >
              {realtimeTransportIcon}
            </Styled.realtimePosition>
          )}
        </Styled.portList>
      </Styled.schedule>
    </Styled.container>
  );
}
