import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SaveIcon from "@mui/icons-material/Save";
import { Button, FormGroup } from "@mui/material";

import { ADMIN_USER_DATA_WITH_PASSWORD } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminUser";
import ADMIN_USER_QUERY, {
  ADMIN_USER_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_USER_QUERY";
import SelectWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/SelectWithReactHookForm";
import SwitchWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/SwitchWithReactHookForm";
import TextFieldWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/TextFieldWithReactHookForm";

const AdminForm = ({
  data,
  id,
}: {
  data: ADMIN_USER_DATA_WITH_PASSWORD | undefined;
  id: string;
}) => {
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<ADMIN_USER_DATA_WITH_PASSWORD>({ mode: "onSubmit" });

  const getAdminDetailQueryKey = ADMIN_USER_QUERY_KEY_GEN.getAdminUserDetail({
    adminId: Number(id),
  });

  // toggle = switch는 default value와 value 중 하나만을 사용하도록 되어 있어서
  // 수정 페이지에서 default value로 데이터를 세팅할 경우, onChange로는 값이 변경되지 않는 문제가 있습니다.
  // 그래서 reset과 useEffect를 사용하여 form 소환 후에 토글데이터를 덮어씌워주고 있습니다.

  useEffect(() => {
    reset({
      isForwardingManager: data?.isForwardingManager,
      isFullfillmentManager: data?.isFullfillmentManager,
    });
  }, [data, reset]);

  const { mutate: updateAdminUserDetail, ResponseHandler: UpdateResponse } =
    ADMIN_USER_QUERY.useUpdateAdminUserDetail({
      successModalInfo: {
        handleConfirmSuccess: (initQuery) => {
          initQuery();
          history.go(-1);
        },
        customizeMessage: () => ({
          title: "수정이 완료되었습니다.",
        }),
      },
      failureModalInfo: {
        customizeMessage: () => ({
          title: "수정에 실패했습니다.",
        }),
      },
    });

  const { mutate: deleteAdminUserDetail, ResponseHandler: DeleteResponse } =
    ADMIN_USER_QUERY.useDeleteAdminUserDetail({
      adminId: Number(id),
      options: {
        successModalInfo: {
          handleConfirmSuccess: (initQuery) => {
            initQuery();
            history.go(-1);
          },
          customizeMessage: () => ({
            title: "삭제가 완료되었습니다.",
          }),
        },
      },
    });

  const onSubmit: SubmitHandler<ADMIN_USER_DATA_WITH_PASSWORD> = (
    sand: ADMIN_USER_DATA_WITH_PASSWORD
  ) => {
    updateAdminUserDetail(
      {
        id: Number(id),
        accountId: sand.accountId,
        email: sand.email,
        isForwardingManager:
          sand.isForwardingManager !== undefined
            ? sand.isForwardingManager
            : false,
        isFullfillmentManager:
          sand.isFullfillmentManager !== undefined
            ? sand.isFullfillmentManager
            : false,
        authority: sand.authority,
        name: sand.name,
        nameEN: sand.nameEN,
        password: sand.password,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(getAdminDetailQueryKey);
        },
      }
    );
  };

  const handleAdminUserDelete = () => {
    deleteAdminUserDetail({
      adminId: Number(id),
    });
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup
          sx={{ display: "flex", flexDirection: "column", gap: 2, mb: 2 }}
        >
          <p>No. {id ? id : "-"}</p>

          <SelectWithReactHookForm
            variant="outlined"
            size="small"
            label="권한"
            name="authority"
            control={control}
            defaultValue={data?.authority || "master"}
            options={["master", "manager", "finance"]}
          />

          <TextFieldWithReactHookForm
            variant="outlined"
            size="small"
            label="아이디"
            name="accountId"
            control={control}
            defaultValue={data?.accountId}
          />

          <TextFieldWithReactHookForm
            variant="outlined"
            size="small"
            label="이름"
            name="name"
            control={control}
            defaultValue={data?.name}
          />

          <TextFieldWithReactHookForm
            variant="outlined"
            size="small"
            label="영문 이름"
            name="nameEN"
            control={control}
            defaultValue={data?.nameEN}
            required
          />

          <TextFieldWithReactHookForm
            variant="outlined"
            size="small"
            label="이메일"
            name="email"
            control={control}
            defaultValue={data?.email}
          />

          <TextFieldWithReactHookForm
            variant="outlined"
            size="small"
            label="비밀번호"
            name="password"
            type="password"
            control={control}
          />

          <SwitchWithReactHookForm
            name="isForwardingManager"
            control={control}
            label="포워딩 관리자 여부"
          />

          <SwitchWithReactHookForm
            name="isFullfillmentManager"
            control={control}
            label="풀필먼트 관리자 여부"
          />
        </FormGroup>

        <Button type="submit" variant="outlined">
          <SaveIcon /> 수정
        </Button>

        <Button
          type="button"
          variant="outlined"
          color="error"
          onClick={handleAdminUserDelete}
          sx={{ ml: 2 }}
        >
          <DeleteForeverIcon />
          삭제
        </Button>
      </form>

      {UpdateResponse}
      {DeleteResponse}
    </>
  );
};

export default AdminForm;
