import { useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AlertColor } from "@mui/material";
import { useAtomValue } from "jotai";
import { useSetRecoilState } from "recoil";

import { UPDATE_BID_QUOTE_DRAFT_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBid";
import ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import { FORWARDING_ADMIN_BID_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminBid";
import { Liner } from "@sellernote/_shared/src/types/common/common";
import {
  AdminBidDetail,
  ApplyBidFormData,
} from "@sellernote/_shared/src/types/forwarding/adminBid";
import { ExchangeRate } from "@sellernote/_shared/src/types/forwarding/trello";
import {
  getInlandType,
  getLinerId,
  getQuotationDataCurrency,
} from "@sellernote/_shared/src/utils/forwarding/admin/adminBid";
import {
  findExchangeRate,
  getAllTotalItemPriceOfFeeData,
} from "@sellernote/_shared/src/utils/forwarding/admin/tradingStatement";
import { FORWARDING_ADMIN_AUTH_SELECTORS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/auth";

import { removePreviousItemPrice } from "../change-user-quotation/:id/utils";

import { BidApplyParams } from ".";

export default function useUpdateRequest({
  shipmentDetail,
  linerList,
  exchangeRateList,
  formData,
  onSnackbarOpen,
}: {
  shipmentDetail: AdminBidDetail | undefined;
  linerList: Liner[] | undefined;
  exchangeRateList: ExchangeRate[] | undefined;
  formData: ApplyBidFormData;
  onSnackbarOpen: (message: string, color?: AlertColor) => void;
}) {
  const [showsQuotationUserMailModal, setShowsQuotationUserMailModal] =
    useState(false);

  const history = useHistory();

  const { id, freightType } = useParams<BidApplyParams>();

  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const setTabDefaultActiveKey = useSetRecoilState(
    FORWARDING_ADMIN_BID_ATOMS.ADMIN_BID_DETAIL_TAB_DEFAULT_ACTIVE_KEY
  );

  const {
    mutate: updateBidQuoteDraft,
    isLoading: isImportBidQuoteDraftUpdateLoading,
  } = ADMIN_BID_QUERY.useUpdateBidQuoteDraft();

  const {
    mutate: updateExportBidQuoteDraft,
    isLoading: isExportBidQuoteDraftUpdateLoading,
  } = ADMIN_BID_QUERY.useUpdateExportBidQuoteDraft();

  const {
    mutate: changeUserQuotation,
    isLoading: isImportUserQuotationChangeLoading,
  } = ADMIN_BID_QUERY.useChangeUserQuotation();

  const {
    mutate: changeUserExportQuotation,
    isLoading: isExportUserQuotationChangeLoading,
  } = ADMIN_BID_QUERY.useChangeUserExportQuotation();

  const {
    freightFeeData,
    domesticFeeData,
    localFeeData,
    otherFeeData,
    inlandFeeData,
    taxFeeData,
    freightPaymentType,
    comment,
    liner,
    leadtime,
    isTransit,
    expiredAt,
  } = formData;

  const handleQuotationUserUpdate = useCallback(
    (allowsSendmail: boolean) => {
      if (!currentAdminAuthInfo || !shipmentDetail || !linerList) {
        onSnackbarOpen("견적 관련 데이터가 없습니다.", "warning");
        return;
      }

      const requestPayload = {
        pathParams: {
          bidId: shipmentDetail.id,
          id: shipmentDetail.quotationsUser[0].id,
        },
        quotationData: {
          currency: getQuotationDataCurrency({
            localFee: localFeeData,
            freightFee: freightFeeData,
            domesticFee: domesticFeeData,
            inlandFee: inlandFeeData,
            otherFee: otherFeeData,
            taxFee: taxFeeData,
            exchangeRateList: exchangeRateList,
          }),
          freightPaymentType: freightPaymentType,
          liner: liner,
          isTransit: isTransit,
          exchangeRate: findExchangeRate(exchangeRateList, "USD"),
          totalPrice: getAllTotalItemPriceOfFeeData(
            freightFeeData,
            domesticFeeData,
            localFeeData,
            inlandFeeData,
            otherFeeData,
            taxFeeData
          ),
          freightFee: removePreviousItemPrice({
            feeList: freightFeeData,
          }),
          localFee: removePreviousItemPrice({
            feeList: localFeeData,
          }),
          domesticFee: removePreviousItemPrice({
            feeList: domesticFeeData,
          }),
          inlandFee: removePreviousItemPrice({
            feeList: inlandFeeData,
          }),
          taxFee: removePreviousItemPrice({
            feeList: taxFeeData,
          }),
          otherFee: removePreviousItemPrice({
            feeList: otherFeeData,
          }),
          comment: comment,
          expiredAt: expiredAt,
          leadtime: leadtime,
          linerId: getLinerId(linerList, liner),
        },
        notiModifiedQuote: allowsSendmail,
      };

      if (shipmentDetail?.isImport) {
        changeUserQuotation(requestPayload, {
          onSuccess: () => {
            history.replace(`/bid/detail/${id}`);
          },

          onError: () => {
            onSnackbarOpen("요청에 실패했습니다.", "error");
          },
        });

        return;
      }

      changeUserExportQuotation(requestPayload, {
        onSuccess: () => {
          history.replace(`/bid/detail/${id}`);
        },

        onError: () => {
          onSnackbarOpen("요청에 실패했습니다.", "error");
        },
      });
      return;
    },
    [
      currentAdminAuthInfo,
      shipmentDetail,
      linerList,
      localFeeData,
      freightFeeData,
      domesticFeeData,
      inlandFeeData,
      otherFeeData,
      taxFeeData,
      exchangeRateList,
      freightPaymentType,
      liner,
      isTransit,
      comment,
      expiredAt,
      leadtime,
      changeUserExportQuotation,
      changeUserQuotation,
      history,
      id,
      onSnackbarOpen,
    ]
  );

  const handleBidQuoteDraftUpdate = useCallback(() => {
    if (!currentAdminAuthInfo || !shipmentDetail || !linerList) {
      onSnackbarOpen("견적 관련 데이터가 없습니다.", "warning");
      return;
    }

    const requestPayload: UPDATE_BID_QUOTE_DRAFT_REQ = {
      quotationData: {
        freightFee: freightFeeData,
        domesticFee: domesticFeeData,
        localFee: localFeeData,
        taxFee: taxFeeData,
        inlandFee: inlandFeeData,
        otherFee: otherFeeData,
        freightPaymentType: freightPaymentType,
        bidId: Number(id),
        managerId: currentAdminAuthInfo.id,
        comment: comment,
        startCountry: shipmentDetail.startCountry,
        startPortId: shipmentDetail.startPort
          ? shipmentDetail.startPortId
          : shipmentDetail.startViaPortId,
        endCountry: shipmentDetail.endCountry,
        endPortId: shipmentDetail.endPort
          ? shipmentDetail.endPortId
          : shipmentDetail.endViaPortId,
        endZoneId: shipmentDetail.zoneId,
        incoterms: shipmentDetail.incoterms,
        freightType: freightType,
        volumn: "",
        liner: liner,
        linerId: getLinerId(linerList, liner),
        leadtime: leadtime,
        isTransit: isTransit,
        currency: getQuotationDataCurrency({
          freightFee: freightFeeData,
          domesticFee: domesticFeeData,
          localFee: localFeeData,
          taxFee: taxFeeData,
          inlandFee: inlandFeeData,
          otherFee: otherFeeData,
          exchangeRateList: exchangeRateList,
        }),
        exchangeRate: findExchangeRate(exchangeRateList, "USD"),
        totalPrice: getAllTotalItemPriceOfFeeData(
          freightFeeData,
          domesticFeeData,
          localFeeData,
          inlandFeeData,
          otherFeeData,
          taxFeeData
        ),
        expiredAt: expiredAt,
      },

      // 수출 요청에서는 자동적으로 inlandType을 설정해줌
      inlandType: shipmentDetail.isImport
        ? getInlandType(inlandFeeData, freightType)
        : undefined,
    };

    if (shipmentDetail.isImport) {
      updateBidQuoteDraft(requestPayload, {
        onSuccess: () => {
          // 견적지원 후 의뢰 상세로 돌아갈 때 견적지원 탭으로 이동하기 위해 탭 키 변경
          setTabDefaultActiveKey(0);
          history.replace(`/bid/detail/${id}`);
        },

        onError: () => {
          onSnackbarOpen("요청에 실패했습니다.", "error");
        },
      });

      return;
    }

    updateExportBidQuoteDraft(requestPayload, {
      onSuccess: () => {
        // 견적지원 후 의뢰 상세로 돌아갈 때 견적지원 탭으로 이동하기 위해 탭 키 변경
        setTabDefaultActiveKey(0);
        history.replace(`/bid/detail/${id}`);
      },

      onError: () => {
        onSnackbarOpen("요청에 실패했습니다.", "error");
      },
    });

    return;
  }, [
    currentAdminAuthInfo,
    shipmentDetail,
    linerList,
    freightFeeData,
    domesticFeeData,
    localFeeData,
    taxFeeData,
    inlandFeeData,
    otherFeeData,
    freightPaymentType,
    id,
    comment,
    freightType,
    liner,
    leadtime,
    isTransit,
    exchangeRateList,
    expiredAt,
    updateExportBidQuoteDraft,
    onSnackbarOpen,
    updateBidQuoteDraft,
    setTabDefaultActiveKey,
    history,
  ]);

  const handleQuotationUpdate = () => {
    /** 확정견적이 있는 경우 확정견적 메일 모달을 오픈*/
    if (shipmentDetail && shipmentDetail?.quotationsUser.length > 0) {
      setShowsQuotationUserMailModal(true);
      return;
    }
    handleBidQuoteDraftUpdate();
  };

  const isLoading = useMemo(() => {
    if (shipmentDetail && shipmentDetail?.quotationsUser.length > 0) {
      return shipmentDetail?.isImport
        ? isImportUserQuotationChangeLoading
        : isExportUserQuotationChangeLoading;
    }

    return shipmentDetail?.isImport
      ? isImportBidQuoteDraftUpdateLoading
      : isExportBidQuoteDraftUpdateLoading;
  }, [
    isExportBidQuoteDraftUpdateLoading,
    isExportUserQuotationChangeLoading,
    isImportBidQuoteDraftUpdateLoading,
    isImportUserQuotationChangeLoading,
    shipmentDetail,
  ]);

  return {
    isLoading,
    showsQuotationUserMailModal,
    setShowsQuotationUserMailModal,
    handleQuotationUserUpdate,
    handleQuotationUpdate,
  };
}
