import { useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { TextField } from "@mui/material";

import {
  formatDate,
  transformUTCDateToLocalDateTime,
} from "@sellernote/_shared/src/utils/common/date";
import Calendar from "@sellernote/_sds-v1/src/components/Calendar";

import Styled from "./index.styles";

export default function DatePicker({
  label,
  when,
  date,
  setDate,
  disabled,
  reset,
}: {
  label: string;
  when: "start" | "end";
  date: string;
  setDate: (v: string) => void;
  disabled?: boolean;
  reset?: () => void;
}) {
  const [isVisibleCalendar, setIsVisibleCalendar] = useState(false);

  return (
    <Styled.datePicker>
      <TextField
        label={label}
        size="small"
        value={formatDate({ date, type: "YY_MM_DD" })}
        onClick={() => {
          if (!disabled) setIsVisibleCalendar(!isVisibleCalendar);
        }}
        disabled={disabled}
      />

      {reset && (
        <div
          className="clear"
          onClick={(e) => {
            e.stopPropagation();
            reset();
          }}
        >
          <HighlightOffIcon color="action" fontSize="small" />
        </div>
      )}

      <Calendar
        className={`calendar ${isVisibleCalendar ? "" : "hidden"}`}
        inputLabel=""
        dateValue={date}
        setDateValue={(v) => {
          // 클라이언트의 타임존을 고려하여 변환된 Date로 셋팅
          setDate(
            transformUTCDateToLocalDateTime({
              utcDateTime: v,
              timeZone: "Asia/Seoul",
              when,
            })?.toISOString() || ""
          );
          setIsVisibleCalendar(false);
        }}
        isIncludedTime={false}
        displayHolidays="enabled"
        inline
      />
    </Styled.datePicker>
  );
}
