import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";

/**
 * 사용처
 *  - 운영 관리 카드 상세 > 컨테이너 정보
 *  - 운영 관리 카드 상세 > 적하목록 제출 > 컨테이너 정보
 *  - 운영 관리 카드 상세 > HBL 생성/수정 > 컨테이너 정보
 *
 * 'id'가 존재하는 경우에만 삭제, 'id'가 존재하지 않는 경우는 'index'를 활용해 폼(state)에서 삭제처리
 */
export default function useDeleteFormContainer({
  onSuccess,
}: {
  onSuccess: () => void;
}) {
  const {
    mutate: deleteContainerData,
    ResponseHandler: ResponseHandlerOfDeleteContainerData,
  } = TRELLO_BID_QUERY.useDeleteContainerData({ onSuccess });

  /**
   * 컨테이너 삭제 API 요청 함수
   * @param idList 삭제할 컨테이너 id 리스트
   * @param onSuccessCallback 함수 실행시 동적으로 콜백 함수를 실행해야 할 경우 사용
   */
  const deleteContainer = ({
    idList,
    onSuccessCallback,
  }: {
    idList: number[];
    onSuccessCallback?: () => void;
  }) => {
    deleteContainerData(
      { containerIds: idList },
      { onSuccess: () => onSuccessCallback?.() }
    );
  };

  return { deleteContainer, ResponseHandlerOfDeleteContainerData };
}
