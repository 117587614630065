import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import BackupIcon from "@mui/icons-material/Backup";
import {
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import { AdminBidUserFreightType } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBidUser";
import { PROMOTION_FREIGHT_TYPE_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/adminPromotion";
import ADMIN_PROMOTION_QUERY, {
  ADMIN_PROMOTION_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_PROMOTION_QUERY";
import {
  AdminPromotionDeactivateReason,
  AdminPromotionDetail,
  AdminPromotionDetailStatus,
} from "@sellernote/_shared/src/types/forwarding/adminPromotion";
import { formatDate } from "@sellernote/_shared/src/utils/common/date";
import {
  PROMOTION_CANCELED_OPTION_LIST,
  PROMOTION_DEACTIVATE_REASON_OPTION_LIST,
  PROMOTION_PENDING_OPTION_LIST,
} from "@sellernote/_shared/src/utils/common/options";
import { changeUserRegisterTypeToKr } from "@sellernote/_shared/src/utils/forwarding/admin/adminUser";
import {
  changePromotionDeactivateReasonToKr,
  changePromotionDetailStatusToKr,
  changePromotionStatusToKr,
} from "@sellernote/_shared/src/utils/forwarding/admin/promotion";
import AdminDetailDescription from "@sellernote/_shared-for-forwarding-admin/src/components/AdminDetailDescription";
import Modal from "@sellernote/_shared-for-forwarding-admin/src/components/Modal";
import TextField from "@sellernote/_shared-for-forwarding-admin/src/components/TextField";

import PromotionFileUpload from "../PromotionFileUploadModal";

const DetailDescription = ({
  detailData,
}: {
  detailData: AdminPromotionDetail;
}) => {
  const queryClient = useQueryClient();

  const history = useHistory();

  const [showsUploadModal, setShowsUploadModal] = useState(false);
  const [showsNoteInput, setShowsNoteInput] = useState(false);
  const [note, setNote] = useState(detailData.note);
  const [showsDeactivateReasonModal, setShowsDeactivateReasonModal] =
    useState(false);

  const {
    mutate: editPromotion,
    ResponseHandler: ResponseHandlerOfEditPromotion,
  } = ADMIN_PROMOTION_QUERY.useEditPromotion({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        if (showsNoteInput) {
          setShowsNoteInput(false);
        }
        initQuery();
        queryClient.invalidateQueries(ADMIN_PROMOTION_QUERY_KEY_GEN.all());
      },
      customizeMessage: () => ({
        title: "수정했습니다.",
      }),
    },
  });

  const handlePromotionActivateFlagChange = useCallback(
    (selectValue: string) => {
      if (selectValue === "true") {
        editPromotion({
          activateFlag: true,
          deactivateReason: null,
          pathParams: {
            promotionDetailId: detailData.id,
          },
        });
        return;
      }

      setShowsDeactivateReasonModal(true);
    },
    [detailData.id, editPromotion]
  );

  const handlePromotionUserFreightTypeChange = useCallback(
    (freightType: AdminBidUserFreightType | null) => {
      editPromotion({
        userFreightType: freightType,
        pathParams: {
          promotionDetailId: detailData.id,
        },
      });
    },
    [detailData.id, editPromotion]
  );

  const handleNoteInputChange = useCallback((e) => {
    setNote(e.target.value);
  }, []);

  const handlePromotionNoteEdit = useCallback(() => {
    editPromotion({
      note,
      pathParams: {
        promotionDetailId: detailData.id,
      },
    });
  }, [detailData.id, editPromotion, note]);

  const handlePromotionNoteInputModeChange = () => {
    if (showsNoteInput) {
      setShowsNoteInput(false);
    } else {
      setShowsNoteInput(true);
    }
  };

  return (
    <>
      <AdminDetailDescription
        title={"프로모션 사용자 상세"}
        descriptionValueList={[
          {
            label: "신청번호",
            value: detailData.id,
            gridSize: 12,
          },
          {
            label: "회사명",
            value: detailData.user?.company || detailData.companyName,
            gridSize: 12,
          },
          {
            label: "성함",
            value: detailData.user?.name || detailData.userName,
            gridSize: 12,
          },
          {
            label: "연락처",
            value: detailData.user?.email || detailData.email,
            gridSize: 12,
          },
          {
            label: "가입일",
            value: detailData.user
              ? formatDate({
                  date: detailData.user.createdAt,
                  type: "YY_MM_DD",
                })
              : "",
            gridSize: 12,
          },
          {
            label: "유입구분",
            value: detailData.user
              ? changeUserRegisterTypeToKr(detailData.user.registerType)
              : "구분 없음",
            gridSize: 12,
          },
          {
            label: "신청일",
            value: formatDate({
              date: detailData.createdAt,
              type: "YY_MM_DD",
            }),
            gridSize: 12,
          },
          {
            label: "프로모션 대상",
            value: (
              <FormControl variant="standard">
                <Select
                  value={detailData.activateFlag ? "true" : "false"}
                  onChange={(e) =>
                    handlePromotionActivateFlagChange(e.target.value)
                  }
                >
                  <MenuItem key={"true"} value={"true"}>
                    {"O"}
                  </MenuItem>
                  <MenuItem key={"false"} value={"false"}>
                    {"X"}
                  </MenuItem>
                </Select>
              </FormControl>
            ),
            gridSize: 7,
          },
          {
            label: "제외사유",
            value: detailData.activateFlag ? (
              changePromotionDeactivateReasonToKr(detailData.deactivateReason)
            ) : (
              <FormControl variant="standard">
                <Select
                  value={detailData.deactivateReason}
                  onChange={(e) => {
                    editPromotion({
                      deactivateReason: e.target.value,
                      pathParams: {
                        promotionDetailId: detailData.id,
                      },
                    });
                  }}
                >
                  {PROMOTION_DEACTIVATE_REASON_OPTION_LIST.map((v) => {
                    return (
                      <MenuItem key={v.value} value={v.value}>
                        {v.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ),
            gridSize: 5,
          },
          {
            label: "상태",
            value: changePromotionStatusToKr(detailData.status),
            gridSize: 7,
          },
          {
            label: `${detailData.status === "canceled" ? "취소" : "보류"}사유`,
            value:
              detailData.status === "pending" ||
              detailData.status === "canceled" ? (
                <FormControl variant="standard">
                  <Select
                    sx={{ width: "200px" }}
                    value={detailData.detailStatus}
                    onChange={(e) => {
                      editPromotion({
                        detailStatus: e.target
                          .value as AdminPromotionDetailStatus,
                        pathParams: {
                          promotionDetailId: detailData.id,
                        },
                      });
                    }}
                  >
                    {detailData.status === "pending"
                      ? PROMOTION_PENDING_OPTION_LIST.map((v) => {
                          return (
                            <MenuItem key={v.value} value={v.value}>
                              {v.label}
                            </MenuItem>
                          );
                        })
                      : PROMOTION_CANCELED_OPTION_LIST.map((v) => {
                          return (
                            <MenuItem key={v.value} value={v.value}>
                              {v.label}
                            </MenuItem>
                          );
                        })}
                  </Select>
                </FormControl>
              ) : (
                changePromotionDetailStatusToKr(detailData.detailStatus)
              ),
            gridSize: 5,
          },

          {
            label: "견적파일",
            value: (
              <IconButton
                color={
                  detailData.attachments.length > 0 ? "primary" : undefined
                }
                onClick={() => {
                  setShowsUploadModal(true);
                }}
              >
                <BackupIcon />
              </IconButton>
            ),
            gridSize: 12,
          },

          {
            label: "화물타입",
            value: (
              <FormControl variant="standard">
                <Select
                  value={detailData.user?.freightType || "null"}
                  disabled={detailData.user ? false : true}
                  onChange={(e) =>
                    handlePromotionUserFreightTypeChange(
                      e.target.value === "null"
                        ? null
                        : (e.target.value as AdminBidUserFreightType)
                    )
                  }
                >
                  <MenuItem key={"null"} value={"null"}>
                    {"구분없음"}
                  </MenuItem>
                  {PROMOTION_FREIGHT_TYPE_OPTION_LIST.map((v) => {
                    return (
                      <MenuItem key={v.value} value={v.value}>
                        {v.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ),
            gridSize: 12,
          },
          {
            label: "의뢰번호",
            value: (
              <Button
                onClick={() => {
                  if (detailData.bidId) {
                    history.push(`/bid/detail/${detailData.bidId}`);
                  } else {
                    return;
                  }
                }}
              >
                {detailData.bidId}
              </Button>
            ),
            gridSize: 12,
          },
          {
            label: "비고",
            value: showsNoteInput ? (
              <>
                <TextField
                  value={note}
                  onChange={handleNoteInputChange}
                  fullWidth
                />

                <Button variant="outlined" onClick={handlePromotionNoteEdit}>
                  등록
                </Button>

                <Button
                  variant="outlined"
                  color="error"
                  onClick={handlePromotionNoteInputModeChange}
                >
                  취소
                </Button>
              </>
            ) : (
              <div>
                <span>{detailData.note}</span>

                <Button
                  style={{ marginLeft: 16 }}
                  variant="outlined"
                  onClick={handlePromotionNoteInputModeChange}
                >
                  수정
                </Button>
              </div>
            ),
            gridSize: 12,
          },
        ]}
      />

      {showsUploadModal && (
        <PromotionFileUpload
          showsUploadModal={showsUploadModal}
          setShowsUploadModal={setShowsUploadModal}
          listId={detailData.id}
        />
      )}

      <Modal
        isOpened={showsDeactivateReasonModal}
        handleClose={() => setShowsDeactivateReasonModal(false)}
        modalBody={
          <>
            <Typography variant="h6" component="div">
              제외사유를 선택해주세요
            </Typography>

            <FormControl variant="standard">
              <Select
                sx={{ width: "200px" }}
                onChange={(e) =>
                  editPromotion({
                    activateFlag: false,
                    deactivateReason: e.target
                      .value as AdminPromotionDeactivateReason,
                    pathParams: {
                      promotionDetailId: detailData.id,
                    },
                  })
                }
              >
                {PROMOTION_DEACTIVATE_REASON_OPTION_LIST.map((v) => {
                  return (
                    <MenuItem key={v.value} value={v.value}>
                      {v.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </>
        }
      />

      {ResponseHandlerOfEditPromotion}
    </>
  );
};

export default DetailDescription;
