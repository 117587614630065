import createReactComponent from '../createReactComponent';
export default createReactComponent('square-filled', 'IconSquareFilled', [
  [
    'path',
    {
      d: 'M19 2h-14a3 3 0 0 0 -3 3v14a3 3 0 0 0 3 3h14a3 3 0 0 0 3 -3v-14a3 3 0 0 0 -3 -3z',
      fill: 'currentColor',
      key: 'svg-0',
      strokeWidth: '0',
    },
  ],
]);
