import { FC, ReactNode, useState } from "react";

import { useBodyScrollLock } from "../hooks/common/useBodyScrollLock";

import { ButtonIconInfo, ButtonPropsV2 } from "./button/useButton";
import { SvgIconComponent } from "./useSvgIcon";

type ModalPropsV1 = ModalCommonPropsV1 &
  (
    | ModalTitleOnlyProps
    | ModalWebNegativeProps
    | ModalContentProps
    | ModalFormInputProps
    | ModalFormInputWithTabProps
    | ModalPDAFormProps
  );

interface ModalCommonPropsV1 extends ModalCommonProps {
  title: React.ReactNode;
}

interface ModalCommonProps {
  /**
   * 하위 element가 modal범위를 넘어가서 표시되어야 할 때 사용
   */
  allowsOverflow?: boolean;
  barcodeValues?: {
    actionNegative?: string;
    actionPositive: string;
  };
  active: boolean;
  onClose?: () => void;
  needConfirmBeforeCloseModal?: boolean;
  /**
   * activeTabIndex에 따라 값이 변경되어야 하는 경우 index를 key로 사용하는 객체 형태로 전달
   */
  needConfirmBeforeCloseModalInfo?:
    | {
        title?: ReactNode;
        actionPositiveLabel?: ReactNode;
        actionNegativeLabel?: ReactNode;
      }
    | Record<
        string,
        {
          title?: ReactNode;
          actionPositiveLabel?: ReactNode;
          actionNegativeLabel?: ReactNode;
        }
      >;
  className?: string;
  usePortal?: boolean;
  children?: ReactNode;
  /** 패킹웹 > 바코드가 없는 모달인 경우 어떤 모달이 열린 것인지 확인하기 위한 data attributes */
  "data-modal-type"?: string;
}

type ModalComponent = FC<ModalPropsV1>;

type ButtonPartialProps = Partial<Pick<ButtonPropsV2, "theme" | "borderType">>;

type ButtonInModalCommonProps = Omit<
  ButtonPropsV2,
  "size" | "theme" | "borderType"
> &
  ButtonPartialProps;

interface ModalFooterActions {
  actionPositive?: {
    label: React.ReactNode;
    handleClick: () => void;
    isCritical?: boolean;
    disabled?: boolean;
    borderType?: "filled" | "outlined";
    iconInfo?: ButtonIconInfo;
  };

  actionNegative?: {
    label: React.ReactNode;
    handleClick: () => void;
    isCritical?: boolean;
    isSecondary?: boolean;
    borderType?: "filled" | "outlined";
    iconInfo?: ButtonIconInfo;
    disabled?: boolean;
  };

  actionOption?: ButtonInModalCommonProps;
}

type ModalContentProps = {
  uiType: "content" | "contentWithCustomBody";
  body: React.ReactNode;
  submitButton?: React.ReactNode;
  className?: string;
} & ModalFooterActions;

type ModalTitleOnlyProps = {
  uiType: "titleOnly" | "webNegativeTitleOnly";
  isWebNegativeTitle?: boolean;
  isWebNegativeActionPositive?: boolean;
} & ModalFooterActions;

type ModalWebNegativeProps = {
  uiType: "webNegative";
  body?: React.ReactNode;
} & ModalFooterActions;

interface ModalFormInputProps {
  uiType: "formInput";
  desc?: string | React.ReactNode;
  body: React.ReactNode;
  hasBorder: boolean;
  submitButton?: React.ReactNode;
  disableMaxHeight?: boolean;
}

/**
 * formInput과 동일하나, tab이 추가된 형태
 */
interface ModalFormInputWithTabProps {
  desc?: React.ReactNode;
  disableMaxHeight?: boolean;

  uiType: "formInputWithTab";
  tabTitleList: string[];
  buttonList?: React.ReactNode[];
  /**
   * TabLine 컴포넌트가 index로 동작하기 때문에 key는 index로 지정
   * 각 탭의 Submit Button 은 .tab-submit-button-container로 감싸주어야 함
   */
  caseBy: Record<string, JSX.Element>;
  /**
   * 버튼이 있는 경우 레이아웃이 달라지기 때문에 해당 탭의 key를 지정해주어야 함
   */
  tabKeyListWithButton?: number[];
  /** 탭 기본선택이 0 외의 다른 탭 선택이 필요한 경우 */
  defaultSelectedTabIndex?: number;
  /** 특정 탭에서만 닫기 확인 모달이 필요한 경우, 해당 탭의 index 배열  */
  activeTabIndexOnCloseModal?: number[];
  /** 탭을 변경할 때 마다 실행되어야 하는 함수 */
  onTabChange?: () => void;
}

/**
 * 버튼은 .pda-form-button-container로 감싸주어야 함
 *   - 버튼이 body의 form과 강결합되어 있어서 body에 버튼을 포함시키고 클래스로 공통 스타일을 적용함
 */
interface ModalPDAFormProps {
  uiType: "PDAForm";
  title: React.ReactNode;
  body: React.ReactNode;
}

// V2 Modal types
type ModalPropsV2 = ModalCommonProps &
  (
    | ModalTitleAndBodyProps
    | ModalTitleAndDescAndBodyProps
    | ModalOnlyDescriptionProps
    | ModalIconAndBodyProps
    | ModalInputProps
  );

type ModalComponentV2 = FC<ModalPropsV2>;

type ModalUiTypeV2 =
  | "titleAndBody"
  | "titleAndDescAndBody"
  | "onlyDescription"
  | "iconAndBody"
  | "formInput";

type ModalTitleAndBodyProps = {
  uiType: "titleAndBody";
  title: React.ReactNode;
  body: React.ReactNode;
} & ModalFooterActions;

type ModalTitleAndDescAndBodyProps = {
  uiType: "titleAndDescAndBody";
  title: React.ReactNode;
  body: React.ReactNode;
  desc: React.ReactNode;
} & ModalFooterActions;

type ModalOnlyDescriptionProps = {
  uiType: "onlyDescription";
  desc: React.ReactNode;
} & ModalFooterActions;

type ModalIconAndBodyProps = {
  uiType: "iconAndBody";
  Icon: SvgIconComponent;
  body: React.ReactNode;
} & ModalFooterActions;

type ModalInputProps = {
  uiType: "formInput";
  title?: React.ReactNode;
  desc?: React.ReactNode;
  body: React.ReactNode;
  width?: string;
  height?: string;
  maxWidth?: string;
} & ModalFooterActions;

function useModal({
  active,
  onClose,
  needConfirmBeforeCloseModal,
}: Pick<
  ModalCommonProps,
  "active" | "onClose" | "needConfirmBeforeCloseModal"
>) {
  const [
    isVisibleConfirmBeforeCloseModal,
    setIsVisibleConfirmBeforeCloseModal,
  ] = useState(false);

  const { lockBodyScroll, unlockBodyScroll } = useBodyScrollLock({
    enablesLocking: active,
  });

  const handleModalClose = (e?: React.MouseEvent) => {
    if (!onClose) return;

    if (needConfirmBeforeCloseModal)
      return setIsVisibleConfirmBeforeCloseModal(true);

    onClose();
    e && e.stopPropagation();
  };

  return {
    handleModalClose,
    isVisibleConfirmBeforeCloseModal,
    setIsVisibleConfirmBeforeCloseModal,
  };
}

export default useModal;

export type {
  ModalPropsV1,
  ModalFooterActions,
  ModalTitleOnlyProps,
  ModalContentProps,
  ModalWebNegativeProps,
  ModalFormInputProps,
  ModalCommonProps,
  ModalCommonPropsV1,
  ButtonInModalCommonProps,
  ModalComponent,
  ModalUiTypeV2,
  ModalPropsV2,
  ModalComponentV2,
};
