import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import { red } from "@mui/material/colors";

export type SelectWithReactHookFormProps<T extends FieldValues> = {
  label?: string;
  name: Path<T>;
  control?: Control<T>;
  options?: string[];
  labelOptionList?: { label: string; value: string }[];
  defaultValue?: PathValue<T, (string | undefined) & Path<T>>;
  required?: boolean;
  disabled?: boolean;
  handleEffectOnChange?: () => void;
};

export default function SelectWithReactHookForm<T extends FieldValues>({
  label,
  name,
  options,
  labelOptionList,
  control,
  defaultValue,
  disabled,
  required,
  handleEffectOnChange,
  ...props
}: SelectProps & SelectWithReactHookFormProps<T>) {
  const isLabelOptionList = Boolean(labelOptionList);

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{ required }}
        render={({ field, fieldState: { error } }) => (
          <FormControl
            variant={props.variant ? props.variant : "standard"}
            fullWidth={props.fullWidth}
          >
            {label && <InputLabel>{label}</InputLabel>}

            <Select
              {...props}
              {...field}
              disabled={disabled}
              label={field.value}
              value={props.value ? props.value : field.value || ""}
              onChange={(event) => {
                field.onChange(event);
                handleEffectOnChange && handleEffectOnChange();
              }}
              error={error !== undefined}
            >
              {isLabelOptionList
                ? labelOptionList?.map((option, index) => (
                    <MenuItem key={option.value + index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                : options?.map((option, index) => (
                    <MenuItem key={option + index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
            </Select>

            {error && error.type === "required" && (
              <FormHelperText sx={{ color: red[500] }}>
                "필수 입력 사항입니다."
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
    </>
  );
}
