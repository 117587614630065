import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Typography } from "@mui/material";

import { ShipmentScheduleLog } from "@sellernote/_shared/src/types/forwarding/trello";
import { formatDate } from "@sellernote/_shared/src/utils/common/date";
import { replaceEmptyToDash } from "@sellernote/_shared/src/utils/common/string";

export default function ScheduleLogItem({
  log,
  isStrikeThrough,
}: {
  log: ShipmentScheduleLog;
  isStrikeThrough?: boolean;
}) {
  const commonStyle = {
    display: "flex",
    justifyContent: "center",
    textDecoration: isStrikeThrough ? "line-through" : "none",
  };

  const { departurePort, ETD, arrivalPort, ETA, shipName, voyageNo } = log;

  return (
    <Box sx={{ ...commonStyle, flexDirection: "column", mt: 1 }}>
      <Typography sx={{ ...commonStyle, alignItems: "center" }}>
        {departurePort} {formatDate({ date: ETD, type: "YY_MM_DD" })}
        &nbsp;
        <ArrowForwardIcon sx={{ fontSize: "16px" }} />
        &nbsp;
        {arrivalPort} {formatDate({ date: ETA, type: "YY_MM_DD" })}
      </Typography>

      <Typography>
        ({replaceEmptyToDash(shipName)} / {replaceEmptyToDash(voyageNo)})
      </Typography>
    </Box>
  );
}
