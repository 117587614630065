import { Dispatch, forwardRef, SetStateAction } from "react";

import { formatDate } from "@sellernote/_shared/src/utils/common/date";

import InputText, { InputTextBorderType } from "../input/InputText";
import { Time } from ".";

// value, onClick 은 라이브러리에서 지원하는 기본 props 입니다.
function CustomInput(
  {
    value,
    dateValue,
    inputLabel,
    inputBorderType,
    isIncludedTime,
    setDateValue,
    date,
    setDate,
    time,
    setTime,
    onClick,
    ...rest
  }: {
    value?: string;
    dateValue: string;
    inputLabel?: string;
    inputBorderType?: InputTextBorderType;
    isIncludedTime?: boolean;
    setDateValue: (val: string) => void;
    date: Date | null | undefined;
    setDate: Dispatch<SetStateAction<Date | null | undefined>>;
    time: Time;
    setTime: Dispatch<SetStateAction<Time>>;
    onClick?: () => void;
  },
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <div ref={ref}>
      <InputText
        label={inputLabel}
        name="date"
        borderType={inputBorderType ?? "filled"}
        valueType="string"
        value={
          isIncludedTime
            ? `${formatDate({
                date: dateValue,
                type: "YY_MM_DD_HH_mm",
              })}`
            : value
        }
        className="example-custom-input"
        handleFocusIn={onClick}
        icon="calendar"
        visiblePostFix
        {...rest}
      />
    </div>
  );
}

export default forwardRef(CustomInput);
