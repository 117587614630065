import { Box, CircularProgress, Typography } from "@mui/material";

export default function Loading() {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      height={"100%"}
    >
      <CircularProgress />

      <Typography component={"span"} fontWeight={"bold"} mt={2}>
        적하목록 정보를 불러오고 있습니다.
      </Typography>
    </Box>
  );
}
