import { useMemo } from "react";
import { Button } from "@mui/material";

import { GET_CARGO_MANIFEST_RES as CargoManifestInfoProps } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/trello";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import { ContainerTypeForCustoms } from "@sellernote/_shared/src/types/forwarding/bid";

import useSnackbar from "../../../../../../../../../../hooks/useSnackbar";
import validateFormAndShowSnackbar from "../utils";

export default function RequestButton({
  form,
  shipmentId,
  isEditMode,
  canSubmit,
  setCanSubmit,
  isImport,
  validateContainerTypeForCustoms,
}: {
  form: CargoManifestInfoProps;
  shipmentId: number;
  isEditMode: boolean;
  canSubmit: boolean;
  setCanSubmit: (value: boolean) => void;
  isImport: boolean;
  validateContainerTypeForCustoms: (
    list: (ContainerTypeForCustoms | null)[]
  ) => boolean;
}) {
  const { handleSnackbarOpen: showSnackbar } = useSnackbar();

  const containerTypeForCustomsList = useMemo(
    () => form.containers?.map((v) => v.containerTypeForCustoms) ?? [],
    [form.containers]
  );

  const { mutate: requestCargoManifest } =
    TRELLO_BID_QUERY.useRequestCargoManifest({
      shipmentId,
      onSnackbarOpen: showSnackbar,
    });

  const handleCargoManifestInfoRequest = () => {
    /** 컨테이너 정보 수정과 달리, 로지스뷰 제출은 컨테이너 정보가 최소 1개 있어야함 */
    if (!form.containers?.length) {
      showSnackbar("컨테이너 정보를 입력해주세요.", "error");
      return;
    }

    if (!validateContainerTypeForCustoms(containerTypeForCustomsList)) {
      showSnackbar("입력한 세관 Code를 확인해주세요.", "error");
      return;
    }

    if (!validateFormAndShowSnackbar({ form, showSnackbar, isImport })) {
      return;
    }

    requestCargoManifest(
      {},
      {
        onSuccess: () => {
          showSnackbar("적하목록을 성공적으로 제출했습니다.");
          setCanSubmit(false);
        },
      }
    );
  };

  return (
    <Button
      sx={{ ml: 1 }}
      variant="contained"
      size="small"
      onClick={handleCargoManifestInfoRequest}
      disabled={isEditMode || !canSubmit}
    >
      제출
    </Button>
  );
}
