import { GET_CARGO_MANIFEST_RES as CargoManifestInfoProps } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/trello";
import { FreightType } from "@sellernote/_shared/src/types/common/common";
import { TrelloBidManagement } from "@sellernote/_shared/src/types/forwarding/trello";

import useHandleManifestInfo from "./hooks";

import CargoManifestInfoForm from "./CargoManifestInfoForm";

export default function CargoManifestInfo({
  cargoManifestInfo,
  shipmentId,
  isImport,
  BRN,
  management,
  freightType,
}: {
  cargoManifestInfo: CargoManifestInfoProps;
  shipmentId: number;
  isImport: boolean;
  BRN: string;
  management: TrelloBidManagement;
  freightType: FreightType;
}) {
  const {
    form,
    handleContainerFormChange,
    handleContainerAdd,
    handleContainerDelete,
    handleFormChange,
    handleEstimatedTimeChange,
  } = useHandleManifestInfo({ cargoManifestInfo });

  return (
    <CargoManifestInfoForm
      form={form}
      cargoManifestInfo={cargoManifestInfo}
      onFormChange={handleFormChange}
      onContainerFormChange={handleContainerFormChange}
      onContainerAdd={handleContainerAdd}
      onContainerDelete={handleContainerDelete}
      onEstimatedTimeChange={handleEstimatedTimeChange}
      shipmentId={shipmentId}
      isImport={isImport}
      BRN={BRN}
      management={management}
      freightType={freightType}
    />
  );
}
