import { Add } from "@mui/icons-material";
import { Box, Button } from "@mui/material";

import { ContainerInfo } from "../types";

type ClientAddedContainerInfo = Omit<ContainerInfo, "id" | "tempId">;

/** id는 RHF에서 자동으로 생성 */
const INITIAL_CONTAINER_INFO: ClientAddedContainerInfo = {
  containerNo: "",
  sealNo: null,
  containerType: "NONE",
  containerTypeForCustoms: null,
  grossWeight: 0,
  tareWeight: 0,
  isClientAdded: true,
};

export default function Header({
  addContainer,
}: {
  addContainer: (value: ClientAddedContainerInfo) => void;
}) {
  return (
    <Box display={"flex"}>
      <Button
        className="sads"
        size="small"
        variant="outlined"
        sx={{ ml: "auto" }}
        onClick={() => addContainer(INITIAL_CONTAINER_INFO)}
      >
        <Add />
      </Button>
    </Box>
  );
}
