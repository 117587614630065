import { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";

import { PagingResponseForBoard } from "@sellernote/_shared/src/types/common/common";
import { ForwardingAdminUserListItem } from "@sellernote/_shared/src/types/forwarding/adminUser";
import { formatDate } from "@sellernote/_shared/src/utils/common/date";

import LegacyTable, {
  LegacyTableBodyRow,
  LegacyTableHeadCell,
} from "../../../components/LegacyTable";

function AdminUserTable({
  adminUserList,
}: {
  adminUserList:
    | PagingResponseForBoard<ForwardingAdminUserListItem>
    | undefined;
}) {
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(20);

  type CellId = keyof ForwardingAdminUserListItem;

  const handleMoveToRegisterAdminUser = useCallback(() => {
    history.push("/admin-user/register");
  }, [history]);

  const headCells: LegacyTableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "id",
        disablePadding: false,
        label: "번호",
      },
      {
        id: "authority",
        disablePadding: false,
        label: "권한",
      },
      {
        id: "accountId",
        disablePadding: false,
        label: "아이디",
      },
      {
        id: "name",
        disablePadding: false,
        label: "이름",
      },
      {
        id: "isForwardingManager",
        disablePadding: false,
        label: "운영담당여부",
      },
      {
        id: "createdAt",
        disablePadding: false,
        label: "생성일",
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!adminUserList?.list) return [];
    return adminUserList?.list
      .slice(currentPage * perPage, currentPage * perPage + perPage)
      .map((v) => {
        const row: LegacyTableBodyRow<CellId> = {
          id: v.id,
          authority: v.authority,
          accountId: v.accountId,
          name: v.name,
          isForwardingManager: v.isForwardingManager ? "예" : "아니오",
          createdAt: formatDate({
            date: v.createdAt,
            type: "YY_MM_DD",
          }),
          handleRowClick() {
            history.push(`/admin-user/${v.id}`);
          },
        };

        return row;
      });
  }, [adminUserList?.list, currentPage, history, perPage]);

  return (
    <>
      <Button
        variant="contained"
        color="success"
        onClick={handleMoveToRegisterAdminUser}
      >
        추가
      </Button>

      <LegacyTable
        title="어드민 관리자 리스트"
        headCells={headCells}
        rows={rows}
        pagination={{
          rowsPerPageOptions: [10, 20, 50, 100, 500, 1000, 10000],
          totalCount: adminUserList?.total || 0,
          perPage,
          setPerPage,
          currentPage,
          setCurrentPage,
        }}
      />
    </>
  );
}

export default AdminUserTable;
