import { Typography } from "@mui/material";
import { blue, grey } from "@mui/material/colors";

import {
  ExportTrelloList,
  PartnerTrelloList,
  ScheduleUpdatedItems,
  TrelloBidList,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { formatDate } from "@sellernote/_shared/src/utils/common/date";

function TrelloCardCommonBody({
  trelloListData,
}: {
  trelloListData: TrelloBidList | ExportTrelloList | PartnerTrelloList;
}) {
  const highlightsText = (target: ScheduleUpdatedItems) => {
    if (!trelloListData.management.scheduleUpdatedItems?.length) return {};

    if (trelloListData.management.scheduleUpdatedItems.includes(target)) {
      return { color: blue[500] };
    }

    return {};
  };

  const COMMON_STYLE = {
    fontWeight: "bold",
    fontSize: 14,
    letterSpacing: -0.5,
  } as const;

  const showsSchedule = Boolean(
    trelloListData.management.fullATD || trelloListData.management.fullETD
  );

  return (
    <>
      <Typography sx={{ fontSize: 12 }} variant="body2">
        {trelloListData.management.memo}
      </Typography>

      <Typography
        variant="body2"
        component={"div"}
        sx={{ fontWeight: "bold", color: grey[700] }}
      >
        {trelloListData.id}{" "}
        {trelloListData?.poNumber &&
          trelloListData.poNumber.length > 0 &&
          `${
            trelloListData.poNumber.length > 1
              ? `/ ${trelloListData.poNumber[0]} 외 ${trelloListData.poNumber.length}`
              : `/ ${trelloListData.poNumber[0]}`
          }`}
      </Typography>

      {showsSchedule && (
        <>
          <span style={{ ...COMMON_STYLE, ...highlightsText("ETD") }}>
            {formatDate({
              date: trelloListData.management.fullATD,
              type: "YY_MM_DD",
            }) ||
              formatDate({
                date: trelloListData.management.fullETD,
                type: "YY_MM_DD",
              })}
          </span>

          {`${" "}~${" "}`}

          <span style={{ ...COMMON_STYLE, ...highlightsText("ETA") }}>
            {formatDate({
              date: trelloListData.management.fullATA,
              type: "YY_MM_DD",
            }) ||
              formatDate({
                date: trelloListData.management.fullETA,
                type: "YY_MM_DD",
              })}
          </span>
        </>
      )}
    </>
  );
}

export default TrelloCardCommonBody;
