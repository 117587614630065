import { useForm } from "react-hook-form";
import { Button, Stack } from "@mui/material";

import { UPDATE_PO_NUMBER_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminOrder";
import TextFieldWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/TextFieldWithReactHookForm";

export default function Form({
  poNumber,
  updatePONumber,
  isLoading,
}: {
  poNumber: string;
  updatePONumber: (data: UPDATE_PO_NUMBER_REQ) => void;
  isLoading: boolean;
}) {
  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<UPDATE_PO_NUMBER_REQ>({
    defaultValues: {
      poNumber,
    },
  });

  return (
    <Stack
      component="form"
      onSubmit={handleSubmit(updatePONumber)}
      width="300px"
      spacing={5}
    >
      <TextFieldWithReactHookForm
        name={"poNumber"}
        label="PO번호"
        control={control}
        needsTrimOnChange={true}
      />

      <Button
        type="submit"
        variant="contained"
        disabled={!isDirty || isLoading}
      >
        수정
      </Button>
    </Stack>
  );
}
