import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { DoubleArrow, LogoutOutlined } from "@mui/icons-material";
import { Divider, Tooltip } from "@mui/material";

import { APP_BUILD_INFO, APP_NAME } from "@sellernote/_shared/src/constants";
import { formatDate } from "@sellernote/_shared/src/utils/common/date";

import { AdminMenuTree } from "../../types/adminMenu";

import MainMenu from "./MainMenu";
import Styled from "./index.styles";

export const MENU_ICON_COLOR_STYLE = { opacity: 0.5, color: "#eeeeee" };

function LayoutForAdmin({
  type,
  menuTree,
  logo,
  children,
  headerItems,
  onLogout,
  title,
  breadcrumbs,
}: {
  type?: "boful";
  menuTree: AdminMenuTree;
  logo: React.ReactNode;
  children: React.ReactNode;
  headerItems: React.ReactNode[];
  onLogout: () => void;
  title?: string;
  breadcrumbs?: string[];
}) {
  const history = useHistory();

  const [isHidden, setIsHidden] = useState(false);

  // TODO: 링크들 전부 a태그로 변경하기 (시간없어서 일단 onClick으로 구현)
  return (
    <>
      <Styled.container>
        {isHidden ? (
          <Styled.menuWhenHidden
            onClick={(e) => {
              e.stopPropagation();
              setIsHidden(false);
            }}
          >
            <div className="show">
              <DoubleArrow sx={{ color: "#9e9e9e", fontSize: "24px" }} />
            </div>
          </Styled.menuWhenHidden>
        ) : (
          <Styled.menu>
            <Styled.menuLogo
              onClick={() => {
                if (APP_NAME === "partner-admin") {
                  return;
                }
                history.push("/");
              }}
            >
              {logo}
            </Styled.menuLogo>

            {APP_BUILD_INFO && (
              <div className="app-build-info">
                <div className="built-at">
                  {`빌드: ${formatDate({
                    date: APP_BUILD_INFO.builtAt,
                    type: "YY_MM_DD_HH_mm_ss",
                  })}`}
                </div>

                <div className="hidden">
                  {`배포된 브랜치: ${APP_BUILD_INFO.gitBranch}`}
                </div>

                <div className="hidden">
                  {`배포된 커밋 SHA: ${APP_BUILD_INFO.gitCommitSha}`}
                </div>
              </div>
            )}

            <Styled.menuTree>
              {menuTree.map((menuSection, i) => {
                return (
                  <Styled.menuSection key={i}>
                    <div className="label">{menuSection.label}</div>

                    <div className="menu-list">
                      {menuSection.mainMenuList.map((mainMenu, mi) => {
                        return <MainMenu mainMenu={mainMenu} key={mi} />;
                      })}
                    </div>
                  </Styled.menuSection>
                );
              })}
            </Styled.menuTree>

            <div
              onClick={(e) => {
                e.stopPropagation();
                setIsHidden(true);
              }}
              className="hide"
            >
              <DoubleArrow sx={{ color: "#9e9e9e", fontSize: "24px" }} />
            </div>
          </Styled.menu>
        )}

        <Styled.content isHidden={isHidden}>
          <Styled.contentHeader isHidden={isHidden}>
            {headerItems.map((headerItem) => headerItem)}

            <Tooltip title="로그아웃">
              <div className="logout" onClick={onLogout}>
                <LogoutOutlined sx={{ color: "#9e9e9e", fontSize: "24px" }} />
              </div>
            </Tooltip>
          </Styled.contentHeader>

          <Styled.contentBody type={type}>
            {(title || breadcrumbs) && (
              <Styled.contentBodyHeader>
                {title && <div className="title">{title}</div>}

                {breadcrumbs && (
                  <div className="breadcrumbs">
                    {breadcrumbs.map((breadcrumb, i) => {
                      return (
                        <span className="breadcrumb" key={i}>
                          {breadcrumb}
                        </span>
                      );
                    })}

                    {title && (
                      <span className="breadcrumb" key="title">
                        {title}
                      </span>
                    )}
                  </div>
                )}

                <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
              </Styled.contentBodyHeader>
            )}
            {children}
          </Styled.contentBody>
        </Styled.content>
      </Styled.container>
    </>
  );
}

export default LayoutForAdmin;
