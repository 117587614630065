import { Currency, FreightType } from "../../../types/common/common";
import {
  AdminBidDetail,
  AdminShipmentItemGroup,
  ConsolidationItemList,
  QuotationForUserStatus,
  ShipmentItem,
} from "../../../types/forwarding/adminBid";
import {
  BidContainerInfo,
  BidForwarderDetail,
  BidManagementSchedule,
  BidProductInfo,
  BidProjectStatus,
  BidServiceType,
  BidStatus,
} from "../../../types/forwarding/bid";
import {
  RealtimeQuotationAIR,
  RealtimeQuotationFCL,
  RealtimeQuotationLCL,
} from "../../../types/forwarding/quotation";

import { ShipdaCurrentLanguage } from "../../../i18n/i18nForShipda";
import { toFixedFloat } from "../../common/number";
import { packageOptionList } from "../../common/options";
import { omitWithEllipsis } from "../../common/string";

/**
 * 아이템 리스트에서 첫 번째 아이템의 이름을 가져오고, 필요시 최대 길이를 넘으면 생략 부호를 붙여 반환합니다.
 *
 * @param items - 이름을 포함한 아이템 배열
 * @param maxLength - 최대 길이 (선택 사항)
 * @returns 아이템 리스트에서 첫 번째 아이템의 이름과 추가 아이템 수를 포함한 문자열
 */
export function getTitleAmongItemListName(
  items?: { name: string }[],
  maxLength?: number
) {
  let result = "";

  if (items && items.length > 0) {
    result += items[0].name;

    if (maxLength) {
      result = omitWithEllipsis({
        src: result,
        maxLength,
        ellipsis: "...",
      });
    }

    if (items.length > 1) {
      result +=
        ShipdaCurrentLanguage.currentLanguage === "ko"
          ? ` 외 ${items.length - 1}`
          : ` and ${items.length - 1} more`;
    }
  }

  return result;
}

/**
 * 견적의 제목을 생성합니다.
 *
 * @param serviceType - 서비스 타입
 * @param freightType - 화물 타입
 * @param containersInfo - 컨테이너 정보 배열 (선택 사항)
 * @param productsInfo - 제품 정보 배열 (선택 사항)
 * @param consolidationItems - 통합 아이템 배열 (선택 사항)
 * @param maxLength - 최대 길이 (선택 사항)
 * @returns 견적의 제목 문자열
 */
export function getBidTitle({
  serviceType,
  freightType,
  containersInfo,
  productsInfo,
  consolidationItems,
  maxLength,
}: {
  serviceType: BidServiceType;
  freightType: string;
  containersInfo?: any[];
  productsInfo?: any[];
  consolidationItems?: any[];
  maxLength?: number;
}) {
  let result = "";

  if (serviceType === "general" || serviceType === "oceanTicket") {
    if (freightType === "FCL" && containersInfo) {
      result = getTitleAmongItemListName(containersInfo, maxLength);
    }

    if (freightType !== "FCL" && productsInfo) {
      result = getTitleAmongItemListName(productsInfo, maxLength);
    }
  }

  if (serviceType === "consolidation") {
    result = getTitleAmongItemListName(consolidationItems, maxLength);
  }

  return result;
}

/**
 * 견적 아이템의 제목을 생성합니다.
 *
 * @param freightType - 화물 타입 (선택 사항)
 * @param containersInfo - 컨테이너 정보 배열 (선택 사항)
 * @param productsInfo - 제품 정보 배열 (선택 사항)
 * @returns 견적 아이템의 제목 문자열
 */
export function getBidItemTitle(
  freightType?: FreightType,
  containersInfo?: BidContainerInfo[],
  productsInfo?: BidProductInfo[]
) {
  let result = "";

  if (!freightType) return result;

  if (freightType === "FCL" && containersInfo) {
    result = getTitleAmongItemListName(containersInfo);
  }

  if (freightType !== "FCL" && productsInfo) {
    result = getTitleAmongItemListName(productsInfo);
  }

  return result;
}

/**
 * FreightType에 따른 물동량 단위를 리턴합니다.
 *
 * @param bidInfo - 견적 정보
 * @returns 물동량 단위 문자열
 */
export function getSupplyUnit(bidInfo: BidForwarderDetail) {
  if (bidInfo.freightType === "FCL") {
    return "FEU";
  } else if (bidInfo.freightType === "LCL") {
    return "R.TON";
  }
  return "C.W";
}

/**
 * 화물 타입에 따른 공급 유형을 반환합니다.
 *
 * @param freightType - 화물 타입
 * @returns 공급 유형 문자열
 */
export function getSupplyType(freightType: string) {
  let result = "";
  switch (freightType) {
    case "AIR":
      result = "C/W";
      break;
    case "LCL":
      result = "R.TON";
      break;
    case "FCL":
      result = "FEU";
      break;
    default:
      break;
  }

  return result;
}

/**
 * 화물 타입에 따른 화물 타입에 공급 값을 합친 텍스트를 반환합니다.
 *
 * @param freightType - 화물 타입
 * @param supply - 공급 값
 * @returns 화물 타입에 공급 값을 합친 문자열
 */
export function getSupplyValueInUnit(freightType: string, supply: number) {
  switch (freightType) {
    case "LCL":
      return `${supply}R.TON`;
    case "FCL":
      return `${supply}FEU`;
    case "AIR":
      return `${supply}C.W`;
    default:
      return `${supply}R.TON`;
  }
}

/**
 * 싱가폴 주롱섬의 범위 좌표
 */
export const coordsForSGJurongIsland = [
  { lat: 1.276501, lng: 103.658238 },
  { lat: 1.272957, lng: 103.654007 },
  { lat: 1.267012, lng: 103.654121 },
  { lat: 1.255009, lng: 103.65881 },
  { lat: 1.248149, lng: 103.661554 },
  { lat: 1.234774, lng: 103.668529 },
  { lat: 1.228257, lng: 103.669559 },
  { lat: 1.222884, lng: 103.671845 },
  { lat: 1.222541, lng: 103.673561 },
  { lat: 1.231687, lng: 103.683052 },
  { lat: 1.24049, lng: 103.690599 },
  { lat: 1.257752, lng: 103.686253 },
  { lat: 1.263125, lng: 103.680536 },
  { lat: 1.267927, lng: 103.684996 },
  { lat: 1.260382, lng: 103.693343 },
  { lat: 1.250893, lng: 103.696087 },
  { lat: 1.25478, lng: 103.707065 },
  { lat: 1.259124, lng: 103.714383 },
  { lat: 1.260153, lng: 103.714383 },
  { lat: 1.265755, lng: 103.705235 },
  { lat: 1.268613, lng: 103.706722 },
  { lat: 1.262897, lng: 103.717013 },
  { lat: 1.260953, lng: 103.719758 },
  { lat: 1.259924, lng: 103.723646 },
  { lat: 1.261754, lng: 103.728448 },
  { lat: 1.264497, lng: 103.731879 },
  { lat: 1.266212, lng: 103.733708 },
  { lat: 1.266555, lng: 103.733937 },
  { lat: 1.268956, lng: 103.732908 },
  { lat: 1.270442, lng: 103.737367 },
  { lat: 1.2725, lng: 103.740226 },
  { lat: 1.273872, lng: 103.740455 },
  { lat: 1.278559, lng: 103.735652 },
  { lat: 1.289762, lng: 103.724903 },
  { lat: 1.292048, lng: 103.717814 },
  { lat: 1.292963, lng: 103.708551 },
  { lat: 1.291591, lng: 103.691056 },
];

/**
 * 싱가폴 CBD 범위 좌표
 */
export const coordsForSGCBD = [
  { lat: 1.284177, lng: 103.880566 },
  { lat: 1.284527, lng: 103.879981 },
  { lat: 1.284254, lng: 103.879319 },
  { lat: 1.283469, lng: 103.878377 },
  { lat: 1.282348, lng: 103.877183 },
  { lat: 1.281751, lng: 103.876777 },
  { lat: 1.281202, lng: 103.876658 },
  { lat: 1.280773, lng: 103.876372 },
  { lat: 1.280701, lng: 103.876061 },
  { lat: 1.281513, lng: 103.874557 },
  { lat: 1.281465, lng: 103.874152 },
  { lat: 1.279932, lng: 103.871696 },
  { lat: 1.278696, lng: 103.872196 },
  { lat: 1.278042, lng: 103.872183 },
  { lat: 1.277239, lng: 103.871982 },
  { lat: 1.276516, lng: 103.8715 },
  { lat: 1.272358, lng: 103.865835 },
  { lat: 1.270189, lng: 103.862681 },
  { lat: 1.269386, lng: 103.861315 },
  { lat: 1.269085, lng: 103.860069 },
  { lat: 1.269225, lng: 103.858643 },
  { lat: 1.27276, lng: 103.85109 },
  { lat: 1.27278, lng: 103.849402 },
  { lat: 1.272499, lng: 103.848679 },
  { lat: 1.272358, lng: 103.846851 },
  { lat: 1.272507, lng: 103.845534 },
  { lat: 1.272571, lng: 103.842476 },
  { lat: 1.272875, lng: 103.842044 },
  { lat: 1.274652, lng: 103.841098 },
  { lat: 1.277122, lng: 103.840546 },
  { lat: 1.277542, lng: 103.840516 },
  { lat: 1.279155, lng: 103.839832 },
  { lat: 1.280348, lng: 103.839429 },
  { lat: 1.281315, lng: 103.838792 },
  { lat: 1.282884, lng: 103.836274 },
  { lat: 1.283928, lng: 103.835381 },
  { lat: 1.285581, lng: 103.834818 },
  { lat: 1.286234, lng: 103.834833 },
  { lat: 1.287306, lng: 103.835036 },
  { lat: 1.288765, lng: 103.835069 },
  { lat: 1.289322, lng: 103.83501 },
  { lat: 1.292358, lng: 103.833352 },
  { lat: 1.292157, lng: 103.831776 },
  { lat: 1.292719, lng: 103.831403 },
  { lat: 1.294838, lng: 103.830595 },
  { lat: 1.295674, lng: 103.830261 },
  { lat: 1.29673, lng: 103.830098 },
  { lat: 1.297863, lng: 103.830062 },
  { lat: 1.299229, lng: 103.830003 },
  { lat: 1.299046, lng: 103.829278 },
  { lat: 1.298987, lng: 103.828878 },
  { lat: 1.299004, lng: 103.828087 },
  { lat: 1.299012, lng: 103.827687 },
  { lat: 1.299262, lng: 103.826462 },
  { lat: 1.299446, lng: 103.825728 },
  { lat: 1.30017, lng: 103.824337 },
  { lat: 1.300604, lng: 103.823628 },
  { lat: 1.301029, lng: 103.823228 },
  { lat: 1.301162, lng: 103.823203 },
  { lat: 1.30177, lng: 103.82327 },
  { lat: 1.302233, lng: 103.823366 },
  { lat: 1.302865, lng: 103.823434 },
  { lat: 1.303791, lng: 103.823424 },
  { lat: 1.304015, lng: 103.82341 },
  { lat: 1.304464, lng: 103.823234 },
  { lat: 1.304797, lng: 103.823002 },
  { lat: 1.30498, lng: 103.822778 },
  { lat: 1.304999, lng: 103.822778 },
  { lat: 1.305233, lng: 103.8233 },
  { lat: 1.305322, lng: 103.823469 },
  { lat: 1.305332, lng: 103.824142 },
  { lat: 1.305361, lng: 103.824571 },
  { lat: 1.305778, lng: 103.82541 },
  { lat: 1.306915, lng: 103.82706 },
  { lat: 1.307395, lng: 103.827759 },
  { lat: 1.307797, lng: 103.827808 },
  { lat: 1.308182, lng: 103.82763 },
  { lat: 1.30846, lng: 103.827394 },
  { lat: 1.308729, lng: 103.827074 },
  { lat: 1.30894, lng: 103.826754 },
  { lat: 1.308985, lng: 103.826751 },
  { lat: 1.310572, lng: 103.827534 },
  { lat: 1.311028, lng: 103.827641 },
  { lat: 1.311875, lng: 103.827727 },
  { lat: 1.312117, lng: 103.827855 },
  { lat: 1.312338, lng: 103.828204 },
  { lat: 1.312416, lng: 103.828738 },
  { lat: 1.312587, lng: 103.829293 },
  { lat: 1.315236, lng: 103.832584 },
  { lat: 1.316873, lng: 103.83462 },
  { lat: 1.317322, lng: 103.83539 },
  { lat: 1.314901, lng: 103.837462 },
  { lat: 1.313904, lng: 103.838317 },
  { lat: 1.313071, lng: 103.839045 },
  { lat: 1.312782, lng: 103.839407 },
  { lat: 1.311695, lng: 103.841698 },
  { lat: 1.310736, lng: 103.843881 },
  { lat: 1.310403, lng: 103.844867 },
  { lat: 1.310263, lng: 103.845211 },
  { lat: 1.309946, lng: 103.845745 },
  { lat: 1.308761, lng: 103.84663 },
  { lat: 1.306721, lng: 103.848815 },
  { lat: 1.308234, lng: 103.849917 },
  { lat: 1.311608, lng: 103.852541 },
  { lat: 1.31236, lng: 103.852619 },
  { lat: 1.312002, lng: 103.853057 },
  { lat: 1.312324, lng: 103.853186 },
  { lat: 1.312833, lng: 103.853559 },
  { lat: 1.313041, lng: 103.853738 },
  { lat: 1.313629, lng: 103.854319 },
  { lat: 1.312884, lng: 103.854678 },
  { lat: 1.311825, lng: 103.855376 },
  { lat: 1.310635, lng: 103.854508 },
  { lat: 1.309481, lng: 103.855712 },
  { lat: 1.308034, lng: 103.85739 },
  { lat: 1.306854, lng: 103.858686 },
  { lat: 1.306316, lng: 103.859174 },
  { lat: 1.305568, lng: 103.859904 },
  { lat: 1.305512, lng: 103.85996 },
  { lat: 1.304358, lng: 103.860614 },
  { lat: 1.302828, lng: 103.861532 },
  { lat: 1.301524, lng: 103.862306 },
  { lat: 1.300845, lng: 103.860965 },
  { lat: 1.300582, lng: 103.860486 },
  { lat: 1.299764, lng: 103.859461 },
  { lat: 1.298547, lng: 103.860423 },
  { lat: 1.29788, lng: 103.860875 },
  { lat: 1.297156, lng: 103.860995 },
  { lat: 1.295708, lng: 103.861183 },
  { lat: 1.295372, lng: 103.861384 },
  { lat: 1.294836, lng: 103.86172 },
  { lat: 1.294393, lng: 103.862216 },
  { lat: 1.294125, lng: 103.862873 },
  { lat: 1.294053, lng: 103.863899 },
  { lat: 1.294207, lng: 103.865219 },
  { lat: 1.294704, lng: 103.867888 },
  { lat: 1.295353, lng: 103.871386 },
  { lat: 1.295416, lng: 103.873444 },
  { lat: 1.295527, lng: 103.875755 },
  { lat: 1.295544, lng: 103.877308 },
  { lat: 1.294898, lng: 103.876371 },
  { lat: 1.294816, lng: 103.876242 },
  { lat: 1.294481, lng: 103.876062 },
  { lat: 1.294213, lng: 103.875883 },
  { lat: 1.293507, lng: 103.875655 },
  { lat: 1.292158, lng: 103.875695 },
  { lat: 1.29073, lng: 103.875742 },
  { lat: 1.289225, lng: 103.876518 },
  { lat: 1.28815, lng: 103.877538 },
  { lat: 1.287103, lng: 103.878355 },
  { lat: 1.286315, lng: 103.878955 },
  { lat: 1.284177, lng: 103.880566 },
];

/**
 * 견적 상태를 한국어로 변환합니다.
 *
 * @param status - 견적 상태
 * @returns 한국어로 변환된 견적 상태 문자열
 */
export const changeBidStatusNameToKr = (status: BidStatus) => {
  switch (status) {
    case "committed":
      return "의뢰 받음";
    case "estimating":
      return "견적 산출 중";
    case "waiting":
      return "사용자 선택 대기중";
    case "waitingForExporterInfo":
      return "수출자 정보 입력 대기중";
    case "inProgress":
      return "진행 중";
    case "finished":
      return "완전종료";
    default:
      return "";
  }
};

/**
 * 견적 프로젝트 상태를 한국어로 변환합니다.
 *
 * @param projectStatus - 견적 프로젝트 상태
 * @returns 한국어로 변환된 견적 프로젝트 상태 문자열
 */
export const changeBidProjectStatusNameToKr = (
  projectStatus: BidProjectStatus | undefined
) => {
  switch (projectStatus) {
    case "waitingFreight":
      return "화물 준비 중";
    case "beforeContactPartner":
      return "수출자 컨택 전";
    case "contactingPartner":
      return "수출자 컨택 중";
    case "scheduling":
      return "출항 준비 중";
    case "containerCarryOut":
      return "공 컨테이너 반출";
    case "containerPickup":
      return "현지 화물 픽업 완료";
    case "gateIn":
      return "터미널 반입 완료";
    case "loaded":
      return "선적 완료";
    case "moving":
      return "출항 완료";
    case "portEntryAndPrepareCustoms":
      return "입항완료 및 통관 준비";
    case "payment":
      return "정산 요청";
    case "completeCustoms":
      return "통관 완료";
    case "delivering":
      return "내륙운송 중";
    case "finished":
      return "종료";
    case "canceled":
      return "취소";
    case "failed":
      return "실패";
    case "inSettlement":
      return "정산 중";
    case "settlementRejected":
      return "정산 반려";
    case "settlementComplete":
      return "정산 완료";
    default:
      return "";
  }
};

/**
 * 운영 관리 카드가 있는지 확인합니다.
 *
 * @param projectStatus - 견적 프로젝트 상태
 * @returns 운영 관리 카드가 있는지 여부
 */
export const checkHasTrelloCard = (projectStatus: BidProjectStatus) => {
  switch (projectStatus) {
    case "beforeContactPartner":
    case "contactingPartner":
    case "waitingFreight":
    case "scheduling":
    case "domesticImportCFS":
    case "moving":
    case "portEntryAndPrepareCustoms":
    case "payment":
    case "completeCustoms":
    case "delivering":
    case "finished":
    case "inSettlement":
    case "settlementRejected":
    case "settlementComplete":
    case "containerCarryOut":
    case "containerPickup":
    case "gateIn":
    case "loaded":
      return true;
    default:
      return false;
  }
};

/**
 * 견적 상세 정보에서 시작 또는 종료 항구의 이름을 반환합니다.
 *
 * @param bidDetail - 견적 상세 정보
 * @param type - 시작 항구 또는 종료 항구 타입
 * @returns 시작 또는 종료 항구의 이름
 */
export const getAdminBidPortName = (
  bidDetail: AdminBidDetail,
  type: "startPort" | "endPort"
) => {
  if (type === "startPort") {
    if (bidDetail.startType === "sea" || bidDetail.startType === "air") {
      return bidDetail.startPort?.nameEN || bidDetail.startAddress;
    }

    if (bidDetail.startType === "inland") {
      return bidDetail.startAddress || "";
    }
  }

  if (type === "endPort") {
    if (bidDetail.endType === "sea" || bidDetail.endType === "air") {
      return bidDetail.endPort?.nameEN || "";
    }

    if (bidDetail.endType === "inland") {
      return bidDetail.endAddress || "";
    }
  }

  return "";
};

/**
 * 견적 제품 정보에서 CBM(부피)을 계산합니다.
 *
 * @param detail - 견적 제품 정보
 * @returns 계산된 CBM 값
 */
export const calculateAdminBidCbm = (detail: BidProductInfo) => {
  if (detail.height && detail.vertical && detail.horizontal) {
    if (detail.volumeUnit === "CM") {
      const totalCBM =
        (detail.height / 100) *
        (detail.vertical / 100) *
        (detail.horizontal / 100);

      return toFixedFloat(totalCBM * detail.quantity, 2);
    }

    if (detail.volumeUnit === "M") {
      const totalCBM = detail.height * detail.vertical * detail.horizontal;
      return toFixedFloat(totalCBM * detail.quantity, 2);
    }
  }

  if (detail.cbm) {
    return toFixedFloat(detail.cbm * detail.quantity, 2);
  }

  return 0;
};

/**
 * 견적 상태를 한국어로 변환합니다.
 *
 * @param quotationsStatus - 견적 상태
 * @returns 한국어로 변환된 견적 상태 문자열
 */
export const changeQuotationsStatusToKr = (
  quotationsStatus: QuotationForUserStatus
) => {
  switch (quotationsStatus) {
    case "registered":
      return "유저 수락 대기중";
    case "succeed":
      return "낙찰 성공";
    case "finished":
      return "낙찰 실패";
    default:
      return "";
  }
};

/**
 * 표시할 스케줄을 반환합니다.
 *
 * @param schedule - 견적 관리 스케줄
 * @returns 스케줄 정보 객체
 */
export function getScheduleToDisplay(
  schedule: BidManagementSchedule | undefined
):
  | {
      hasSchedule: true;
      start: { type: "ETD" | "ATD"; date: string };
      end: { type: "ETA" | "ATA"; date: string };
    }
  | {
      hasSchedule: false;
      start: null;
      end: null;
    } {
  const hasSchedule = !!(
    schedule?.ETD ||
    schedule?.ETA ||
    schedule?.ATD ||
    schedule?.ATA
  );

  if (!hasSchedule) return { hasSchedule: false, start: null, end: null };

  return {
    hasSchedule,
    start: schedule?.ATD
      ? { type: "ATD", date: schedule?.ATD }
      : { type: "ETD", date: schedule?.ETD || "" },
    end: schedule?.ATA
      ? { type: "ATA", date: schedule?.ATA }
      : { type: "ETA", date: schedule?.ETA || "" },
  };
}

/**
 * BL 레이블을 반환합니다.
 *
 * 1. HBL, MBL 둘다 있는 경우 > HBL
 * 2. HBL 없고 MBL 있는 경우  > MBL
 * 3. 둘다 없는 경우          > -
 *
 * @param blRecord - BL 레코드
 * @returns BL 레이블 문자열
 */
export function getBLLabel(blRecord?: { hBL: string; mBL: string }) {
  if (!blRecord) return "-";

  const { hBL, mBL } = blRecord;

  return hBL || mBL || "-";
}

/**
 * 견적 등록 payload에 사용되는 통화 정보를 반환합니다.
 *
 * @param currency - 견적에 적용된 화폐 정보
 * @param exchangeRate - 견적에 적용된 화폐 환율
 * @returns 통화 정보 레코드
 */
export function getCurrencyRecordForBidRegisterPayload({
  currency,
  exchangeRate,
}: {
  /** 견적에 적용된 화폐정보 */
  currency: Currency | undefined;
  /** 견적에 적용된 화폐 환율 */
  exchangeRate: number | undefined;
}): Record<Currency, number> | undefined {
  if (!currency || !exchangeRate) return;

  return {
    [currency]: exchangeRate,
  } as Record<Currency, number>;
}

/**
 * 로그인 시 마지막에 수입인지 수출인지 확인하는데 사용하는 localStorage key값
 */
export const LOCAL_STORAGE_KEY_FOR_LATEST_MYPAGE_DASHBOARD_SHIPMENT_TYPE =
  "dashboardShipmentType";

/**
 * 수출 프로젝트 상태를 한국어로 변환합니다.
 *
 * @param projectStatus - 수출 프로젝트 상태
 * @returns 한국어로 변환된 수출 프로젝트 상태 문자열
 */
export const changeExportProjectStatusNameToKr = (
  projectStatus: BidProjectStatus | undefined
) => {
  switch (projectStatus) {
    case "waitingFreight":
      return "화물 준비 중";
    case "scheduling":
      return "출항 준비 중";
    case "containerCarryOut":
      return "공 컨테이너 반출";
    case "containerPickup":
      return "국내 화물 픽업 완료";
    case "gateIn":
      return "국내 터미널 반입 완료";
    case "domesticImportCFS":
      return "국내 CFS 픽업 완료";
    case "loaded":
      return "선적 완료";
    case "moving":
      return "출항 완료";
    case "portEntryAndPrepareCustoms":
      return "입항완료 및 통관 준비";
    case "completeCustoms":
      return "통관 완료";
    case "delivering":
      return "내륙운송 중";
    case "finished":
      return "종료";
    case "canceled":
      return "취소";
    case "failed":
      return "실패";
    case "inSettlement":
      return "정산 중";
    case "settlementRejected":
      return "정산 반려";
    case "settlementComplete":
      return "정산 완료";
    default:
      return "";
  }
};

/**
 * TODO: label과 value값이 달라 임시로 label값으로 변경해주는 함수 서버에서 value값을 변경하면 제거
 *
 * 포장 타입의 라벨 값을 반환합니다.
 *
 * @param packageType - 셀렉트에서 선택한 포장 타입 value
 * @returns 포장 타입 옵션 리스트의 라벨 값, 없다면 그대로 value를 리턴
 */
export const getPackageLabel = (packageType: string) => {
  return (
    packageOptionList.find((v) => {
      return v.value === packageType;
    })?.label || packageType
  );
};

/**
 * 수출을 위한 통합 아이템 리스트를 반환합니다.
 *
 * @param shipmentItemList - 선적 아이템 리스트
 * @returns 통합 아이템 리스트
 */
export function getConsolidationItemListForExport(
  shipmentItemList: ShipmentItem[]
) {
  /** exporterGroup에 따라 묶이지 않은 bidItems 데이터를 exporterGroup에 따라 다시 묶어야 함 */
  const consolidationItemList: ConsolidationItemList[] = Object.values(
    shipmentItemList.reduce((acc, item) => {
      const { exporterGroup, exporter } = item;

      if (!acc[exporterGroup]) {
        acc[exporterGroup] = {
          exporterInfo: exporter,
          exporterGroup: exporterGroup,
          itemList: [item],
        };
      } else {
        acc[exporterGroup] = {
          ...acc[exporterGroup],
          itemList: [...acc[exporterGroup].itemList, item],
        };
      }

      return acc;
    }, {} as { [key: number]: ConsolidationItemList })
  );

  return consolidationItemList;
}

/**
 * 수입을 위한 통합 아이템 리스트를 반환합니다.
 *
 * @param itemGroupList - 선적 아이템 그룹 리스트
 * @returns 통합 아이템 리스트
 */
export function getConsolidationItemListForImport(
  itemGroupList: AdminShipmentItemGroup[]
) {
  /** exporterGroup에 따라 묶이지 않은 bidItems 데이터를 exporterGroup에 따라 다시 묶어야 함 */
  const consolidationItemList: ConsolidationItemList[] = Object.values(
    itemGroupList.reduce((acc, itemGroup) => {
      const shipmentItem = itemGroup.item;
      const { exporterGroup, exporter } = shipmentItem;

      if (!acc[exporterGroup]) {
        acc[exporterGroup] = {
          exporterInfo: exporter,
          exporterGroup: exporterGroup,
          itemList: [shipmentItem as ShipmentItem],
        };
      } else {
        acc[exporterGroup] = {
          ...acc[exporterGroup],
          itemList: [
            ...acc[exporterGroup].itemList,
            shipmentItem as ShipmentItem,
          ],
        };
      }

      return acc;
    }, {} as { [key: number]: ConsolidationItemList })
  );
  return consolidationItemList;
}

/**
 * Order 과정에서 견적이 있는지 확인합니다.
 *
 * @param quotation - 실시간 견적 또는 "NO_QUOTATION" 또는 null
 * @returns 견적이 있는지 여부
 */
export function checkHasQuotation(
  quotation:
    | RealtimeQuotationFCL
    | RealtimeQuotationAIR
    | RealtimeQuotationLCL
    | "NO_QUOTATION"
    | null
): quotation is
  | RealtimeQuotationFCL
  | RealtimeQuotationAIR
  | RealtimeQuotationLCL {
  if (!quotation) {
    return false;
  }

  if (typeof quotation !== "object") {
    // string으로 오는 경우를 배제하기 위함
    return false;
  }

  return true;
}
