const HEAD_CELL_LIST = [
  {
    id: "select",
    label: "선택",
    disablePadding: false,
    width: 100,
  },
  {
    id: "type",
    label: "구분",
    disablePadding: false,
    width: 200,
  },
  {
    id: "totalPrice",
    label: "공급가액",
    disablePadding: false,
    width: 200,
  },
  {
    id: "vatPrice",
    label: "부가세",
    disablePadding: false,
    width: 200,
  },
  {
    id: "taxFee",
    label: "대납세금",
    disablePadding: false,
    width: 200,
  },
  {
    id: "finalPrice",
    label: "합계",
    disablePadding: false,
    width: 200,
  },
  {
    id: "createdAt",
    label: "생성일",
    disablePadding: false,
    width: 200,
  },
  {
    id: "deletedAt",
    label: "삭제일",
    disablePadding: false,
    width: 200,
  },
];

export default HEAD_CELL_LIST;
