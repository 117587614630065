import { TableBody, TableCell, TableRow, Typography } from "@mui/material";

import { ScheduleHistoryDetail } from "@sellernote/_shared/src/types/forwarding/trello";
import { formatDate } from "@sellernote/_shared/src/utils/common/date";
import { replaceEmptyToDash } from "@sellernote/_shared/src/utils/common/string";
import { changeBidProjectStatusNameToKr } from "@sellernote/_shared/src/utils/forwarding/bid";

import ChangeLog from "./ChangeLog";

const commonStyle = { borderRight: "1px solid #e0e0e0" };

export default function HistoryTableBody({
  rowList,
}: {
  rowList: ScheduleHistoryDetail[];
}) {
  return (
    <TableBody>
      {rowList.map((row) => {
        return (
          <TableRow hover key={row.changedAt}>
            <TableCell align="center" sx={commonStyle}>
              <Typography>
                {formatDate({
                  date: row.changedAt,
                  type: "YY_MM_DD_HH_mm_ss",
                })}
              </Typography>
            </TableCell>

            <TableCell align="center" sx={commonStyle}>
              <Typography>
                {changeBidProjectStatusNameToKr(row.shipmentStatus)}
              </Typography>
            </TableCell>

            <TableCell align="center" sx={commonStyle}>
              <ChangeLog row={row} />
            </TableCell>

            <TableCell align="center" sx={commonStyle}>
              <Typography>
                {replaceEmptyToDash(
                  row.changedBy === "mt" ? "마린트래픽" : row.changedBy
                )}
              </Typography>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}
