// date 관련
// 매년 같은 공휴일
export const PUBLIC_HOLIDAY_LIST = [
  "01/01",
  "03/01",
  "05/01", // 근로자의 날
  "05/05",
  "06/06",
  "08/15",
  "10/03",
  "10/09",
  "12/25",
];

// 음력 공휴일, 음력 명절
export const LUNAR_PUBLIC_HOLIDAY_LIST = [
  // 2025년
  // 설 연휴
  "2025/01/28",
  "2025/01/29",
  "2025/01/30",
  // 추석 연휴
  "2025/10/06",
  "2025/10/07",
  // 2026년
  // 설 연휴
  "2026/02/16",
  "2026/02/17",
  "2026/02/18",
  // 추석 연휴
  "2026/09/24",
  "2026/09/25",
  "2026/09/26",
];

// 대체 공휴일
export const SUBSTITUTE_HOLIDAY_LIST = [
  // 2025년
  "2025/03/03", // 삼일절 대체 휴일
  "2025/05/06", // 어린이날 대체 휴일
  "2025/10/08", // 추석 연휴 대체 휴일
  // 2026년
  "2026/03/02", // 삼일절 대체 휴일
  "2026/05/25", // 부처님 오신날 대체 휴일
];

/**
 * @deprecated
 * TODO: 이거 쓰면 안됨 (매번 getAppTodayMidnight을 호출하는 방식으로 써야함)
 */
export const TODAY = new Date();

/**
 * @deprecated
 * TODO: 이거 쓰면 안됨 (매번 getAppTomorrowMidnight을 호출하는 방식으로 써야함)
 */
export const TOMORROW = (() => {
  const tempDate = new Date();

  tempDate.setDate(TODAY.getDate() + 1);

  return tempDate;
})();
