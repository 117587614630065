import { Grid } from "@mui/material";

import { ShipmentItem } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { ContainerType } from "@sellernote/_shared/src/types/forwarding/bid";
import { getBooleanValueToKr } from "@sellernote/_shared/src/utils/common/etc";

import SADSTable, { TableBodyRow, TableHeadCell } from "../../../SADSTable";
import TotalTable, { TableBodyTotalRow } from "../../TotalTable";

type CellKey =
  | "NO"
  | "품명"
  | "컨테이너"
  | "물동량"
  | "위험물"
  | "CBM"
  | "중량";

const headCellList: TableHeadCell<CellKey>[] = [
  { cellKey: "NO", label: "NO", width: 100 },
  { cellKey: "품명", label: "품명", width: 100 },
  { cellKey: "컨테이너", label: "컨테이너 X 수량", align: "right", width: 100 },
  { cellKey: "물동량", label: "물동량", align: "right", width: 100 },
  { cellKey: "CBM", label: "CBM", align: "right", width: 100 },
  { cellKey: "중량", label: "중량", align: "right", width: 100 },
  { cellKey: "위험물", label: "위험물", align: "right", width: 100 },
];

type TotalKey = "총계" | Exclude<CellKey, "NO" | "품명">;

type Item = Pick<
  ShipmentItem,
  "id" | "name" | "isDangerous" | "cbm" | "weight"
>;

type ContainerInfo = { containerType: ContainerType; quantity: number };

type ItemGroup = { containerInfo?: ContainerInfo[] } & {
  item: Item;
};

function FCLItemList({
  itemGroupList,
  supply,
}: {
  itemGroupList: ItemGroup[];
  supply: number;
}) {
  const isSingleRow = itemGroupList.length === 1;

  const bodyRowList: TableBodyRow<CellKey>[] = (itemGroupList || []).map(
    ({ item, containerInfo }) => {
      return {
        rowKey: item.id,
        NO: { value: item.id },
        품명: { value: item.name },
        컨테이너: {
          value: (
            <Grid container direction={"column"}>
              {containerInfo?.map((v, i) => (
                <Grid item key={i}>
                  {v.containerType} X {v.quantity}
                </Grid>
              ))}
            </Grid>
          ),
        },
        물동량: { value: isSingleRow ? `${supply}FEU` : "-", align: "right" }, // 단건인 경우 총 물동량 표기, 여러건인 경우 미표기.
        CBM: { value: item.cbm || "-" },
        중량: { value: item.weight || "-" },
        위험물: {
          value: getBooleanValueToKr({
            value: item.isDangerous,
            textType: "포함/미포함",
          }),
        },
      };
    }
  );

  /**
   * containerType 별 quantity 합산
   */
  const totalContainerQuantityList = (() => {
    const containerInfoList = itemGroupList?.flatMap((v) => v.containerInfo);

    return containerInfoList.reduce((acc, cur) => {
      if (!cur) {
        return acc;
      }

      if (acc.length === 0) {
        return acc.concat(cur);
      }

      const containerInfo = acc.find(
        (v) => v.containerType === cur.containerType
      );

      if (containerInfo) {
        return acc.map((v) =>
          v.containerType === cur.containerType
            ? { ...v, quantity: v.quantity + cur.quantity }
            : v
        );
      }

      return acc.concat(cur);
    }, [] as { containerType: ContainerType; quantity: number }[]);
  })();

  const totalRow: TableBodyTotalRow<TotalKey> = {
    총계: { value: "총계", isLabel: true, colSpan: 2, align: "center" },
    컨테이너: {
      value: (
        <Grid container direction={"column"}>
          {totalContainerQuantityList.map((v) => (
            <Grid item key={v.containerType}>
              {v.containerType} X {v.quantity}
            </Grid>
          ))}
        </Grid>
      ),
      align: "right",
    },
    물동량: { value: `${supply}FEU`, align: "right" },
    CBM: { value: "-", align: "right" },
    중량: { value: "-", align: "right" },
    위험물: { value: "-", align: "right" },
  };

  return (
    <Grid direction="column">
      <SADSTable headCellList={headCellList} bodyRowList={bodyRowList} />

      {!isSingleRow && (
        <TotalTable headCellList={headCellList} totalRow={totalRow} />
      )}
    </Grid>
  );
}

export default FCLItemList;
