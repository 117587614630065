const HEAD_CELL_LIST = [
  {
    id: "select",
    disablePadding: false,
    label: "선택",
    width: 100,
  },
  {
    id: "partnerType",
    disablePadding: false,
    label: "파트너 유형",
    width: 200,
  },
  {
    id: "companyName",
    disablePadding: false,
    label: "회사명",
    width: 300,
  },
  {
    id: "totalPrice",
    disablePadding: false,
    label: "공급가액",
    width: 200,
  },
  {
    id: "vatPrice",
    disablePadding: false,
    label: "부가세",
    width: 200,
  },
  {
    id: "finalPrice",
    disablePadding: false,
    label: "합계",
    width: 200,
  },
  {
    id: "createdAt",
    disablePadding: false,
    label: "등록 일자",
    width: 200,
  },
  {
    id: "deletedAt",
    disablePadding: false,
    label: "삭제 일자",
    width: 200,
  },
];

export default HEAD_CELL_LIST;
