import { Chip, Grid } from "@mui/material";

import { FreightType } from "@sellernote/_shared/src/types/common/common";
import { ShipmentCreateContainersInfo } from "@sellernote/_shared/src/types/forwarding/adminBidCreate";
import { BidProductInfo } from "@sellernote/_shared/src/types/forwarding/bid";

import ItemListForFCL from "./ItemListForFCL";
import ItemListForLCLAndAIR from "./ItemListForLCLAndAIR";

function ItemList({
  freightType,
  containersInfo,
  productsInfo,
}: {
  freightType: FreightType;
  containersInfo: ShipmentCreateContainersInfo[];
  productsInfo: BidProductInfo[];
}) {
  return (
    <Grid container spacing={2} sx={{ padding: "50px" }}>
      <Grid item xs={12}>
        <Chip color="primary" label="컨테이너 상세정보" variant="outlined" />
      </Grid>

      {freightType === "FCL" && (
        <ItemListForFCL containersInfo={containersInfo} />
      )}

      {(freightType === "LCL" || freightType === "AIR") && (
        <ItemListForLCLAndAIR
          productsInfo={productsInfo}
          freightType={freightType}
        />
      )}
    </Grid>
  );
}

export default ItemList;
