import createReactComponent from '../createReactComponent';
export default createReactComponent('square-x', 'IconSquareX', [
  [
    'path',
    {
      d: 'M3 5a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-14z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 9l6 6m0 -6l-6 6', key: 'svg-1' }],
]);
