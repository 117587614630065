import React, { useMemo } from "react";
import { Typography } from "@mui/material";

import BUSINESS_MANAGEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/BUSINESS_MANAGEMENT_QUERY";
import { RemarketingHistory } from "@sellernote/_shared/src/types/forwarding/businessManagement";
import { formatDate } from "@sellernote/_shared/src/utils/common/date";
import { changeProceedReasonToNameKr } from "@sellernote/_shared/src/utils/forwarding/admin/businessManagement";
import Modal from "@sellernote/_shared-for-forwarding-admin/src/components/Modal";
import Table, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/_shared-for-forwarding-admin/src/components/Table";

type CellId = keyof RemarketingHistory;

const BusinessManagementHistoryModal = ({
  setShowsHistoryModal,
  showHistoryModal,
  bidDetailId,
}: {
  setShowsHistoryModal: React.Dispatch<React.SetStateAction<boolean>>;
  showHistoryModal: boolean;
  bidDetailId: number;
}) => {
  const { data: historyData } =
    BUSINESS_MANAGEMENT_QUERY.useGetRemarketingHistory({
      bidId: bidDetailId,
    });

  const headCells: TableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "manager",
        disablePadding: false,
        label: "담당자",
      },
      {
        id: "remarketingType",
        disablePadding: false,
        label: "구분",
      },
      {
        id: "logDetails",
        disablePadding: false,
        label: "담당자",
      },
      {
        id: "contactedAt",
        disablePadding: false,
        label: "담당자",
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!historyData) return [];

    return historyData?.map((v) => {
      const row: TableBodyRow<CellId> = {
        manager: v.manager.name,
        remarketingType: v.remarketingType === "call" ? "콜" : "메일",
        logDetails: changeProceedReasonToNameKr(v.logDetails),
        contactedAt: formatDate({
          date: v.contactedAt,
          type: "YY_MM_DD_HH_mm_ss",
        }),
      };
      return row;
    });
  }, [historyData]);

  return (
    <Modal
      isOpened={showHistoryModal}
      handleClose={() => {
        setShowsHistoryModal(false);
      }}
      modalBody={
        <>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {bidDetailId} / 컨택횟수
          </Typography>

          <Table rows={rows} headCells={headCells} />
        </>
      }
    />
  );
};

export default BusinessManagementHistoryModal;
