import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { FreeTimeContainer } from "@sellernote/_shared/src/api-interfaces/shipda-api/freeTime";
import { FreeTimeCategory } from "@sellernote/_shared/src/types/forwarding/bid";
import {
  formatDate,
  getRemainedDays,
} from "@sellernote/_shared/src/utils/common/date";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { replaceEmptyToDash } from "@sellernote/_shared/src/utils/common/string";
import {
  calculateFreeTimeDate,
  toFormattedDifferentDate,
} from "@sellernote/_shared/src/utils/forwarding/freeTime";

const FREE_TIME_CATEGORY_LABEL: Record<FreeTimeCategory, string> = {
  all: "", // all은 사용하는 곳 없어서 빈string으로 둠
  storage: "Storage",
  DET: "DET",
  DEM: "DEM",
  DETAndDEM: "DET + DEM",
};

export default function useFreeTimeTableData({
  containers,
  isCombined,
  filteredContainerNoList,
}: {
  containers?: Array<FreeTimeContainer>;
  isCombined?: boolean;
  filteredContainerNoList: string[];
}) {
  const { t } = useTranslation();

  // 의뢰당 컨테이너 수가 적고 페이지네이션이 없어서 프론트에서 필터링하기로 합의.
  const containerInfoList = useMemo(() => {
    // filter 에서 선택한 containerNo 가 없으면, 전체 리스트를 모두 보여준다. (default)
    if (!filteredContainerNoList.length) return containers;

    return containers?.filter(({ containerNo }) =>
      filteredContainerNoList.includes(containerNo)
    );
  }, [filteredContainerNoList, containers]);

  const toFormattedOccurrenceDate = (date: Date | null) =>
    replaceEmptyToDash(formatDate({ date, type: "YY_MM_DD" }));

  const dateAndPriceByStatus = useCallback(
    (containerInfo: FreeTimeContainer) => {
      const {
        inboundTime,
        outboundTime,
        returnTime,

        storageFreeTimeDay,
        storageCharge,

        combinedFreeTimeDay,
        combinedCharge,

        demurrageFreeTimeDay: DEMFreeTimeDay,
        demurrageCharge: DEMCharge,

        detentionFreeTimeDay: DETFreeTimeDay,
        detentionCharge: DETCharge,

        /** 계산이 된 프리타임 기한 */
        storageLFD,
        demurrageLFD,
        detentionLFD,
        combinedLFD,
      } = containerInfo;

      const LFD = (() => {
        /**
         *
         * @param category 화면에 표시되는 항목. (DEM, DET, Storage, DET+DEM)
         * @param freeTimeDay 반입 후 n일을 표기하는 프리타임 예상일.
         * @param dates 위 calculateDate 함수로 계산한 날짜들.
         * @returns 각 조건에 따라 화면에 표시되는 포맷에 맞춘 날짜 형식.
         */
        const toLFDFormat = (
          category: FreeTimeCategory,
          freeTimeDay: number,
          dates: {
            occurrenceDate: Date | null;
            freeTimeDate: string;
            exceededDuration: number;
          }
        ) => {
          const { freeTimeDate, exceededDuration } = dates;

          const isOverdue = exceededDuration > 0;

          return (
            <div className={`${isOverdue && "exceeded-duration"}`}>
              {FREE_TIME_CATEGORY_LABEL[category]}:{" "}
              {freeTimeDate
                ? freeTimeDate
                : category === "DET"
                ? t("components:FreeTimeTable.반출_후___freeTimeDay__일", {
                    freeTimeDay,
                  })
                : t("components:FreeTimeTable.반입_후___freeTimeDay__일", {
                    freeTimeDay,
                  })}
              {isOverdue &&
                t("components:FreeTimeTable.____exceededDuration__일_초과_", {
                  exceededDuration,
                })}
            </div>
          );
        };

        return {
          storage: toLFDFormat(
            "storage",
            storageFreeTimeDay,
            calculateFreeTimeDate(inboundTime, outboundTime, storageLFD)
          ),
          DEM: toLFDFormat(
            "DEM",
            DEMFreeTimeDay,
            calculateFreeTimeDate(inboundTime, outboundTime, demurrageLFD)
          ),
          DET: toLFDFormat(
            "DET",
            DETFreeTimeDay,
            calculateFreeTimeDate(outboundTime, returnTime, detentionLFD) // DET 만 발생일 조건이 다름.
          ),
          DETAndDEM: toLFDFormat(
            "DETAndDEM",
            combinedFreeTimeDay,
            calculateFreeTimeDate(inboundTime, returnTime, combinedLFD)
          ),
        };
      })();

      const charge = (() => {
        if (!inboundTime)
          return {
            storage: t("components:FreeTimeTable._원"),
            DEM: t("components:FreeTimeTable._원"),
            DETAndDEM: t("components:FreeTimeTable._원"),
            DET: t("components:FreeTimeTable._원"),
          };

        const toPriceFormat = ({
          price,
          LFD,
          completeDate,
        }: {
          price: number;
          LFD: Date | null;
          completeDate?: Date | null;
        }) => {
          const today = new Date();

          // 프리타임 기한을 초과한 기간. (완료일이 아직 없는 경우, 현시간 기준)
          const exceededDuration = getRemainedDays(
            toFormattedDifferentDate(LFD) ?? 0,
            toFormattedDifferentDate(completeDate) ||
              toFormattedDifferentDate(today)
          );

          const isOverdue = exceededDuration > 0;

          const isConfirmed = !!completeDate;

          // 초과일이 있는데 비용이 0으로 오는 경우, 비용별도문의로 표기. (요금표가 없는 경우)
          const isUnavailablePrice = !price && isOverdue;

          // 초과일이 없고, 비용이 0으로 오는 경우, 0원이므로 초과비용없음 표기.
          const isNoPrice = !price && !isOverdue;

          const priceDisplay = (() => {
            if (price)
              return t("components:FreeTimeTable.__price__원", {
                price: toThousandUnitFormat(price),
              });

            if (isUnavailablePrice)
              return t("components:FreeTimeTable.비용별도문의");

            if (isNoPrice) return t("components:FreeTimeTable.초과비용없음");

            return "";
          })();

          return (
            <>
              {priceDisplay}

              {isConfirmed && !isUnavailablePrice && (
                <em className="confirmed-charge">
                  &nbsp;({t("components:FreeTimeTable.확정")})
                </em>
              )}
            </>
          );
        };

        return {
          storage: toPriceFormat({
            price: storageCharge,
            LFD: storageLFD,
            completeDate: outboundTime,
          }),
          DEM: toPriceFormat({
            price: DEMCharge,
            LFD: demurrageLFD,
            completeDate: outboundTime,
          }),
          DETAndDEM: toPriceFormat({
            price: combinedCharge,
            LFD: combinedLFD,
            completeDate: returnTime,
          }),
          DET: toPriceFormat({
            price: DETCharge,
            LFD: detentionLFD,
            completeDate: returnTime,
          }),
        };
      })();

      return [
        {
          status: t("components:FreeTimeTable.반입"),
          date: toFormattedOccurrenceDate(inboundTime),
          LFD: "-",
          price: "-",
        },
        {
          status: t("components:FreeTimeTable.반출"),
          date: toFormattedOccurrenceDate(outboundTime),
          LFD: (
            <>
              {!isCombined && (
                <>
                  {LFD.DEM}
                  <br />
                </>
              )}

              {LFD.storage}
            </>
          ),
          price: (
            <>
              {!isCombined && (
                <>
                  {charge.DEM}
                  <br />
                </>
              )}

              {charge.storage}
            </>
          ),
        },
        {
          status: t("components:FreeTimeTable.반납"),
          date: toFormattedOccurrenceDate(returnTime),
          LFD: isCombined ? LFD.DETAndDEM : LFD.DET,
          price: isCombined ? charge.DETAndDEM : charge.DET,
        },
      ];
    },
    [isCombined, t]
  );

  const rows = useMemo(() => {
    if (!containerInfoList) return [];

    return containerInfoList
      .map((containerInfo) => {
        // 하나의 컨테이너 정보에 반입, 반출, 반납 세가지 status 의 경우로 나뉘어진 리스트.
        const listByStatus = dateAndPriceByStatus(containerInfo);

        return listByStatus.map((item) => {
          return {
            id: containerInfo.containerNo,
            containerNo: containerInfo.containerNo,
            containerType: containerInfo.containerType,
            ...item,
          };
        });
      })
      .flat();
  }, [dateAndPriceByStatus, containerInfoList]);

  return { rows };
}
