import {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useState,
} from "react";
import { Autocomplete } from "@mui/material";

import { CHANGE_ADMIN_BID_CONSOLIDATION_INFO_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBid";
import ADMIN_ZONE_ADDRESS_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_ZONE_ADDRESS_QUERY";
import { useDebounce } from "@sellernote/_shared/src/utils/common/hook";

import TextField from "../../components/TextField";

import useZoneAddressNameListForAutoComplete, {
  ZoneAddressNameListForAutoComplete,
} from "./useZoneAddressNameListForAutoComplete";

function ZoneAddressTextFiled({
  zoneName,
  pageType,
  setBidUpdateState,
  setTrelloZoneNameState,
  setTrelloZoneIdState,
}: {
  zoneName: string;
  pageType: "bid" | "trello";
  setBidUpdateState?: Dispatch<
    SetStateAction<CHANGE_ADMIN_BID_CONSOLIDATION_INFO_REQ>
  >;
  setTrelloZoneNameState?: Dispatch<SetStateAction<string>>;
  setTrelloZoneIdState?: Dispatch<SetStateAction<number>>;
}) {
  const [searchTerm, setSearchTerm] = useState(zoneName);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const { data: zoneAddress } = ADMIN_ZONE_ADDRESS_QUERY.useGetAdminZoneAddress(
    {
      address: searchTerm ? debouncedSearchTerm : zoneName,
    }
  );

  const { zoneAddressNameListForAutoComplete } =
    useZoneAddressNameListForAutoComplete({
      zoneAddressData: zoneAddress,
    });

  const getZoneId = useCallback(
    (selectValue: string) => {
      if (!zoneAddress) {
        return 0;
      }

      if (!selectValue) return 0;

      return (
        zoneAddress?.find((zoneAddressData) => {
          return (
            zoneAddressData.roadAddress === selectValue ||
            zoneAddressData.jibunAddress === selectValue
          );
        })?.id || 0
      );
    },
    [zoneAddress]
  );

  const handlePurchasePartnerChange = useCallback(
    ({
      event,
      selectValue,
    }: {
      event: SyntheticEvent<Element, Event>;
      selectValue: ZoneAddressNameListForAutoComplete | null;
    }) => {
      setSearchTerm(selectValue?.value || "");
      // 현재 운영 관리 페이지와 의뢰 상세 페이지 수정 방식이 조금 다르기 때문에 구분
      if (pageType === "bid" && setBidUpdateState) {
        setBidUpdateState((prev) => {
          return {
            ...prev,
            endAddress: selectValue?.value || "",
            endPortId: null,
            zoneId: getZoneId(selectValue?.value || ""),
          };
        });

        return;
      }

      if (
        pageType === "trello" &&
        setTrelloZoneNameState &&
        setTrelloZoneIdState
      ) {
        setTrelloZoneIdState(getZoneId(selectValue?.value || ""));
        setTrelloZoneNameState(selectValue?.value || "");
      }
      return;
    },
    [
      getZoneId,
      pageType,
      setBidUpdateState,
      setTrelloZoneIdState,
      setTrelloZoneNameState,
    ]
  );

  const handleSearchTermChange = useCallback((e) => {
    setSearchTerm(e.target.value);
  }, []);

  return (
    <Autocomplete
      filterOptions={(options) => options}
      size="small"
      onChange={(event, newValue: ZoneAddressNameListForAutoComplete | null) =>
        handlePurchasePartnerChange({ event, selectValue: newValue })
      }
      value={{
        label: searchTerm,
        value: searchTerm,
      }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      options={zoneAddressNameListForAutoComplete}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth={true}
          onChange={handleSearchTermChange}
        />
      )}
    />
  );
}
export default ZoneAddressTextFiled;
