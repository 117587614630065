import { TFunction } from "react-i18next";

import {
  BidEventsMessageType,
  BidProjectStatus,
} from "@sellernote/_shared/src/types/forwarding/bid";
import { InfographicTransferData } from "@sellernote/_shared/src/types/forwarding/trello";

/**
 * 인포그래픽 > 현재 운송 모선명을 찾는 유틸함수
 * 'LTS'는 내륙 이벤트로 '내륙 운송 중'을 노출함 (#15299)
 *
 * @param t 쉽다에서 다국어처리를 위해 전달, 쉽다 Admin은 전달 X
 */
function getTransportNameForInfographic(
  shipList: InfographicTransferData[],
  t?: TFunction
) {
  const currentShipName =
    shipList.find(({ shipNow }) => shipNow)?.shipName ?? "-";

  if (currentShipName === "LTS") {
    return t
      ? t("page-mypage-bid:운송관리_공통_상태_내륙운송중")
      : "내륙 운송 중";
  }

  return currentShipName;
}

/**
 * 인포그래픽 > 항차/편명을 찾는 유틸함수
 */
function getTransportNumberForInfographic(shipList: InfographicTransferData[]) {
  return shipList.find(({ shipNow }) => shipNow)?.voyageNo ?? "-";
}

function getShipmentProjectStatusCompareOrder({
  target,
  projectStatus,
}: {
  target: BidProjectStatus;
  projectStatus: BidProjectStatus | BidEventsMessageType | undefined;
}): { isBefore: boolean; isAfter: boolean } {
  if (!projectStatus) {
    return { isBefore: false, isAfter: false };
  }

  const projectStatusList: BidProjectStatus[] = [
    "beforeContactPartner",
    "contactingPartner",
    "scheduling",
    "containerCarryOut",
    "containerPickup",
    "gateIn",
    "loaded",
    "moving",
    "portEntryAndPrepareCustoms",
    "payment",
    "completeCustoms",
    "delivering",
    "finished",
    "inSettlement",
    "settlementRejected",
    "settlementComplete",
    "canceled",
    "failed",
    "expired",
  ];

  const targetIndex = projectStatusList.findIndex(
    (status) => status === target
  );

  const projectStatusIndex = projectStatusList.findIndex(
    (status) => status === projectStatus
  );

  return {
    isBefore: targetIndex > projectStatusIndex,
    isAfter: targetIndex <= projectStatusIndex,
  };
}

export {
  getTransportNameForInfographic,
  getTransportNumberForInfographic,
  getShipmentProjectStatusCompareOrder,
};
