import { Dispatch, SetStateAction } from "react";
import { Box, Divider } from "@mui/material";

import { GET_CARGO_MANIFEST_RES as CargoManifestInfoProps } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/trello";

import { ContainerFormChangeProps } from "../../types";

import AddressForm from "./AddressForm";
import ContainerInfo from "./ContainerInfo";
import FirstCategoryForm from "./FirstCategoryForm";
import SecondCategoryForm from "./SecondCategoryForm";

export default function FormList({
  form,
  onFormChange,
  onContainerFormChange,
  onContainerAdd,
  onContainerDelete,
  onEstimatedTimeChange,
  isEditMode,
  isImport,
  setDeletedIdList,
}: {
  form: CargoManifestInfoProps;
  onFormChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onContainerFormChange: ({
    index,
    key,
    value,
  }: ContainerFormChangeProps) => void;
  onContainerAdd: () => void;
  onContainerDelete: ({ index }: { index: number }) => void;
  onEstimatedTimeChange: (value: string | null, isImport: boolean) => void;
  isEditMode: boolean;
  isImport: boolean;
  /** 함수형 업데이트를 위해 Dispatch 타입 사용 */
  setDeletedIdList: Dispatch<SetStateAction<number[]>>;
}) {
  return (
    <>
      <Box
        display={"flex"}
        gap={3}
        justifyContent={"space-between"}
        width={"100%"}
      >
        <FirstCategoryForm
          form={form}
          onFormChange={onFormChange}
          isEditMode={isEditMode}
          isImport={isImport}
        />

        <Divider orientation="vertical" flexItem />

        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-evenly"}
          width={"50%"}
        >
          <SecondCategoryForm
            form={form}
            onFormChange={onFormChange}
            onEstimatedTimeChange={onEstimatedTimeChange}
            isEditMode={isEditMode}
            isImport={isImport}
          />

          <Divider sx={{ mt: 2, mb: 2 }} />

          <AddressForm
            form={form}
            onFormChange={onFormChange}
            isEditMode={isEditMode}
          />
        </Box>
      </Box>

      <Divider />

      <ContainerInfo
        containerList={form.containers ?? []}
        onContainerFormChange={onContainerFormChange}
        onContainerAdd={onContainerAdd}
        onContainerDelete={onContainerDelete}
        isEditMode={isEditMode}
        isImport={isImport}
        setDeletedIdList={setDeletedIdList}
      />
    </>
  );
}
