import { ContainerInfo } from "../../types";

const validateContainerType = (list: ContainerInfo[]) => {
  return list.every((container) => container.containerType);
};

const validateDecimalPlaces = (weight: number): boolean => {
  if (Number.isInteger(weight)) return true;

  const decimalPart = weight.toString().split(".")[1];
  return Boolean(decimalPart && decimalPart.length < 4);
};

const validateContainerNo = (list: ContainerInfo[]) => {
  return list.every((container) => container.containerNo.length === 11);
};

/**
 * Gross Weight, Tare Weight 값 검증
 *
 * 조건 1) 0을 입력했을 때는 유효성 검사를 진행하지 않는다.
 * 조건 2) 정수를 입력했을 때, 소수점을 입력하지 않아도 된다.
 * 조건 3) 소수점이 있다면 3자리를 초과하지 않는다.
 */
const validateWeight = (list: ContainerInfo[]) => {
  return list.every(
    (container) =>
      validateDecimalPlaces(container.grossWeight) &&
      validateDecimalPlaces(container.tareWeight)
  );
};

export { validateWeight, validateContainerNo, validateContainerType };
