import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import {
  ADMIN_BID_SCHEDULE_STATUS_OPTION_LIST,
  ADMIN_EXPORT_STATUS_OPTION_LIST,
} from "@sellernote/_shared/src/constants/forwarding/adminBid";
import { TrelloBidDetail } from "@sellernote/_shared/src/types/forwarding/trello";
import { changeBidProjectStatusNameToKr } from "@sellernote/_shared/src/utils/forwarding/bid";

/**
 * 운영 관리 카드 상태를 변경하는 컴포넌트
 *   - 수입은 상태변경이 불가능해서 수출에서만 사용
 */
function ChangeStatus({
  isScheduleStatus,
  isFinishedStatus,
  trelloDetail,
  handleProjectStatusChange,
  handleExportProjectStatusChange,
}: {
  isScheduleStatus: boolean;
  isFinishedStatus: boolean;
  trelloDetail: TrelloBidDetail;
  handleProjectStatusChange: (e: SelectChangeEvent) => void;
  handleExportProjectStatusChange: (e: SelectChangeEvent) => void;
}) {
  const { projectStatus, freightType } = trelloDetail;

  // 운송방식에 따라서 상태가 조금씩 다름
  const filteredExportStatusOptionListByFreightType =
    ADMIN_EXPORT_STATUS_OPTION_LIST.filter((option) => {
      if (freightType === "FCL") {
        return option.value !== "domesticImportCFS";
      }

      if (freightType === "LCL") {
        return option.value !== "containerCarryOut";
      }

      return (
        option.value !== "containerCarryOut" &&
        option.value !== "domesticImportCFS"
      );
    });

  if (!isFinishedStatus && !trelloDetail.isImport) {
    return (
      <Box display={"flex"} alignItems={"center"} gap={2}>
        <Typography>상태: </Typography>

        <FormControl>
          <InputLabel>수동처리</InputLabel>

          <Select
            size="small"
            label={"수동처리"}
            value={projectStatus}
            onChange={handleExportProjectStatusChange}
          >
            {filteredExportStatusOptionListByFreightType.map(
              (option, index) => {
                return (
                  <MenuItem
                    disabled={
                      // 현재 상태 포함 이전 상태까지 비활성화
                      index <=
                      filteredExportStatusOptionListByFreightType.findIndex(
                        (opt) => opt.value === projectStatus
                      )
                    }
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </MenuItem>
                );
              }
            )}
          </Select>
        </FormControl>
      </Box>
    );
  }

  if (isScheduleStatus && APP_NAME === "shipda-admin") {
    return (
      <Box display={"flex"} alignItems={"center"}>
        <Typography>상태: </Typography>

        <FormControl>
          <InputLabel>수동처리</InputLabel>

          <Select
            label={"수동처리"}
            value={projectStatus}
            onChange={handleProjectStatusChange}
          >
            {ADMIN_BID_SCHEDULE_STATUS_OPTION_LIST.map((option) => {
              return (
                <MenuItem
                  disabled={
                    // FCL에서만 모든 상태가 활성화
                    (trelloDetail.freightType !== "FCL" &&
                      option.value !== "moving") ||
                    // 뒷 단계 상태일 때는 앞 단계는 선택 불가능하다.
                    (projectStatus === "loaded" &&
                      (option.value === "gateIn" ||
                        option.value === "containerPickup" ||
                        option.value === "containerCarryOut" ||
                        option.value === "scheduling")) ||
                    (projectStatus === "gateIn" &&
                      (option.value === "containerPickup" ||
                        option.value === "containerCarryOut" ||
                        option.value === "scheduling")) ||
                    (projectStatus === "containerPickup" &&
                      (option.value === "containerCarryOut" ||
                        option.value === "scheduling")) ||
                    (projectStatus === "containerCarryOut" &&
                      option.value === "scheduling")
                  }
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    );
  }

  return (
    <Typography style={{ marginTop: "5px" }}>
      상태 :{changeBidProjectStatusNameToKr(projectStatus)}
    </Typography>
  );
}

export default ChangeStatus;
