import { Add } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";

export default function Header({
  numberOfContainer,
  onContainerAdd,
  isEditMode,
}: {
  numberOfContainer: number;
  onContainerAdd: () => void;
  isEditMode: boolean;
}) {
  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{ mb: 1 }}
    >
      <Typography className="sads" sx={{ fontWeight: "bold" }}>
        컨테이너 정보 (총 {numberOfContainer}개)
      </Typography>

      <Button
        className="sads"
        size="small"
        variant="contained"
        onClick={onContainerAdd}
        disabled={!isEditMode}
      >
        <Add />
      </Button>
    </Box>
  );
}
