import createReactComponent from '../createReactComponent';
export default createReactComponent('file-type-xls', 'IconFileTypeXls', [
  ['path', { d: 'M14 3v4a1 1 0 0 0 1 1h4', key: 'svg-0' }],
  ['path', { d: 'M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4', key: 'svg-1' }],
  ['path', { d: 'M4 15l4 6', key: 'svg-2' }],
  ['path', { d: 'M4 21l4 -6', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M17 20.25c0 .414 .336 .75 .75 .75h1.25a1 1 0 0 0 1 -1v-1a1 1 0 0 0 -1 -1h-1a1 1 0 0 1 -1 -1v-1a1 1 0 0 1 1 -1h1.25a.75 .75 0 0 1 .75 .75',
      key: 'svg-4',
    },
  ],
  ['path', { d: 'M11 15v6h3', key: 'svg-5' }],
]);
