import { useCallback, useState } from "react";

import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";

/**
 * 운영 관리 상세 > 통관현황 > 화물관리번호 클릭시, 유니패스 화물 진행 정보를 새창으로 띄우는 기능
 */
export default function useHandleCargoManagementUnipassHTMLString() {
  /** 화물관리번호 */
  const [cargoMtNo, setCargoMtNo] = useState<string | undefined>(undefined);

  const handleCargoMtNoUpdate = useCallback((value: string) => {
    setCargoMtNo(value);
  }, []);

  const { ResponseHandler: ResponseHandlerOfGetCargoManagementUnipassPage } =
    TRELLO_BID_QUERY.useGetCargoManagementUnipassPage({
      cargoMtNo: cargoMtNo,
      enabled: Boolean(cargoMtNo),

      onSuccess: (data: string) => {
        const unipassWindow = window.open("", "Unipass", "_blank");

        if (!unipassWindow) {
          alert("유니패스 페이지를 불러오는 데 실패했습니다.");
          return;
        }

        /** 데이터가 없을때, 화면에 띄운 로딩 안내 문구 제거 */
        unipassWindow.document.getElementById("fetching-unipass")?.remove();

        /** 새창에 응답 HTML string을 렌더링 */
        unipassWindow.document.write(data);
        unipassWindow.document.close();

        setCargoMtNo(undefined);
      },

      onError: () => {
        setCargoMtNo(undefined);
        window.open("", "Unipass", "_blank")?.close();
      },
    });

  return {
    handleCargoMtNoUpdate,
    ResponseHandlerOfGetCargoManagementUnipassPage,
  };
}
