import { useQueryClient } from "react-query";
import { Box, Button, Typography } from "@mui/material";

import ADMIN_FILE_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_FILE_QUERY";
import { TRELLO_BID_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";

import useSnackbar from "../../../../../../hooks/useSnackbar";

import Modal from "../../../../../../components/Modal";

export default function InvoiceDeleteModal({
  invoiceId,
  setInvoiceId,
  setShowsInvoiceDeleteModal,
}: {
  invoiceId: number;
  setInvoiceId: (val: number) => void;
  setShowsInvoiceDeleteModal: (val: boolean) => void;
}) {
  const queryClient = useQueryClient();

  const trelloDetailQueryKey = TRELLO_BID_QUERY_KEY_GEN.trelloDetail();

  const { handleSnackbarOpen: showSnackbar } = useSnackbar();

  const { mutate: deleteFile } = ADMIN_FILE_QUERY.useDeleteInvoice(invoiceId);

  const handleInvoiceDelete = () => {
    deleteFile(
      {},
      {
        onSuccess: () => {
          showSnackbar("삭제에 성공했습니다.");
          setInvoiceId(0);
          queryClient.invalidateQueries(trelloDetailQueryKey);
        },

        onError: () => {
          showSnackbar("삭제에 실패했습니다.");
          setInvoiceId(0);
        },
      }
    );
  };

  const handleModalClose = () => {
    setInvoiceId(0);
    setShowsInvoiceDeleteModal(false);
  };

  return (
    <Modal
      isOpened={Boolean(invoiceId)}
      handleClose={handleModalClose}
      modalBody={
        <Box display={"flex"} flexDirection={"column"} textAlign={"center"}>
          <Typography className="sads" fontWeight="bold" color="error">
            삭제 하시겠습니까?
          </Typography>

          <Typography className="sads" color="error" sx={{ mt: 1 }}>
            다시 한 번 확인 후 삭제 부탁드립니다.
          </Typography>

          <Button
            sx={{ mt: 3 }}
            color="error"
            className="sads"
            variant="contained"
            onClick={handleInvoiceDelete}
          >
            삭제하기
          </Button>
        </Box>
      }
    />
  );
}
